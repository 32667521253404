import React, { Component } from 'react';
import { Redirect } from "react-router-dom";

// TODO this component should redirect to external login page
class Login extends Component {
    constructor(props) {
        super(props);
    }
    redirectLogin(referrerURI) {
      
      const uri = process.env.LEGACY_URL + "/login.pay";
      console.debug("environment process.env.LEGACY_URL = ", process.env.LEGACY_URL);
      console.debug("Navigate to login page", uri);
      window.location = encodeURI(uri);
    }
    render() {
        const referrer = this.props.referrer;
        const protocol = window.location.protocol;
        const host = window.location.host;
        const referrerURI = protocol + "//" + host + referrer;
        this.redirectLogin(referrerURI);
        return null;
    }
}
export default Login;
