import React, { Component } from 'react';
import TempPlaceholder from '../../Placeholders';

// Implement the Table and add it to the src components folder.
// Then take the real implemented component and render it here on demo scratch pad for QA.

class TableDemo extends Component {
  constructor(props)
  {
    super(props);
  }

  componentDidMount() {
  }

  render() {
    return (
      <div className="Table">

        <div className="component">
          <h4>Table component</h4>
          <div><a href='https://paytrace.atlassian.net/browse/PC2-52'>PC2-52</a></div>
          <div>No Jira item for this but has rows that contain other components</div>
          <hr/>
          <div>
            <TempPlaceholder background="https://paytrace.atlassian.net/secure/attachment/41635/Screen%20Shot%202019-01-14%20at%2011.49.16%20AM.png" />
          </div>
        </div>

      </div>
    );
  }
}

export default TableDemo;