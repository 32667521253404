// ViewTransactions
import React, { Component } from 'react';

class ViewTransactions extends Component {
    constructor()
    {
        super();
        this.state =
        {
            reason: "none received",
            message: "",
        };
    }

    componentWillMount()
    {
        var view_transactions_url = process.env.API_URL + '/transactions/view_transactions';
        // console.debug("view transaction");
        // TODO: this component is a proto type
        // either remove it or leave it or whatever but returning
        // nothing for now so i don't try to fetch when working locally
        return;
        fetch(view_transactions_url, {
            credentials: "include",
            method: 'POST'
        })
        .then(results => 
        {
            var fake = '[' +
            '{"id":105969302,"card_number":"545454xxxxxx5454","settle_amount":"0.5000",' +
                '"status":"Declined","customer_name":"","invoice":"","user":"TsysTest",' +
                '"processed":"2018-08-07T20:53:40Z","settled":null,"approval_code":"",' +
                '"avs":null,"csc":null,"processing_method":"Virtual Terminal"}, ' +
            '{"id":105969303,"card_number":"545454xxxxxx5454","settle_amount":"3.5000",' +
                '"status":"Declined","customer_name":"","invoice":"","user":"foobar",' +
                '"processed":"2018-08-07T20:53:40Z","settled":null,"approval_code":"",' +
                '"avs":null,"csc":null,"processing_method":"Virtual Terminal"}' +
            '{"id":105969305,"card_number":"545454xxxxxx5454","settle_amount":"1.5000",' +
                '"status":"Declined","customer_name":"","invoice":"","user":"RhondasTsysTest",' +
                '"processed":"2018-08-07T20:53:40Z","settled":null,"approval_code":"",' +
                '"avs":null,"csc":null,"processing_method":"Virtual Terminal"}' +
            ']';
            // var transactions = JSON.parse(fake);
            var transactions = results.json();
            return transactions;
        }).then(data => 
        {
            this.setState({transactions: data})
        }).catch(error => {
            console.log('fetch error = \n', error)
        });

    }

    render() {
        if (this.state.transactions === undefined) {
            return null;
        }

        return (
            <table className="view_transactions table table-striped">
                <thead>
                    <tr>
                        <th>name</th><th>age</th><th>Transaction ID</th>
                        <th>Card Number</th><th>Card Type</th><th>Amount</th>
                        <th>Approval</th><th>AVS Result</th><th>CSC Result</th>
                        <th>Status</th><th>Name</th><th>Invoice</th>
                        <th>Method</th><th>User Account</th><th>When</th>
                        <th>Settled</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.transactions.map(function(transaction, index){
                        return <tr key={index}>
                                    <td>
                                        <div><a href='#'>+Transaction</a></div>
                                        <div><a href='#'>+Customer</a></div>
                                    </td>
                                    <td>
                                        <span><input type='checkbox'/>Reciept</span>
                                    </td>
                                    <td>
                                        {transaction['id']}
                                    </td>
                                    <td>{transaction['card_number']}</td>
                                    <td>MasterCard</td>
                                    <td>{transaction['settle_amount']}</td>
                                    <td>{transaction['approval_code']}</td>
                                    <td>{transaction['avs']}</td>
                                    <td>{transaction['csc']}</td>
                                    <td>{transaction['status']}</td>
                                    <td>{transaction['customer_name']}</td>
                                    <td>{transaction['invoice']}</td>
                                    <td>{transaction['processing_method']}</td>
                                    <td>{transaction['user']}</td>
                                    <td>{transaction['processed']}</td>
                                    <td>{transaction['settled']}</td>
                                </tr>
                    })}
                </tbody>
            </table>
        );
    }
}

export default ViewTransactions;