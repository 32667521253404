// AmountInputBox
//
//  desc:
//    amount box for payment page
//
//  props:
//    values:               (optional) if provided, and array of streets with objects for value and placeholder for AddressInput component. 
//    onChange:             (optional) if provided, will handle change events to update controlled data
//    onValidate:           (optional) event handler to handle validation of input
//    isValid:              (optional) if true changes UI to error state
//    amount:               (optional) value for input box
//    name:                 (optional) name of form input box
//    id:                   (required) id of form input box used to update the controlled data model.
//    maxLength:            (optional) max length of input box
//    tooltipText:          (optional) tool tip of textbox
//    autoFocus:            (required) to focus on control when edit link on review page navigates back to page with control
import React, { Component } from 'react';
import { Validation, Change } from '../Utility';
import './AmountInputBox.css';


class AmountInputBox extends Component {
  constructor(props) {
    super(props);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  formatMoney(value) {
    var number = Number(value);
    var money =  number.toFixed(2);
    // pad input text value with 0s to the hundreth place.
    // pad input text value with a 0 before a decimal point if needed.
    // as long as it doesn't cause it to exceed max number.
    return money;
  }

  handleBlur(evt) {
    const newValue = this.formatMoney(evt.target.value);
    let validity = this.validateAmount(newValue);
    if (newValue != evt.target.value && validity) {
      evt.target.value = newValue;//todo write test
      if (this.props.onChange) {
        this.props.onChange(newValue);
      }
    }

    // call any parent level validations.
    if (this.props.onValidate) {
      this.props.onValidate(new Validation(evt));
    }
  }

  handleChange(evt) {
    var newValue = evt.target.value;
    var isValid = newValue == '.' || this.validateAmount(newValue);
    if (!isValid) return;

    if (this.props.onChange) {
      this.props.onChange(newValue);
    }
  }

  // changes it to a number with 0 prefix if needed before decimal.
  validateAmount(value) {
    // converting the value into number will also format any number starting with .
    // for example: .75 will be 0.75
    var validInput = Number(value);
    if (validInput <= 9999999999.99 && /^[0-9]+(\.[0-9]{1,2})?$/.test(validInput)) {
      return true;
    }
    return false;
  }

  render() {
    const error = this.props.isValid ? '' : 'AmountInput_AmountError';
    const classes = `AmountInputBox with-prepended-icon ${error}`;
    const amount = this.props.amount;
    const placeholder = this.props.placeholderText;
    const name = this.props.name;
    const id = this.props.id;
    const maxlength = this.props.maxLength;
    const tooltip = this.props.tooltipText;
    const style = {};
    if (this.props.width) {
      style.width = this.props.width;
    }
    
    return (
      <div className={ classes }>
        {this.props.children}
        <i className='input-left-icon'>$</i>
        <input
            autoFocus={ this.props.autoFocus }
            style={style}
            id={ id }
            name={ name }
            value={ amount }
            placeholder={ placeholder }
            maxLength={ maxlength }
            title = { tooltip }
            type="text"
            onChange={ this.handleChange }
            onBlur={ this.handleBlur }
        />
      </div>
    );
  }
}

export default AmountInputBox;
