import React, { Component } from 'react';
import HyperLink from './HyperLink'
import Icon, {ClosedMenuIcon, OpenMenuIcon} from './Icon'
import parse from 'html-react-parser';
import { BrowserRouter as Router, Route, Link, Switch, withRouter } from "react-router-dom";

class SubmenuLegacy extends Component {
  constructor(props){
    super(props);
    this.handleMenuItemSelected = this.handleMenuItemSelected.bind(this);
  }

  componentWillMount()
  {  
    const htmlSections = this.props.node.children;
    this.setState({link: htmlSections[0], list: htmlSections[1]});
  }

  handleMenuItemSelected(e) {
    e.stopPropagation();
    this.props.action(e, this.props.node);
  }

  hyperLinkText() {
    var htmlStr = this.state.link.innerHTML;
    var len = htmlStr.length;
    var n = htmlStr.lastIndexOf("</i>")
    return htmlStr.substring(n+4, len);
  }

  // converts the html links to react components to display on side menu
  renderSubmenuLinks(props) {
    const converted = parse(this.state.list.innerHTML, {
      replace: function(domNode) {
        if (domNode.attribs && domNode.attribs.href !== undefined){
          //console.log("determine if need to replacing href by matching", domNode.attribs.href, window.location.hostname)

          // uses reactjs router navigation if the link element contains the specified marker
          // go try to replace it if not then just return the converted component with the markup.
          if (domNode.attribs['href'].includes(window.location.hostname)) {
            const filename = domNode.attribs.href.substring(domNode.attribs.href.lastIndexOf('/') + 1);
            const filenameWithoutExt = filename.split('.')[0];
        
            // determine what route the link should use and 
            // uses the file path of href if a mapping does not exist.
            const customPathMapping = {
              donationlinks: 'designer'
            }
            const filepath = customPathMapping[filenameWithoutExt] !== undefined 
                              ? customPathMapping[filenameWithoutExt] 
                              : filenameWithoutExt;
        
            const label = domNode.children[0].data;
            return (
              <Link
                className='link-replaced'
                to={{
                  pathname: "/" + filepath
                }}>
                {label}
              </Link>
            )
          } else {
            return domNode;
          }
        } else {
          return domNode;
        }
      }
    });
    
    return converted;
  }

  render() 
  {
    let isSelected = this.props.node.classList.contains('open');
    let menuIcon = <ClosedMenuIcon/>
    if(isSelected) {
      menuIcon = <OpenMenuIcon/>
    }

    return (
      
      <li className={this.props.node.classList.value}>
        <HyperLink clickHandler={this.handleMenuItemSelected} href={this.state.link.getAttribute("href")} text={this.hyperLinkText()}>
          {menuIcon}
          {this.hyperLinkText()}
        </HyperLink>
        <ul id={this.state.list.id}>{this.renderSubmenuLinks(this.props)}</ul>
      </li>
    );
  }
}

export default withRouter(SubmenuLegacy);
