import React, { Component } from 'react';

export default class FileUploader extends Component {
  constructor(props)
  {
    super(props);
    this.file = null;

    this.onFileReaderLoad = this.onFileReaderLoad.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.uploaderInput = document.getElementById('fileUploader');
  }

  handleChange(event) {
    this.file = event.target.files[0];
    var fr = new FileReader();
    // once file reader is done loading fire off event and get 
    // base64 image src for the uploaded image.
    fr.onload = this.onFileReaderLoad;
    fr.readAsDataURL(this.file);

    event.preventDefault();
  }
  
  // public method  
  // that gets called when file reader loads the file from file input element
  onFileReaderLoad(event) {
    if (this.props.onFileLoad) {
      this.props.onFileLoad(event.target.result, this.file);
    }
  };

  // public method
  // so references to this component can trigger file upload dialog box
  triggerFileUpload(sender) {
    var event = new Event("UploadImage");
    // this.uploaderInput = document.getElementById('fileUploader');
    // this.uploaderInput.dispatchEvent(event);
    if (this.uploaderInput) {
      this.uploaderInput.click(event);
    }
  }

  render() {
    // note displaying if property exist, not if the property is truthy or falsey
    const style = this.props.display ? null : { display: "none" };
    return (
      <div className="FileUploader">
        {this.props.children}
        <input style={style} type="file" id="fileUploader" onChange={this.handleChange} />
      </div>
    );
  }
}
