// Address
//
//  desc:
//    provides max of 2 address fields (the street part)
//
//  props:
//    values:               (optional) if provided, and array of streets with objects for value and placeholder for AddressInput component. 
//    onchange:             (optional) if provided, will handle change events to update and validate controlled data
//
import React, { Component } from 'react';
import AddressInput from './AddressInput';
import AddressLink from './AddressLink';
import {Change, Validation, Rule} from '../Utility';

class Address extends Component {
  constructor(props)
  {
    super(props);

    this.handleAddAddress = this.handleAddAddress.bind(this);
    this.handleAddressValidate = this.handleAddressValidate.bind(this);
    this.handleAddressChange = this.handleAddressChange.bind(this);
  }

  createAddressInputBox() {
    const addresses = [];
    this.props.values.map((addr, i) => {
      addresses.push(<AddressInput
                            valid={ this.props.isValid }
                            onChange={ this.handleAddressChange }
                            onValidate={ this.handleAddressValidate } 
                            key={ i } identifier={ i } 
                            value={addr.value} 
                            placeholderText={addr.placeholder} maxLength="50"/>)
    });
    return addresses;
  }
  
  handleAddressChange(change) {
    const values = [];
    this.props.values.map((addr, i) => { values.push(addr.value) });
    values[change.id()] = change.value();
    if (this.props.onChange) {
      this.props.onChange(new Change({
        target: {
          id: 'address',
          value: values,
        }
      }));
    }
  }
  
  handleAddressValidate(validation) {
    const values = [];
    this.props.values.map((addr, i) => { values.push(addr.value) });
    values[validation.id()] = validation.value();
    
    let isValid = true;
    for (var i = 0; i < this.props.values.length; i++){
      if (this.props.values[i].value.trim() == '') {
        isValid = false;
      }
    }
    const v = new Validation({
      target: {
        id: "address",
        value: values,
      }
    });
    v.addRule( new Rule((v) => { return isValid }) )
    if (this.props.onValidate) {
      this.props.onValidate(v);
    }
  }
  
  getAddresses(){
    // return this.state.addressCollection ;
  }

  handleAddAddress(evt){
    if (this.props.onChange) {
      const values = [];
      this.props.values.map((addr) => { values.push(addr.value) });
      values.push('');
      this.props.onChange(new Change({
        target: {
          id: 'address',
          value: values
        }
      }))
    }
  }
  
  render() {
    const count = this.props.values.length;
    return (
      <div className="Address">
        { this.createAddressInputBox() }
        { count < 2 ? <AddressLink onClick={this.handleAddAddress} /> : null }
      </div>
    );
  }
}

export default Address;

