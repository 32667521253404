// InstructionsButton
//
//  desc:
//         
//
//
//  props:
//    theme:              the theme to use for styling this button 

import React from 'react';
import './InstructionsButton.css'

const help_pdf = '/assets/documents/help/Star_Cloud_Printer_Setup_Instructions.pdf';         //s3 file key for retrieving the help document 

class InstructionsButton extends React.Component
{
 constructor(props)
  {
    super(props)
    this.state = {

    };
    this.onButtonClick = this.onButtonClick.bind(this);
  }


  onButtonClick()
  {
    let uri = process.env.APHRODITE_ASSET_URL + help_pdf ;
    window.open(uri, '_blank');
  }


  render()
  {
    return(
      <div className='InstructionsButton' onClick={this.onButtonClick} >
        <div style={{'color': this.props.theme.btn_text_color, 'backgroundColor': this.props.theme.btn_bg_color}} >
          Printer Setup Guide
        </div>
      </div>
    );
  }

}

export default InstructionsButton;
