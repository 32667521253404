// FormSection
//
//  desc:
//    Renders the current form step title and
//    Renders the current form step in the process (Payment, Billing, Review, and ...).
//    Renders the navigation control as a child component passed in.
//    Renders the breadcrumbs for the available steps.
//    Renders the total payment component.
//
//  props:
//    children:             (required) the current step in the payment process. 
//                              Component should not be wrapped and uses key value to 
//                              determine what the current step is.
//
import React, { Component } from 'react';
import HeaderLabel from '../../HeaderLabel';
import TotalPaymentBox from '../../TotalPaymentBox';
import Icon from '../../Icon';
import NavigationButtons from '../NavigationButtons';
import LoginWidget, {CreateAccount} from '../../LoginWidget';


import './Form.css';
import './form.med.css';
import './form.small.css';

/*
 * Section of the form that will display the step child component containing.
 * current step to display. Navigation component navigates through the 
 * children by calling the callback handler to update the index state 
 * representing the current step. The top component keeps track of the 
 * current index and propagates that value down to any child that needs to know.
 */
class FormSection extends Component {
  constructor(props){
    super(props);
  }

  renderNavigation() {
    if (this.props.navigateControl) {
      return this.props.navigateControl;
    }
    return null;
  }
  
  renderTotalPaymentControl() {
    if (this.props.totalPaymentControl) {
      return this.props.totalPaymentControl;
    }
    return null;
  }
  
  renderBreadCrumbs() {
    if (this.props.navigationBreadCrumbs) {
      return this.props.navigationBreadCrumbs;
    }
    return null;
  }
  
  renderPageHeader() {
    if (this.props.navigationPageHeader) {
      return this.props.navigationPageHeader;
    }
    return null;
  }

  renderCurrentPage() {
    // The pages like "payment", "billing", "review" and "receipt" determined by Steps component.
    return (
      <div className='row'>
        <div className='col-lg-7'>
        {this.props.children}
        </div>
        <div className='col-lg-5'>
          {
            (1==2) ? <LoginWidget /> : null
          }
        </div>
      </div>
    )
  }
  
  render() {
    return (
      <div className='paymentform-form'>
        <div className='row'>
          <div className='col-md-12'>
            <div className="row">
              {this.renderBreadCrumbs()}
            </div>

            <div className="row">
              {this.renderPageHeader()}
            </div>

            { this.renderCurrentPage() }
          </div>
        </div>

        <div className='total-section row'>
          {this.renderTotalPaymentControl() }
        </div>

        <div className='row'>
        { this.renderNavigation() }
        </div>

      </div>
    );
  }
}

export default FormSection;
