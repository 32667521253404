// ZipCodeInput
//
//  desc:
//    provides a title and a zip code input box (with optional placeholder text)          
//
//  props:
//    title:                (optional) if provided, will display title above the input box
//    placeholderText:      (optional) if provided, will display ghosted placeholder text in the input box. Note: there is no placeholder text by default
//    zipCode:              (optional) if provided, will set the the zipcode text for the input box (will be marked out with ***)
//    inError:              if 'true', will display the zipcode input in red 
//    hoverText:            (optional) text to display to the user when the mouse cursor hovers over the input box. By default, there is none
//    onTextInputCallback:  a callback function for when the user has entered input
//

import React from 'react';
import {Change, Validation} from '../Utility';
import './index.css'

class ZipCodeInput extends React.Component
{
 constructor(props)
  {
    super(props)
    this.onTextInput = this.onTextInput.bind(this)
    this.validateZipCode = this.validateZipCode.bind(this)
  }


  getPlaceholderText()
  { //defaults to empty string if no prop provided
    return this.props.placeholderText != null ? this.props.placeholderText : ""
  }


  getReasonText()
  { //defaults to empty string if no prop provided
    return this.props.reasonText != null ? this.props.reasonText : ""
  }


  getHoverText()
  { //defaults to empty string if no prop provided
    return this.props.hoverText != null ? this.props.hoverText : ""
  }

  getZipCode()
  { //defaults to 25 if no prop provided
    return this.props.zipCode != null ? this.props.zipCode : ""
  }


  getInputClasses()
  {
    var retClass = 'zipCodeInputBox';
    if ( !this.props.isValid || (this.props.inError != null && this.props.inError == true) )
    {
      retClass += ' zipCodeInput_zipCodeError'
    }
    return retClass;
  }
  

  onTextInput(evt)
  {
    let inputText = evt.target.value;
    if (inputText.length >= 11) {
      return
    }

    if (this.props.onTextInputCallback != null) {
        this.props.onTextInputCallback(new Change(evt));
    }
  }


  validateZipCode(evt)
  {
    if (this.props.onValidate) {
      this.props.onValidate(new Validation(evt))
    }
  }


  render() {
    const zipcode = this.props.zipCode || '';
    return (
      <div className='zipCodeInput'>
        <div className='zipCodeInputTitle'>
           {this.props.title}
        </div>
        <input id="zipcode"
                className={this.getInputClasses()} 
                value={ zipcode }
                title={this.getHoverText()}
                onChange={this.onTextInput}
                onBlur={this.validateZipCode}
                placeholder={this.getPlaceholderText()}
                type='text' 
                pattern="^\s*?\d{5}(?:[-\s]\d{4})?\s*?$"
                required
                />
     </div>
     );
  }
}

export default ZipCodeInput;
