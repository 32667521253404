// Steps
//
//  desc:
//    control that wraps up all the available steps of the payment link form.
//    In charge of determining which step to render. And bubbling up the validation 
//    handling to the parent component.
//
//  props:
//    onValidate:           (optional) a callback function validation
//    current:              (required) the current child step to render
//    children:             (required) the existing steps like payment, billing, review, receipt
//

/* Steps
 *
 * This component doesn't do much. Probably could just roll it up
 * into the parent but keeping it separate feels better.
 * Basically it just manages which step in the processing should show. 
 */
import React, { Component } from 'react';
import './steps.css';


class Steps extends Component {
  constructor(props){
    super(props);
    this.validateCurrentStep = this.validateCurrentStep.bind(this);
  }
  
  validateCurrentStep(validity) {
    if (this.props.onValidate) {
      this.props.onValidate(validity);
    }
  }

  displayCurrentPage() {
    const currentIndex = this.props.current; // index of child in array to show. the current step.
    const components = React.Children.toArray(this.props.children); // array of payment steps.
    const currents = components.filter( (component, idx) => (currentIndex == idx) ? component : null );

    if (currents.length > 0) {
      return React.cloneElement(
        currents[0], {onValidate: this.validateCurrentStep}
      )
    }
    return null;
  }

  render() {
    return (
      <div className="steps">
        {this.props.children}
      </div>
    );
  }
}

export default Steps;
