import React, { Component } from 'react';
import './spinner.css';
import './test.css';
import 'bootstrap/dist/css/bootstrap.min.css';

// '{ "merchant_id":"49456", "payment": {"amount": {"value": "13.99","valid": true,"autoFocus": false},"invoice": {"value": "some invoice"},"paymentType": {"value": "one-time"},"frequency": {"value": 1,"valid": true},"customFrequency": {"value": "day"},"customFrequencyCount": {"value": 2,"valid": true},"duration": {"value": "continual"},"durationLimit": {"value": 1,"valid": true},"payon": {"value": "null","valid": true,"autoFocus": false}},"billing": {"password": {"value": "Secret"},"checkingAccount": {"value": "","autoFocus": false},"routingNumber": {"value": ""},"paymentMethod": {"value": "CC"},"name": {"value": "Bob the Builder","valid": true},"cc": {"value": "5454545454545454","valid": true,"autoFocus": false},"exp": {"value": "1/2020","valid": true},"securityCode": {"value": "","valid": true},"address": {"value": ["50th Ave"]},"city": {"value": "seattle","valid": true},"state": {"value": "WA","valid": true},"country": {"value": "us","valid": true},"zipcode": {"value": "98999","valid": true},"phone": {"value": "9993332222","valid": true},"email": {"value": "me@email.com","valid": true}},"review": {"recaptcha": {"value": "null","valid": false},"agreeToTerms": {"value": false}}}' "https://jqlev.paytrace.com/v1/guest/process_transaction"

class QATest extends Component {
  constructor(props){
    super(props);
    this.state = {
      isWaiting: false,
      form: {
        merchant_id: 49456
        , CC: '5454545454545454'
        , ExpMnth: '01'
        , ExpYr: '22'
        , DDA: '123456'
        , TR: '325070760'
        , SNAME: 'Jackie Robinson'
        , SADDRESS: '5224 Tilden Avenue'
        , SADDRESS2: ''
        , SCITY: 'New York City'
        , SSTATE: 'NY'
        , SCOUNTY: 'Brooklyn'
        , SZIP: '11223'
        , SCOUNTRY: 'US'
        , BNAME: ''
        , BADDRESS: ''
        , BCITY: ''
        , BSTATE: ''
        , BZIP: ''
        , BCOUNTRY: ''
        , EMAIL: 'jackie@aol.com'
        , PHONE: '214-337-6889'
        , FAX: '214-337-1885'
        , Password: 'homerun!'
        , TaxID: 'tax123'
        , Description: ''
      }
    }
    this.onsubmit = this.onsubmit.bind(this);
    this.onchange = this.onchange.bind(this);
    
    this.inputs = [
      {id:'merchant_id', name:'merchant_id', label:'Merchant ID'}
      , {id:'CC', name:'CC', label:'CC'}
      , {id:'ExpMnth', name:'ExpMnth', label:'Exp Mnth'}
      , {id:'ExpYr', name:'ExpYr', label:'Exp Yr'}
      , {id:'DDA', name:'DDA', label:'DDA'}
      , {id:'TR', name:'TR', label:'TR'}
      , {id:'SNAME', name:'SNAME', label:'SNAME'}
      , {id:'SADDRESS', name:'SADDRESS', label:'SADDRESS'}
      , {id:'SADDRESS2', name:'SADDRESS2', label:'SADDRESS2'}
      , {id:'SCITY', name:'SCITY', label:'SCITY'}
      , {id:'SSTATE', name:'SSTATE', label:'SSTATE'}
      , {id:'SCOUNTY', name:'SCOUNTY', label:'SCOUNTY'}
      , {id:'SZIP', name:'SZIP', label:'SZIP'}
      , {id:'SCOUNTRY', name:'SCOUNTRY', label:'SCOUNTRY'}
      , {id:'BNAME', name:'BNAME', label:'BNAME'}
      , {id:'BADDRESS', name:'BADDRESS', label:'BADDRESS'}
      , {id:'BADDRESS2', name:'BADDRESS2', label:'BADDRESS2'}
      , {id:'BCITY', name:'BCITY', label:'BCITY'}
      , {id:'BSTATE', name:'BSTATE', label:'BSTATE'}
      , {id:'BZIP', name:'BZIP', label:'BZIP'}
      , {id:'BCOUNTRY', name:'BCOUNTRY', label:'BCOUNTRY'}
      , {id:'EMAIL', name:'EMAIL', label:'EMAIL'}
      , {id:'PHONE', name:'PHONE', label:'PHONE'}
      , {id:'FAX', name:'FAX', label:'FAX'}
      , {id:'Password', name:'Password', label:'Password'}
      , {id:'TaxID', name:'TaxID', label:'TaxID'}
      , {id:'Description', name:'Description', label:'Description'}
    ];
  }
  
  onchange(evt) {
    this.setState({
      form: {
        [evt.target.id]: evt.target.value
      }
    });
    console.log(evt.target.value);
  }
  
  onsubmit(evt) {
    this.setState({isWaiting: true})
    const url = process.env.API_URL + "/v1/guest/customer_create";
    let requestSettings = {
      method: "post",
      body: JSON.stringify(this.state.form),
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "PayTrace-Product": "paymentlink"
      }
    };
    fetch(url, requestSettings).then(results => {
      return results.json();
    }).then(data => {
      this.setState({isWaiting: false});
      console.log(data);
    }).catch(err => {
      this.setState({isWaiting: false});
      console.error(err);
    });
    evt.preventDefault();
  }
  
  render() {
    return (
      <div className="form-container">
        <form>
          
          { 
            this.inputs.map(function(item, idx) {
              return (
                <div key={item.id + idx} className="form-group">
                  <label>{item.label}</label>
                  <input id={item.id} name={item.name} className="form-control" 
                        onChange={this.onchange} type="text" value={this.state.form[item.id]}/>
                  <small>{item.note}</small>
                </div>
              )
            }, this)
          }
          <button onClick={this.onsubmit} type="button" className="btn btn-primary">Submit</button>
          <div className={this.state.isWaiting ? 'loader' : 'hidden'}>Loading...</div>
        </form>

      </div>
    );
  }
}

export default QATest;
