import React, { Component } from 'react';
import TempPlaceholder from '../../Placeholders';
import DatePicker from '../../DatePicker';
// Implement the DatePicker and add it to the src components folder.
// Then take the real implemented component and render it here on demo scratch pad for QA.

class DatePickerDemo extends Component {
  constructor(props)
  {
    super(props);
  }

  componentDidMount() {
  }

  render() {
    return (
      <div className="DatePicker">

        <div className="component">
          <h4>DatePicker</h4>
          <div><a href='https://paytrace.atlassian.net/browse/PC2-68'>PC2-68</a></div>
          <hr/>
          <div>
            <TempPlaceholder background="https://paytrace.atlassian.net/secure/thumbnail/41653/Screen+Shot+2019-01-14+at+11.37.44+AM.png?default=false" />
          </div>
          <div>
            <TempPlaceholder background="https://paytrace.atlassian.net/secure/thumbnail/41713/Screen+Shot+calendar.png?default=false" />
          </div>
        </div>
        
        <div className="component">
          <DatePicker onDateChange={(dateSelected, inputValue) => console.log(dateSelected, inputValue)} />
        </div>

        <br/>
        <br/>
      </div>
    );
  }
}

export default DatePickerDemo;