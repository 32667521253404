// CreatePaymentLink
//
//  desc:
//    view for creating a merchant's payment link to be embedded on webpages, etc      
//
//  props:
//    brandColor:       the brand color that is part of the merchant's profile (TODO: when functionality is added, this may be provided as some other way than a prop)
//    dollarAmount:     dollar amount that should be a part of the donation link

import React from 'react';
import DOMPurify from 'dompurify';
import './index.css'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {ThemeContext} from '../../../lib/PayTrace/SiteBranding';
import ColorDesigner from '../../ColorDesigner';
import SectionText from '../../common/react-component-library/SectionText';
import TextInputBox from '../../common/react-component-library/TextInputBox';
import CheckboxLabelTextInput from '../../common/react-component-library/CheckboxLabelTextInput';
import Icon from '../../common/react-component-library/Icon';

import {CreatePaymentLinkSender} from './CreatePaymentLink.js';
import {CreatePaymentLinkRetriver} from'./CreatePaymentLink.js';
import {UserContext} from '../../Login/UserContext';

import ParamParseLib from '../../common/react-component-library/lib/ParamParse/paramParse.js';
import {GetPaymentLink} from '../../../lib/comms';

//create the style arrays in this way so that we can easily create copyable code for the merchant later 
const buttonSolidStyleArray = [
  "border: 0px; height: 38px; width: 160px; color: white; font-weight: bold; ",
  "border: 0px; border-radius: 7px; height: 38px; width: 160px; color: white; font-weight: bold; ",
  "border: 0px; border-radius: 18px; height: 38px; width: 160px; color: white; font-weight: bold; "
];

const buttonOutlineStyleArray = [
  "border: 1px solid; height: 38px; width: 160px; background-color: white; ",
  "border: 1px solid; height: 38px; border-radius: 7px; width: 160px; background-color: white; ",
  "border: 1px solid; height: 38px; border-radius: 18px; width: 160px; background-color: white; ",
]

const BUTTON_DEFAULT_COLOR = '#4696CA';

class CreatePaymentLink extends React.Component
{
  constructor(props)
  {
    super(props);
    const PRIMARY = '#4696CA';
    this.state = 
    {
      codeCopied: false,
      paymentLinkCode: "",
      buttonColor: PRIMARY,
      buttonChoiceArray: [],
      buttonStyleArray: [],
      selectedButton: 1,                      //by default selected style option is 1 (unless prefs are loaded in)
      submitButtonText: "",                   //TODO: when functionality is fully added, this may change    (how this is provided is yet to be determined)
      customButtonUrl: "",                    //by default, there is no custom button url (unless prefs are loaded in)
      errorLabelText:"",
      errorLableClassName:""
    }
    this.onCopyCodeClicked = this.onCopyCodeClicked.bind(this);
    this.onCodeCopied = this.onCodeCopied.bind(this);
    this.onButtonColorClick = this.onButtonColorClick.bind(this);

    this.onNewButtonTextCallback = this.onNewButtonTextCallback.bind(this);
    this.onSaveClick = this.onSaveClick.bind(this);
    this.onButtonSelectorChecked = this.onButtonSelectorChecked.bind(this)
    this.onCustomCheckboxClick = this.onCustomCheckboxClick.bind(this);
    this.onCustomTextInput = this.onCustomTextInput.bind(this);
  }  

  componentDidMount() 
  {
    //Make a call to API_URL and load merchant button info
    CreatePaymentLinkRetriver.getButtonInfo(
      (merchantButtonInfo) => {
        
        if(merchantButtonInfo.success)
        {
          //let merchantColor = merchantButtonInfo.button_color 
          let merchantButtonText = merchantButtonInfo.button_text 
          let merchantButtonUrl = merchantButtonInfo.button_url
          
          // set merchant color
          this.setMerchantColor(merchantButtonInfo.button_color);
          
          // set button text
          this.setMerchantButtonText(merchantButtonText)
          
          // set merchant button_url
          this.setMerchantButtonUrl(merchantButtonUrl)
    
          //Button style with merchant data
          this.createButtonChoices(this.getChosenColor(), merchantButtonText, merchantButtonInfo.button_style );
        }
        else 
        {
          // in case of being unable to load a Merchant data
          // For now: showing the generic error message 
          if (merchantButtonInfo.error !== undefined && (merchantButtonInfo.status == 400 || merchantButtonInfo.status == 500) ){
            this.setState({errorLabelText: "Please choose and save button design.", errorLableClassName: "alert alert-danger"});
          }  
          // setting button style with default colors
          this.createButtonChoices(this.getChosenColor(), this.getSubmitButtonText() );
        }
      });
  }


  setMerchantColor(merchantColor)
  {    
    if(merchantColor != null){
      this.setState({buttonColor: merchantColor });
    }
  }
  
  
  setMerchantButtonText(value)
  { // set button text
    if(value != this.getDefaultSubmitButtonText()){
      this.setState({ submitButtonText: value});
    }
  }
  
  
  setMerchantButtonUrl(value)
  {
    // If merchant button_url has value, it is a custom button selection
    if(value != null){
      this.setState({ customButtonUrl: value, selectedButton: 15 });
      //setting selected button to random higher number so built in button styles are not selected.
    }
  }
  
  
  createButtonChoices(buttonColor, buttonText, merchantButtonStyle)
  {
    let chosenColor = buttonColor;
    let tempButtonArray = [];
    let tempButtonStyleArray = [];
    
    //first create the solid buttons
    for (let i = 0; i < buttonSolidStyleArray.length; i++)
    {
      let tempStyle = buttonSolidStyleArray[i];
      tempButtonStyleArray.push(tempStyle);

      tempStyle += "background-color:" + String(chosenColor) + ";";
      let newButton = this.createButtonHtmlString(tempStyle, buttonText);  //"<button style='" + tempStyle + "'>" + this.state.submitButtonText + "</button>";
      tempButtonArray.push(newButton);
    }

    //then create the outline buttons
    for (let i = 0; i < buttonOutlineStyleArray.length; i++)
    {
      let tempStyle = buttonOutlineStyleArray[i];
      tempButtonStyleArray.push(tempStyle);

      tempStyle += "border-color:" + String(chosenColor) + ";";
      tempStyle += "color:" + String(chosenColor) + ";";
    
      let newButton = this.createButtonHtmlString(tempStyle, buttonText);  //"<button style='" + tempStyle + "'>" + this.state.submitButtonText + "</button>";
      tempButtonArray.push(newButton);
    }
    
    // To load Merchant's stored built in Style 
    if(merchantButtonStyle !== undefined || merchantButtonStyle != null){
      for (let i = 0; i < tempButtonStyleArray.length; i++)
      {
        if(tempButtonStyleArray[i] == merchantButtonStyle){
          this.setState({selectedButton: i});
        }
      } 
    }

    // set the html and button style
    this.setState({buttonChoiceArray: tempButtonArray, buttonStyleArray: tempButtonStyleArray});

  }

  
  onSaveClick(evt)
  {
    //Get the required data 
    let chosenButtonStyle = "";
    let isCustomChecked = this.isCustomCheckboxChecked();

    var tempErrorClassName = "alert alert-danger";

    if(isCustomChecked){
      if(this.state.customButtonUrl == "")
      {
        var tmpErrMsg = "Please provide a custom button url."
        var tempErrorClassName = "alert alert-danger";
      
        this.setState({errorLabelText: tmpErrMsg, errorLableClassName: tempErrorClassName})
        return false;
      }
    }
    else{
      chosenButtonStyle = this.state.buttonStyleArray[this.state.selectedButton];
    }
    
    let params = {}
    params = {
      "button_text": encodeURIComponent(this.getSubmitButtonText())
      ,"button_color": encodeURIComponent(this.getChosenColor())
      ,"button_url": encodeURIComponent(this.state.customButtonUrl)
      ,"button_style": encodeURIComponent(chosenButtonStyle)
    }
  
    //Make a call to API_URL
    let createPaymentLinkResponse = {};
    createPaymentLinkResponse = CreatePaymentLinkSender.send(params,
        (createPaymentLinkResponse) => {

          if(createPaymentLinkResponse.success){
            this.setState({errorLabelText: "Your selections have been saved successfully.", errorLableClassName: "alert alert-success" });
          }
          else{
            this.setState({errorLabelText: createPaymentLinkResponse.status_message, errorLableClassName: tempErrorClassName});
          }
          window.scrollTo(0, 0);
        });

  }


  //creates html in a string with the given style and the button text 
  createButtonHtmlString(style, buttonText)
  {
    let newButton = "<button style='" + style + "'>" + buttonText + "</button>";
    return newButton;
  }


  onButtonSelectorChecked(event)
  {
    let selected = event.target.checked;
    if (selected)
    {
      this.setState({selectedButton:event.target.value, errorLabelText:"", errorLableClassName:""})
    }
  }


  createButtonSelectionComponent()
  {
    const buttonItems = this.state.buttonChoiceArray.map((button, index) =>
    {
      // Correct! Key should be specified inside the array.
      let tempDangerousHtml = {__html: button}
      let checkbox = <input type="checkbox" name={index} value={index} onChange={this.onButtonSelectorChecked} checked={this.state.selectedButton == index} />

      return  <div className='create-button-combo-div' key={index.toString()}>
                <div key={index.toString()} dangerouslySetInnerHTML={tempDangerousHtml}/>
                  {checkbox}
              </div>
      });
    return (
      <div id='create-button-selector'>
        {buttonItems}
      </div>
    );    
  }

  getChosenColor()
  {
    return this.state.buttonColor
  }

  onButtonColorClick(color)
  {
    color = DOMPurify.sanitize(color);

    //set the state of the button color chosen by the user 
    this.setState({buttonColor: color});
    this.createButtonChoices(color,  this.getSubmitButtonText());         //a new color has been chosen, so we update the buttons
  }

  //handle the clicking of the button (disbale button?) and call the callback handler
  onCopyCodeClicked()
  {
    //handle extra tasks when the code has been copied. (stubbed function currently) such as a flyout! 
  }

  //return ths appropriate button for rendering
  getCopyButton()
  {
    let button =
     <ThemeContext.Consumer>
        {theme => (
          <button className="create-copy-button btn"  style={{'backgroundColor': theme.btn_bg_color, 'color': theme.btn_text_color}} onClick={this.onCopyCodeClicked}>
            <i className="far fa-copy"></i>  Copy Code
          </button>
        )}
      </ThemeContext.Consumer>

    return button;
  }


  onCodeCopied()
  { 
    this.setState({codeCopied: true});
  };


  //needed default as a separate function due to state change transitions 
  getDefaultSubmitButtonText()
  {
    return "Submit Payment"
  }


  getSubmitButtonText()
  {
    return this.state.submitButtonText.length > 0 ? this.state.submitButtonText : this.getDefaultSubmitButtonText()
  }


  onNewButtonTextCallback(newText)
  {
    newText = DOMPurify.sanitize(newText);
    this.setState({submitButtonText: newText})
    this.createButtonChoices(this.getChosenColor(), newText.length > 0 ? newText : this.getDefaultSubmitButtonText());
  }


  onCustomCheckboxClick(isChecked)
  {
    let thisSelection = this.state.buttonChoiceArray.length +1;
    if (isChecked)
    { //update the selected button to reflect that this is the item now checked
      this.setState({selectedButton: thisSelection, errorLabelText:"", errorLableClassName:""});
    }
    else
    {
      if (this.state.selectedButton == thisSelection)
      { //this was the currently selected checkbox, so we want to unselect
        this.setState({selectedButton: -1})         //resetting the value back to a nonvalid option
      }
    }
  }


  onCustomTextInput(text)
  {
    this.setState({customButtonUrl: text})
  }


  //returns a bool indicating if the user wants to use the custom button url for their button
  isCustomCheckboxChecked()
  { //the button choice array only holds the buttons we provide
    if (this.state.selectedButton > this.state.buttonChoiceArray.length)
    {
      return true;
    }
    return false;
  }


  getPaymentLinkCode(merchantInfo)
  {
    let tempMerchantID = '';
    if(merchantInfo){
      tempMerchantID = merchantInfo.merchant_id;
    }
    let temp = this.createPaymentLinkCode(tempMerchantID);
    return temp
  }


  createPaymentLinkCode(merchant_id)
  { 
    let chosenButtonHtml = ""
    if (this.isCustomCheckboxChecked())
    { //merchant wants to use a custom a custom button
      chosenButtonHtml = '<input type="image" onclick="window.location.href=\'' + this.getMerchantPaylinkUrl(merchant_id) +'\'" src="' + this.state.customButtonUrl +'"/>'
    }
    else
    { //check to see if a button that we have provided is currently selected 
      chosenButtonHtml = this.state.buttonChoiceArray[this.state.selectedButton];
      if (chosenButtonHtml)
      {
        let addFormHtml = '<button type="submit" onclick="window.location.href=\'' + this.getMerchantPaylinkUrl(merchant_id) + '\'"';
        chosenButtonHtml = chosenButtonHtml.replace('<button',addFormHtml);
      }
    }
    if (chosenButtonHtml)
    {
      chosenButtonHtml = '<!--Please paste on your website.-->\n' + chosenButtonHtml + '<!--Thank you for using PayTrace.com-->\n<!--Please paste on your website.-->\n'
    }
    return chosenButtonHtml

  }

  //returns the comus paylink url for the merchant
  getMerchantPaylinkUrl(merchant_id)
  {
    var paylink_url = process.env.PAYLINK_URL + '?m=' + merchant_id;
    paylink_url += '&amount=' ;
    paylink_url += '&invoice=' ;
    return paylink_url
  }
  
  
  getLinkParams()
  {
    if (this.state.isDoneLoading != true)
    {
      GetPaymentLink().then(function(paymentLinkParams)
      {
        var encodedParamString = ""
        try 
        {
          encodedParamString = window.atob(paymentLinkParams.LinkParams); // try to convert the base64 string
        } 
        catch(e) 
        { // invalid base64 so try to just deconstructEncodedParameterString
          encodedParamString = paymentLinkParams;
        }

        ParamParseLib.deconstructEncodedParameterString(encodedParamString);

      }.bind(this)).finally(function()
      {
        this.setState(
        {
          isDoneLoading: true,
        });
        this.getColors();

      }.bind(this));
    }
  }
  
  getColors()
  {
    let state = this.state;
    let primaryColor = ParamParseLib.getPrimaryColor();
    
    //only overrides the defaults if a color is found 
    if(primaryColor  && state.buttonColor == BUTTON_DEFAULT_COLOR)
    {
      state.buttonColor = primaryColor
      this.setState(state);
      // To apply a current color to the button
      this.onButtonColorClick(primaryColor);
    }

  }
  
  
  render() {
    let button = this.getCopyButton();
    let buttonSelector = this.createButtonSelectionComponent();
    let isCustomChecked = this.isCustomCheckboxChecked();
    
    return (
      <UserContext.Consumer>
        {({ user, merchantInfo }) => {
          var tempMerchantInfo = merchantInfo;
          
          if (merchantInfo)
          { //checking that proper merchant info exists (ie, authenticated properly) before continuing 
            this.getLinkParams()
          }
  
          return (
            <ThemeContext.Consumer>
              {theme => (
                <div className='create-payment-link' style={{ backgroundColor: '#FFF' }}>
                  <div style={{ color: theme.tertiary_color, fontWeight: 'bold', padding: '10px', height: '40px', backgroundColor: '#f5f5f5', textAlign: 'left' }}>
                    Add a link to your website
                  </div>
  
                  <div className='create-payment-options'>
                    <button className='create-save-button btn pt-primary' onClick={this.onSaveClick}>
                      <Icon className="fas fa-check-circle" fgcolor="#FFF" bgcolor="#F98E38" />Save
                    </button>
  
                    <div style={{ clear: 'both', padding: '4px' }} />
                    <div className={this.state.errorLableClassName}>{this.state.errorLabelText}</div>
                    <div style={{ clear: 'both', paddingBottom: '40px' }} />
  
                    <SectionText title="1. Choose a Color" content="Choose a color for your payment button" />
                    <div style={{ float: 'left' }}>
                      <div>
                        <div className='create-color-checkbox'>
                          <div style={{ marginTop: "10%" }}>Color</div>
                        </div>
                        <ColorDesigner
                          onUpdateColor={this.onButtonColorClick}
                          color={this.state.buttonColor}
                          enabled={true}
                        >
                        </ColorDesigner>
                      </div>
                    </div>
                    <div style={{ clear: 'both' }} />
  
                    <SectionText title="2. Edit Button Text - Optional" content="Provide a button text for your payments. The default text is Submit Payment." />
                    <TextInputBox placeholderText="Submit Payment" onChangeCallback={this.onNewButtonTextCallback} name="newButtonText" maxLength="35" value={this.state.submitButtonText} />
  
                    <SectionText title="3. Select a Button Style" content="Select a button style or Use a custom button URL for your payments" />
                    {buttonSelector}
                    <div style={{ clear: 'both' }} />
  
                    <div className='create-custom-url-checkbox'>
                      <CheckboxLabelTextInput isChecked={isCustomChecked} onCheckboxCallback={this.onCustomCheckboxClick} onTextInputCallback={this.onCustomTextInput} value={this.state.customButtonUrl} />
                    </div>
                    <div style={{ clear: 'both' }} />
  
                    <SectionText title="4. Copy/Paste Code to Your Website" content="Copy the code snippet below to add your payment button to your website" />
  
                    <div style={{ float: 'left' }}>
                      <textarea className="create-payment-link-text-area" readOnly rows={15} cols={60} value={this.getPaymentLinkCode(tempMerchantInfo)} />
  
                      <div className="copyCodeButton">
                        <CopyToClipboard
                          onCopy={this.onCodeCopied}
                          options={{ message: 'copied!' }}
                          text={this.getPaymentLinkCode(tempMerchantInfo)}
                        >
                          <span>{button}</span>
                        </CopyToClipboard>
                      </div>
                    </div>
                    <div style={{ clear: 'both' }} />
                  </div>
                </div>
              )}
            </ThemeContext.Consumer>
          );
        }}
      </UserContext.Consumer>
    );
  }
}

export default CreatePaymentLink;
