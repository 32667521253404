// ContentHeader
//
//  desc:
//
//  required props:
//    type: type of content
//

import React, { Component } from 'react';
import {ThemeContext} from '../../../lib/PayTrace/SiteBranding';
import './index.css'

class ContentHeader extends Component {

  constructor(props)
  {
    super(props);
  } 

  getFeedbackUrl(){
    var feedback_context = "Paymentlink"
    var feedback_url = process.env.PHOENIX_URL + "/mu/feedback/" + feedback_context ;
    //"https://rmphx.paytrace.com/mu/feedback/Paymentlink"
    return feedback_url ;
  }

  render() {
    return (
      <ThemeContext.Consumer>
          {theme => (
            <div className='ContentHeader' id='content-header'>
              <h1 style={{color: theme.secondary_color}}> {this.props.type} </h1>
              <a style={{color: theme.secondary_color}} href={this.getFeedbackUrl()} target='_blank'> <i className = "fa fa-comments"/> Let us know what you think!</a>
            </div>
          )}
      </ThemeContext.Consumer>
    );
  }
}

export default ContentHeader;
