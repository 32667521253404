// CreditCardFields
//
//  desc:
//    groups of fields required for CC processing
//
//  props:
//    formdata:             (required) form data
//      ccnum:                    (optional) value for the cc component
//      isCCValid:                (optional) passed into the cc component to set error state
//      exp:                      (optional) value for the exp component
//      isExpValid:               (optional) passed into the exp component to set error state
//      securityCode:             (optional) value for the security code component
//      isSecurityCodeValid:      (optional) passed into the secuirty code component to set error state
//    onDataChange:         (optional) a callback function to update model for form data
//    onValidate:           (optional) a callback function to validate the form data
//

import React, { Component } from 'react';
import CreditCard, {CreditCardSmartIcon, Validator} from '../../CreditCard';
import ExpirationDateInput from '../../ExpirationDateInput';
import SecurityCodeInput from '../../SecurityCodeInput';
import {Validation, Rule} from '../../Utility';

class CreditCardFields extends Component {
  constructor(props){
    super(props);
    this.state = {
      isCCValidVisited: false,
      isExpDateValidVisited: false,
      isSecurityCodeValidVisited: false,
    }

    this.onBillingCreditCardNumberChange = this.onBillingCreditCardNumberChange.bind(this);
    this.onBillingCreditCardExpChange = this.onBillingCreditCardExpChange.bind(this);
    this.onBillingCreditCardSecurityCodeChange = this.onBillingCreditCardSecurityCodeChange.bind(this);

    this.validateExpirationDate = this.validateExpirationDate.bind(this);
    this.validateCardNumber = this.validateCardNumber.bind(this);
    this.validateSecurityCode = this.validateSecurityCode.bind(this);
  }

  onBillingCreditCardNumberChange(value) {
    if (this.props.onBillingCreditCardNumberChange) {
      this.props.onBillingCreditCardNumberChange(value);
    }
  }
  
  onBillingCreditCardExpChange(value) {
    if (this.props.onBillingCreditCardExpChange) {
      this.props.onBillingCreditCardExpChange(value);
    }
  }

  onBillingCreditCardSecurityCodeChange(value) {
    if (this.props.onBillingCreditCardSecurityCodeChange) {
      this.props.onBillingCreditCardSecurityCodeChange(value);
    }
  }

  // just for UI updating validation on blur
  validateSecurityCode() {
    this.setState({
      isSecurityCodeValidVisited: true
    })
  }

  // just for UI updating validation on blur
  validateExpirationDate(validation) {
    this.setState({
      isExpDateValidVisited: true
    })
  }
  
  // just for UI updating validation on blur
  validateCardNumber(validation) {
    this.setState({
      isCCValidVisited: true
    })
  }
  
  render() {
    const ccnum = this.props.formdata.cc;
    const exp = this.props.formdata.exp;
    const securityCode = this.props.formdata.securityCode;
    const autoFocus = this.props.autoFocus;
    return (
      <div>
        <CreditCard
            autoFocus={ autoFocus }
            id="cc"
            number={ ccnum || '' }
            isValid={ this.props.isCCValid || !this.state.isCCValidVisited }
            onChange={ this.onBillingCreditCardNumberChange }
            onValidate={ this.validateCardNumber }>
          <CreditCardSmartIcon cardNumber={ ccnum } />
        </CreditCard>
        
        <div className='side-by-side' style={{clear:'both'}}>
          <div className='control'>
            <ExpirationDateInput
                            id="exp"
                            value={ exp || '' }
                            isValid={ this.props.isExpDateValid || !this.state.isExpDateValidVisited }
                            onChange={ this.onBillingCreditCardExpChange }
                            onValidate={ this.validateExpirationDate }
                            title="Expiration Date"
                            maxLength="7"
                            tooltipText="CreditCard expiration date" />
          </div>
          <div className='control'>
            <SecurityCodeInput
                            id="securityCode"
                            value={ securityCode || '' }
                            isValid={ this.props.isSecurityCodeValid || !this.state.isSecurityCodeValidVisited }
                            onChange={ this.onBillingCreditCardSecurityCodeChange }
                            onVisited={ this.validateSecurityCode }
                            title="Security Code"
                            placeholderText= ""
                            textBoxWidth="100%"
                            tooltipText="3-4 digit code usually found on the back of your card" />
          </div>
        </div>
      </div>
    );
  }
}

export default CreditCardFields;
