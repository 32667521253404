import React, { Component } from 'react';
import './PTCanvas.css';

import SideBar from '../../components/SideBar';
import Content from '../Content'


class PTCanvas extends Component {

  constructor(props)
  {
    super(props);

  } 

  render() {
    return (
      <div className="PTCanvas container-fluid" id="pt-canvas">
        <SideBar />
        <Content />
     </div>
    );
  }
}

export default PTCanvas;
