// CreditCard
//
//  desc:
//    Control for credit card input. Consists of smart icon to display what credit card is inputed.
//    Uses a library from brain tree to determine what type credit card it is. Also expanding on
//    that library, CCValidator object component used to validate the length of type of credit card is correct.
//    Images for the credit card must be imported. The credit card smart icon is a named component.
//
//  props:
//    number:          (optional) cc number value
//    onChange:        (optional) change handler to update data model
//    onValidate:      (optional) validate handler
//    isValid:         (optional) if true, will highlight input box in red
//    id:              (required) id of cc input to update the data model
//    name:            (optional) name of the form input box
//    autoFocus:       (required) to focus on control when edit link on review page navigates back to page with control
//    {children}:      (optional) the credit card smart icon 
//

import React, { Component } from 'react';
import {GetCardType, CardType} from './CardTypes';
import { Validation, Change } from '../Utility';

import Visa from '../resources/images/visa@2x.png';
import MC from '../resources/images/mastercard@2x.png';
import Discover from '../resources/images/discover@2x.png';
import AMEX from '../resources/images/amex@2x.png';
import UnknownCard from '../resources/images/card@2x.png';

import './credit_card.css';


// note this validator probably should extend Validation in the Utility file.
// 
export const CCValidator = (function(cc){
  const validateLength = function(type, number){
    const validLengths = type.lengths;
    const numberLength = number.length;
    const matchStrength = type.matchStrength;
    const code = type.code;
    const checkLength = validLengths.find(function(len) {
      return numberLength == len;
    })
    return checkLength !== undefined
  }
  return {
    valid: function(number){
      if (cc(number)) {
        return validateLength(cc(number), number);
      } else {
        return false;
      }
    }
  }
})(CardType);

export class CreditCardSmartIcon extends Component {
  constructor(props)
  {
    super(props);
  }
  displayCreditCardIconFor(cardNumber) {
    var cardType = GetCardType(cardNumber).toLowerCase().trim();
    var img = null;
    switch (cardType) {
      case 'visa':
        img = Visa;
        break;
      case 'mastercard':
        img = MC;
        break;
      case 'discover':
        img = Discover;
        break;
      case 'american-express':
        img = AMEX;
        break;
      default:
        img = UnknownCard;
    }
    return (
      <img src={img} />
    )
  }
  render() {
    const number = this.props.cardNumber || '';
    return (
      <div className="CreditCard">
        {this.displayCreditCardIconFor(number)}
      </div>
    );
  }  
}

class CreditCard extends Component {
  constructor(props)
  {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleValidate = this.handleValidate.bind(this);
  }
  
  handleChange(event) {
    var number = event.target.value;
    // only allow digits for credit card input
    // this will break if the card number is masked!
    if (/\D+/g.test(number)) {
      return;
    }
    if (this.props.onChange) {
      this.props.onChange(number);
    }
    event.preventDefault();
  }
  
  handleValidate(event) {
    const validation = new Validation(event)
    validation.addRule(CCValidator);

    if (this.props.onValidate) {
      this.props.onValidate(validation);
    }
    event.preventDefault();
  }

  render() {
    const style = {
      border: this.props.isValid ? null : "2px solid red"
    };
    const id = this.props.id;
    const name = this.props.name;
    const autoFocus = this.props.autoFocus;
    return (
      <div className="CreditCard">
        <label htmlFor='card_number'>Card Number</label>
        <input
          autoFocus={ autoFocus }
          id={ id }
          name={ name }
          type="number"
          style={ style }
          placeholder="Card Number"
          type="text" 
          onChange={ this.handleChange }
          onBlur={ this.handleValidate }
          value={ this.props.number || '' } />
        <div className="credit-card-icon">
          { this.props.children }
        </div>
      </div>
    );
  }
}

export default CreditCard;
