// SecurityCodeInput
//
//  desc:
//    security control component
//
//  props:
//    value:                (optional) security code
//    id:                   (required) used to update the data model
//    name:                 (optional) name of control
//    title:                (optional) label of control
//    placeholderText:      (optional) if provided, will display ghosted placeholder text in the input box. Note: there is no placeholder text by default
//    textBoxWidth:         (optional) width
//    tooltipText:          (optional) tooltip
//    onChange:             (optional) a callback function to update the data model
//    onValidate:           (optional) a callback function to validate input

import React, { Component } from 'react';
import './SecurityCodeInput.css';

class SecurityCodeInput extends Component {
  constructor(props)
  {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }
  
  getPlaceholderText()
  { //defaults to empty string if no prop provided
    return this.props.placeholderText != null ? this.props.placeholderText : "" ;
  }
  
  getTextBoxWidth()
  { 
    //defaults to 50 if no prop provided
    return this.props.textBoxWidth != null ? this.props.textBoxWidth : "50%" ;
  }

  getTooltipText()
  { //defaults to empty string if no prop provided
   return this.props.tooltipText != null ? this.props.tooltipText : ""
  }
  
  handleChange(event)
  {
    const number = (event.target.value).trim();

    if (this.props.onChange) {
      this.props.onChange(number);
    }
    event.preventDefault();
  }
   
  handleBlur(event)
  {
    const inputText = (event.target.value).trim();

    // we could leverage the credit card type utility to know the length
    // of code based on credit card type if we wanted to
    if (this.props.onVisited) {
      this.props.onVisited();
    }
  }

  getInputClasses()
  {
    var retClass = 'SecurityCodeInput';
    if (!this.props.isValid )
    {
      retClass += ' SecurityCodeInput_SecurityCodeError'
    }
    return retClass;
  }

  
  render() {
    const securityCode = this.props.value;
    const id = this.props.id;
    const name = this.props.name;
    return (
      <div className = {this.getInputClasses()}>
        <div>
          {this.props.title} &nbsp; &nbsp;
          <span><a href = "https://www.cvvnumber.com/cvv.html" target="_blank"><i className="fas fa-info-circle fa-lg" style={{color: 'grey' }}></i></a></span>
        </div>
        <input
            id={id}
            name={name}
            style={{width:this.getTextBoxWidth()}}
            type="text"
            maxLength="4"
            value={securityCode}
            placeholder={this.getPlaceholderText()}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            name={this.props.name}
            title={this.getTooltipText()}
        />
      </div>
    );
  }
}

export default SecurityCodeInput;
