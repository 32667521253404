import React, { Component } from 'react';
import ColorPicker from '../../ColorPicker';
import './ColorPickerDemo.css';
 
class ColorPickerDemo extends Component {
  constructor(props){
    super(props);
    this.openColorPicker = this.openColorPicker.bind(this);
    this.closeColorPicker = this.closeColorPicker.bind(this);
    this.handleChangeComplete = this.handleChangeComplete.bind(this);
    this.state = {
      visible: false,
      chosenColor: '#fff'
    }
  }
  openColorPicker(evt) {
    const visible = this.state.visible;
    this.setState({
      visible: !visible,
    });
  }
  closeColorPicker(evt) {
    this.setState({
      visible: false
    });
  }
  handleChangeComplete(color, evt) {
    console.log(color);
    this.setState({ chosenColor: color });
  }
  render() {

    return (
      <div className="ColorPickerDemo"  style={{textAlign: 'left', padding: '20px', marginBottom: '10px', border: '2px dashed green'}}>
        <h4>Aphrodite - Design page -Color Chooser</h4>
        <div>Color Picker Demo</div>
        <div>
          <a href="https://paytrace.atlassian.net/browse/PC2-96">
          https://paytrace.atlassian.net/browse/PC2-96</a>
        </div>
        
        <hr/>

        {/* we could make this a component */}
        <div className="InputBoxCustom">
          <input type="text" onChange={(evt) => this.handleChangeComplete(evt.target.value) } placeholder="Color.." name="search" value={ this.state.chosenColor }/>
          <button type="submit" onClick={this.openColorPicker}>
            <div style={{ backgroundColor: this.state.chosenColor }} className="innerButtonColorSwatchSquareThing"></div>
          </button>
        </div>

        <ColorPicker
          visible={ this.state.visible }
          color={ this.state.chosenColor }
          onChangeComplete={ this.handleChangeComplete } 
          onCloseColorPicker={ this.closeColorPicker }/>

      </div>
    );
  }
}

export default ColorPickerDemo;
