// commsHelper.js
//
//  desc:
//    network functionality provided for retrieving data from an api. 
//    To use, import function 'callToEndpoint' 
//  
//  Note
//  If credentials are include then cookies for customer passed to server automatically.
//  If credentials are same-origin then cookies for customer passed to server only if same origin is true.
//  For anything dealing with customer token, we need that session to be sent to the server to process a payment for that customer.
//  We should check for cross scripting with the CSRF token. TODO

function status(response) 
{
  if (response.status >= 200 && response.status < 300) 
  {
    return Promise.resolve(response)
  } 
  else 
  {
    return Promise.reject(new Error(response.statusText))
  }
}


function json(response) 
{
  return response.json()
}

function getCSRFToken(){
  // getCookie("CSRF-TOKEN")
  // TODO not implemented
  return "";
}

// callToEndpoint
//
// expects:
//    endpoint: hash that contains the endpoint path and the endpoint type
//              path should be of the type "/index.html" 
//              type should be either "GET" or "POST"
//            example endpoint = {path: '/v1/mid_info', type: "GET"}
//
//    data:     data provided in a json format that will be provided to the servers endpoint
//            example data = {merchant_id: 1234}
//
//    callback: callback function that will be called with a single parameter that is the returned json data from the endpoint call
//    abortcontroller: to allow aborting asnc task if needed.
//
// note: can comment out cors and same-origin and leviathan can allow any and not expect credentials for local development testing.
export function callToEndpoint(endpoint, data, callback, abortController)
{
  var endpointUrl = process.env.API_URL + endpoint.path
  let settings = {credentials: "include"}
  if (process.env.NODE_ENV == 'local') {
    // console.log("Using Local configuration - connecting to: ", process.env.API_URL + endpoint.path)
    // NOTE: to use localhost. change api to allow any origin and not require credentials
    settings = {
      method: 'GET',
      mode: 'cors', //default cors
      credentials: 'omit',
      headers: {
        'Content-Type': 'application/json'
      },
      referrer: 'client', // no-referrer, *client
    }
  }

  if (endpoint.type == 'POST')
  { 
    // can I set domain to a shorter domain like "paytrace.com" and
    // since leviathan cors allows anything under that domain. Would that
    // be considered same-origins so cookies will be passed? Do we have to set mode to same domain.
    // document.domain = "paytrace.com". For now just including credentials. Need to look into this.
    // We need to send cookie value to leviathan for customer login to work.

    //POST types have different needed settings....  provided docs on different options plus the defaults (*)
    //. also stringify the data for POSTing
    settings = 
    {
      method: "POST",               // *GET, POST, PUT, DELETE, etc.
      mode: "cors",                 // no-cors, cors, *same-origin
      cache: "no-cache",            // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "include",       // include, *same-origin, omit
      headers: 
      {
        "X-CSRF-Token": getCSRFToken(),
        "Content-Type": "application/json",
        "PayTrace-Product": "paymentlink"
      },
      redirect: "follow",           // manual, *follow, error
      referrer: "no-referrer",      // no-referrer, *client
      body: JSON.stringify(data)    // body data type must match "Content-Type" header
    }
  }
  else if (endpoint.type == 'GET')
  {
    //url encode the parameters and add them to the url for a GET
    var searchParams = new URLSearchParams();
    for(var key in data)
    {
      searchParams.append(key, data[key]);
    }
    endpointUrl = endpointUrl + '?' + searchParams.toString();
  }
  if (abortController) {
    // if abort controller was passed in then allow aborting this fetch
    settings['signal'] = abortController.signal
  }

  var retVal = [];
  fetch(endpointUrl, settings)
  .then(status)
  .then(json)
  .then(data => 
  {
    retVal = data;
    callback(data);
  })
  .catch(error => console.error('Error in calling endpoint:', error, endpointUrl));

  return retVal;
}

