// Review
//
//  desc:
//    review page
//
//  props:
//    IAmNotARobot - handler for recaptcha change
//    data.paymentMethod - to determine to show cc or ach
//    data.checkingAccount - fill review table
//    data.cc - fill review table
//    data.paymentType - fill review table
//    data.payon - fill review table
//    data.amount - fill review table
import React, { Component } from 'react';
import PaymentSummary from '../../../PaymentSummary';
import './review.css';
import './review.small.css';
import Recaptcha from '../../../Recaptcha';
import {GetCardType, CardType, GetCardTypeName} from '../../../CreditCard/CardTypes';


class Review extends Component {
  constructor(props){
    super(props);
    this.onRecaptchaChange = this.onRecaptchaChange.bind(this);
    this.onAgreementCheckboxChange = this.onAgreementCheckboxChange.bind(this);
    
    const totalAmount = this.props.formdata.amount;
    const payon = this.props.formdata.payon;
    const paymentType = this.props.formdata.paymentType;
    
    // DELETE THIS NOT USED!!
    // let paymentInfo = "";
    // if (this.props.formdata.paymentMethod && this.props.formdata.paymentMethod.toLowerCase() == 'cc') {
    //   const cc = this.props.formdata.cc;
    //   const type = GetCardTypeName(cc)
    //   paymentInfo = `${type} *${cc.substr(cc.length - 4)}`;
    // } else {
    //   const checkNum = this.props.formdata.checkingAccount;
    //   if (checkNum !== undefined) {
    //     paymentInfo = `Checking *${checkNum.substr(checkNum.length - 4)}`;
    //   } else {
    //     paymentInfo = '';
    //   }
    // }
    // this.items = {
    //   "Total": `\$${totalAmount}`
    //   ,"Payment Type": paymentType
    //   ,"Pay On": payon
    //   ,"Payment Info": paymentInfo
    // }
  }
  
  reviewProps() {
    let paymentInfo = "";
    if (this.props.formdata.paymentMethod && this.props.formdata.paymentMethod.toLowerCase() == 'cc') {
      const cc = this.props.formdata.cc;
      const type = GetCardTypeName(cc)
      paymentInfo = `${type} *${cc.substr(cc.length - 4)}`;
    } else {
      const checkNum = this.props.formdata.checkingAccount;
      if (checkNum !== undefined) {
        paymentInfo = `Checking *${checkNum.substr(checkNum.length - 4)}`;
      } else {
        paymentInfo = '';
      }
    }
    return {
      "totalAmount": this.props.formdata.amount,
      "convenienceFee": this.props.formdata.convenienceFee,
      "payon":  this.props.formdata.payon,
      "paymentType": this.props.formdata.paymentType,
      "paymentInfo": paymentInfo,
    }
  }
  
  onRecaptchaChange(token) {
    if (this.props.onRecaptchaChange) {
      this.props.onRecaptchaChange(token, true);
    }
  }
  
  onAgreementCheckboxChange(evt) {
    if (this.props.onAgreementCheckboxChange) {
      this.props.onAgreementCheckboxChange(evt.target.checked)
    }
  }

  componentWillUnmount() {
    if (this.props.onAgreementCheckboxChange) {
      this.props.onAgreementCheckboxChange(false)
    }
    if (this.props.onRecaptchaChange) {
      this.props.onRecaptchaChange(null, false);
    }
  }
  
  getTermsLink(){
    let tempTermsLink = this.props.termsLink || '';
    if(tempTermsLink){
      return <label>I agree to the <a href={tempTermsLink} target="_blank">terms and conditions</a></label>
    }  
    else{
      return <label>I agree to the terms and conditions</label>
    }
  }

  
  render() {
    const lTermsLink = this.getTermsLink();
    return (
      <div className='review'>
        <PaymentSummary {...this.reviewProps()} />
        <Recaptcha onChange={ this.onRecaptchaChange } />
        
        <div className="termsAgree">
          <input onChange={this.onAgreementCheckboxChange} checked={this.props.formdata.agreeToTermsChecked || ''} type='checkbox' className='checkbox-round'/>
          {lTermsLink}
        </div>
      </div>
    );
  }
}

export default Review;
