import React, { Component } from 'react';
import {monitorPayTraceSessionTimeout} from '../../lib/PayTrace/session_monitor.js';
import {MIRetriever} from './MerchantInfo.js'

// Promises are async so creating an object that 
// accepts a callback to be called. For example
// can be called in components did mount and accepting
// a callback. Not implemented but we can also 
// set up an abort controller to abort on component unmount event.
// Example this.abortController = new AbortController();
// Not worrying about it now though.
export const Authenticator = {
  isLoggedIn: function(callback){
    const uri = process.env.API_URL + "/authenticate";
    console.debug("process.env.NODE_ENV = ", process.env.NODE_ENV);
    console.debug("process.env.API_URL = ", process.env.API_URL);
  
    if (process.env.NODE_ENV === undefined || process.env.NODE_ENV == 'local') {
      console.debug("Either environment not set or sent to dev. Setting login to true");
      callback({
        user: {name: 'Development User', user_name: "devUser", email: "dev@paytrace.com", is_admin: true, is_reseller: true},
        timeout: 1800,
        isLoggedIn: true
      });
      return;
    }

    let requestSettings = {
      credentials: "include",
      headers: {
        "PayTrace-Product": "paymentlink"
      }
    };

    console.debug("fetching log in info from", uri);
    fetch(uri, requestSettings)
      .then(function(response){
        return response.json();
      })
      .then(function(myJson){
        if (myJson["success"]) 
        {
          callback({
            user: myJson["current_user"],
            timeout: myJson["timeout"],

            isLoggedIn: true
          });
          monitorPayTraceSessionTimeout({
            allowedIdleSeconds: myJson["timeout"],        
            loginUrl: process.env.LEGACY_URL + "/login.pay",
            refreshUrl: process.env.API_URL + "/session_refresh",
          }); 

        } else {
          callback({
            user: null,
            timeout: null,
            isLoggedIn: false
          });
        }
      })
      .catch(function(error){
        console.error("Error trying to authorize user", error);
        console.error("Network error so Setting isLoggedIn to null for pending");
        callback({
          user: null,
          timeout: null,
          isLoggedIn: false
        });
      });
  }
}

// Context is exported so to consume data from context provider use the components
// <UserContext.Consumer></UserContext.Consumer>
const UserContext = React.createContext({
  user: null,
  timeout: null,
  isLoggedIn: null,
  merchantInfo: null,
  surchargeInfo: null
});

// Provides context for user profile to 'implicitly' pass props downward
// and allowing components to consume properties without threading the props
// through the component hierarchy.
class UserContextProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: null,
      timeout: null,
      user: null,
      merchantInfo: null,
      surchargeInfo: null,
      dispatch: action => {
        this.setState(state => this.reducer(state, action));
        action.callback(this.state);
      }
    };
  }

  componentDidMount() {
    // TODO Right now authenticating the user once but we will need to
    // somehow implement a timeout and maybe find a better place for this.
    const me = this;
    Authenticator.isLoggedIn(({user, timeout, isLoggedIn}) => {
      me.setState({
        isLoggedIn: isLoggedIn,
        timeout: timeout,
        user: user
      })
    });

    //retrieve  merchant info related to this user
    MIRetriever.getMerchantInfo(({merchantInfo}) => {   //TODO: may need to ensure that the user is logged in before this is called 
      me.setState({
        merchantInfo: merchantInfo
      })
    });

    // retrieve merchant surcharge info related to this user
    MIRetriever.getSurchargeInfo(({surchargeInfo}) => {
      me.setState({
        surchargeInfo: surchargeInfo
      })
    });

  }

  // Can be implemented to allow passing functions as a context.
  // And allow the childe component to dispatch updates to state of 
  // the provider component.
  // Was planning on using it on routes and redirects because 
  // for those components we are unable to pass methods down to the props of
  // children routed components, only strings properties are allowed.
  // Dont need it now though.
  reducer(state, action) {
    console.log("not implemented", state, action);
    return {
      isLoggedIn: !state.isLoggedIn,
    }
  }

  render() {
    const children = this.props.children;
    return (
        <UserContext.Provider value={this.state}>
            {children}
        </UserContext.Provider>
    )
  }
}

export {UserContext};
export default UserContextProvider;
