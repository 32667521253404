// SideBar
//
//  desc:
//    parent container for anything to be displayed in a side bar. (usually the nav menu)
//  required props:
//    TBD
//

import React, { Component } from 'react';
import './index.css'

import MenuLegacy from './MenuLegacy';


class SideBar extends Component {

  constructor(props)
  {
    super(props);
    

    this.state =
    {
      menu_nodes: "loading",
      authorized: true,
    };
  } 


  componentDidMount()
  {   
    var menu_url = process.env.API_URL + '/menu/get_markup';
    // TODO: render menu based off of configuration maybe?
    // returning a fake menu should figure a way to work locally without
    // needing to get external resource
    // if (false) {
    //     var data = "<ul>\
    //         <li>\
    //             <a href='#' user-action='Dashboard'>\
    //             <i className='icon-home'></i>Dashboard</a></li>\
    //             <li className='submenu'>\
    //                 <a href='#adminmenu'><i className='fa fa-fw fa-caret-right'></i>Admin</a>\
    //                 <ul id='adminmenu'>\
    //                 </ul>\
    //             </li>\
    //         </li>\
    //     </ul>"

    //     const doc = new DOMParser().parseFromString(data, "text/html");
    //     this.setState({menu_nodes: doc.body, authorized: true});
    //     return;
    // }

    let requestSettings = {
      credentials: "include",
      headers: {
        "PayTrace-Product": "paymentlink"
      }
    };

    fetch(menu_url, requestSettings).then(results => 
    {
      return results.text();
    }).then(data => 
    {

      if (data.indexOf("NotAuthorized") != -1)
      { //testing to see if we got an not authorized response.... we dont want to try rendering certain things if we arent logged in
         this.setState({authorized: false});
        return;
      }

      const doc = new DOMParser().parseFromString(data, "text/html");
      this.setState({menu_nodes: doc.body, authorized: true});
    })
  }


  render() {
    let menu;
    if (this.state.authorized == false)
    { //testing to see if we got an not authorized response.... we dont want to try rendering certain things if we arent logged in
      menu = <div> please login to continue... </div>
    }
    else if (this.state.menu_nodes == "loading" )
    {
      menu = <div> loading... </div>
    }
    else 
    {
      menu =  <MenuLegacy menu_nodes={this.state.menu_nodes}/>
    }
    return (
        <div className='sidebar'>
          {menu} 
        </div>
     );
  }
}

export default SideBar;
