import React, { Component } from 'react';
import TempPlaceholder from '../../Placeholders';
import PasswordNew from '../../PasswordNew';

// Implement the PasswordInput and add it to the src components folder.
// Then take the real implemented component and render it here on demo scratch pad for QA.

class PasswordInputDemo extends Component {
  constructor(props)
  {
    super(props);
    this.isPasswordValid = this.isPasswordValid.bind(this);
    this.state = ({passwordValid : false})
  }

  componentDidMount() {
  }

  //callback function for new password component to indicate if the entered password is valid or not
  isPasswordValid(isValid, password)
  {
    console.debug("TODO: hit the password valid callback ", isValid)
    this.setState({passwordValid: isValid});
    this.setState({currentPassword: password});
  }

  getSaveButton()
  {
    var isDisabled = true;
    if (this.state.passwordValid == true)
    {
      isDisabled = false;
    }
    return <button type='button' disabled={isDisabled} > Save! </button>
  }

  render() {
    return (
      <div className="PasswordInput">

        <div className="component">
          <h4>Password Input component</h4>
          <div><a href='https://paytrace.atlassian.net/browse/PC2-69'>PC2-69</a></div>
          <div>like a input text box component but for passwords</div>
          <hr/>
          <div>
            <PasswordNew isPasswordValidCallback={this.isPasswordValid}/>
          </div>
          {this.getSaveButton()}
        </div>

      </div>
    );
  }
}

export default PasswordInputDemo;