export function monitorPayTraceSessionTimeout(options) {
  // Set this so it is easier to manipulate in the console.  Set this property
  // to `true` to get messages logged to the console
  window.logSessionMonitoring = window.localStorage && window.localStorage.getItem('paytrace-session-monitor-logging') == 'on';
  
  var allowedIdleSeconds = options.allowedIdleSeconds,
    loginUrl = options.loginUrl,
    refreshUrl = options.refreshUrl;

  // Milliseconds to "Nagle" user input events before sending a keepalive ping
  var KEEPALIVE_BUFFER_MILLIS = 10000;   
  // Milliseconds before expected session expiration at which keepalive
  // becomes aggressive
  var KEEPALIVE_SAFETY_MARGIN = 30000;   
  var SESSION_TIMEOUT_COOKIE = "ptc_st";
  
  function shiftDate(d, offsetMs) {
    return new Date(d.getTime() + offsetMs);
  }
  
  // Adapted from https://www.w3schools.com/js/js_cookies.asp
  function getCookieValue(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i = 0; i <ca.length; i++) {
      var c = ca[i].replace(/^ +/, '');
      if (c.substring(0, name.length) === name) {
        return JSON.parse(atob(c.substring(name.length, c.length)));
      }
    }
    return {};
  }
  
  function setCookieValue(cname, value) {
    document.cookie = cname + "=" + btoa(JSON.stringify(value)) + "; domain=.paytrace.com; path=/";
  }
  
  if (allowedIdleSeconds == 0) {
    // For some reason the server-side code didn't know the session timeout
    // length, so just mark the session updated in the cookie if it is out
    // of date:
    var workVal = getCookieValue(SESSION_TIMEOUT_COOKIE), now = Date.now();
    if (typeof workVal.sessionExpires !== "undefined" && workVal.sessionExpires < now) {
      workVal.sessionExpires = now;
      setCookieValue(SESSION_TIMEOUT_COOKIE, workVal);
    }
    return;
  }
  var sessionExpires = Date.now() + allowedIdleSeconds * 1000;
  var keepAliveAt = null;
  
  function updateWindowProperty() {
    window.sessionExpires = new Date(sessionExpires);
  }
  updateWindowProperty();
  
  var CHECKPOINT_CALLBACK_PROP = "checkpointCallback";
  function atNextCheckpoint(fn) {
    var checkTime = sessionExpires + 5000, cbinfo;
    if (keepAliveAt != null && keepAliveAt < checkTime) {
      checkTime = keepAliveAt;
    }
    // The callback function is responsible for calling for a reschedule, if
    // necessary, when it receives its call.  This produces "joining" of the
    // callback chains so we don't get a number of independent chains issuing
    // large numbers of keepalive requests and large numbers of delayed
    // callbacks that need to be handled.  Therefore, only one delayed callback
    // for fn should be scheduled at any one time, with the pending one being
    // being cancelled if the callback needs to happen sooner.
    if (typeof (cbinfo = fn[CHECKPOINT_CALLBACK_PROP]) === "object") {
      if (cbinfo.time <= checkTime) {
        // The function is being called back before checkTime -- it can
        // reschedule if appropriate
        return;
      } else {
        // The callback needs to happen sooner, so cancel the existing one
        // and schedule a new one.
        window.clearTimeout(cbinfo.timer);
      }
    }
    cbinfo = fn[CHECKPOINT_CALLBACK_PROP] = {time: checkTime};
    if (window.logSessionMonitoring) {console.log("SESSION MONITOR: scheduling checkpoint for %s", new Date(checkTime));}
    cbinfo.timer = window.setTimeout(function() {
      // Remove the callback info, which is for this callback.
      delete fn[CHECKPOINT_CALLBACK_PROP];
      fn();
    }, checkTime - Date.now());
  }
  
  // Only use the "force" option during page load
  function updateTrackingCookie(options) {
    options = options || {};
    var workVal = getCookieValue(SESSION_TIMEOUT_COOKIE);
    if (options.force || (workVal.sessionExpires || 0) < sessionExpires) {
      workVal.sessionExpires = sessionExpires;
      workVal.allowedIdleSeconds = allowedIdleSeconds;
      setCookieValue(SESSION_TIMEOUT_COOKIE, workVal);
    }
  }
  // Update the tracking cookie forcefully because this page just loaded and
  // we know from the server what the timeout interval is _right now_:
  updateTrackingCookie({force: true});
  
  function checkForSessionTimeout() {
    var nowTime = Date.now(), cookieVal;
    if (sessionExpires <= nowTime) {
      if (window.logSessionMonitoring) {console.log("SESSION MONITOR: Navigating to login page at %s", new Date());}
      // Could be timeout, but another tab might have refreshed, so check the cookie
      cookieVal = getCookieValue(SESSION_TIMEOUT_COOKIE);
      if ((cookieVal.sessionExpires || nowTime) <= nowTime) {
        document.cookie = "pturlc=" + window.location + "; domain=.paytrace.com; path=/";
        window.location = loginUrl + (loginUrl.indexOf("?") >= 0 ? "&" : "?") + "autonav=session-expired";
      } else {
        sessionExpires = cookieVal.sessionExpires;
        if (typeof cookieVal.allowedIdleSeconds === "number") {
          allowedIdleSeconds = cookieVal.allowedIdleSeconds;
        }
        updateWindowProperty();
        atNextCheckpoint(checkForSessionTimeout);
      }
    } else if (keepAliveAt != null && keepAliveAt <= nowTime) {
      if (window.logSessionMonitoring) {console.log("SESSION MONITOR: Sending keepalive at %s", new Date());}
      sendKeepalive();
    } else {
      if (window.logSessionMonitoring) {console.log("SESSION MONITOR: at %s, scheduling new callback", new Date());}
      atNextCheckpoint(checkForSessionTimeout);
    }
  }
  atNextCheckpoint(checkForSessionTimeout);
  
  // Observe AJAX successes and count those, as they will refresh the token
  (function() {
    var origOpen = XMLHttpRequest.prototype.open;
    XMLHttpRequest.prototype.open = function() {
        // Test reqOptions.url for ".paytrace.com" server
        this.addEventListener('load', function() {
             if (this.responseURL.search(/^(https:\/\/(|[^\/]+\.)paytrace\.com)?\//) >= 0) 
             {
              sessionExpires = Date.now() + allowedIdleSeconds * 1000;
              updateWindowProperty();
              updateTrackingCookie();
              atNextCheckpoint(checkForSessionTimeout);
            }
       });
        origOpen.apply(this, arguments);
    };
  })();

 
  // Schedule the keepalive check-in callback one second _after_ the
  // given time -- there is enough play in the session timeout to allow this
  // and browsers sometimes call back a few milliseconds early.
  function scheduleKeepalive(kaTime) {
    keepAliveAt = kaTime;
    window.setTimeout(function() {
      if (keepAliveAt != null && keepAliveAt <= Date.now()) {
        sendKeepalive();
      }
    }, kaTime - Date.now() + 1000);
  }
  
  function noteSessionActivity(e) {
    var limitTime = sessionExpires - KEEPALIVE_SAFETY_MARGIN;
    var nowTime = Date.now();
    var kaTime = Math.min(nowTime + KEEPALIVE_BUFFER_MILLIS, limitTime);
    if (kaTime < nowTime) {
      sendKeepalive();
    } else if (keepAliveAt != null && nowTime <= keepAliveAt) {
      // Pending callback is already set to send a keepalive unless we
      // bump keepAliveAt back.  Only bump it back if the the keepalive
      // request would go out before the "idle window" after this observed
      // event is complete; this is a "pseudo-Nagle" algorithm.
      if (keepAliveAt < nowTime + KEEPALIVE_BUFFER_MILLIS) {
        // Increment up with keepAliveAt, don't base on nowTime (increments are too fine)
        kaTime = Math.min(keepAliveAt + KEEPALIVE_BUFFER_MILLIS, limitTime);
        if (keepAliveAt < kaTime) {
          scheduleKeepalive(kaTime);
        }
      }
    } else {
      scheduleKeepalive(kaTime);
    }
  }
  
  var sendKeepalive = (function() {
    var sending = false;
    return function() {
      if (!sending) {
        sending = true;
        keepAliveAt = null;

        var httpRequest = new XMLHttpRequest()
        httpRequest.onreadystatechange = function (data) {
          sending = false;
        }
        httpRequest.withCredentials = true;
        httpRequest.open('GET', refreshUrl)
        httpRequest.send()
      }
    };
  })();
  
  // Observe user input (keyboard, click, scrolling) and ping the
  // keepalive endpoint as necessary:
  ["scroll", "keydown", "click"].forEach(function(eventName) {
    window.addEventListener(eventName, noteSessionActivity, true);
  });
}
