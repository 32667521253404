import React, { Component } from 'react';

class HyperLink extends Component {
  constructor(props) {
    super(props);
  } 
  
  render() {
    return (
        <a href={this.props.url} title= {this.props.titleText} target={this.props.targetWindow} style={{color:this.props.color}} >{this.props.icon}{this.props.name}</a>    
    )
  }
}

export default HyperLink;