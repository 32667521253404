import React, { Component } from 'react';

export class ClosedMenuIcon extends Component {
  render() 
  {
    return (
      <i className="fa fa-fw fa-caret-right"></i>
    );
  }
}

export class OpenMenuIcon extends Component {
  render() 
  {
    return (
      <i className="fa fa-fw fa-caret-down"></i>
    );
  }
}

class Icon extends Component {
  constructor(props) {
    super(props);
  }
  render() 
  {
    return (
      <i className={this.props.className}></i>
    );
  }
}

export default Icon;