// Icon
//
//  desc:
//    provides predefined/loaded icons for the app
//    If img src is given will try to render preloaded images.
//    If class name is give will load font awesome icons.
//
//  props:
//    img:                (depends) img or className required
//    className:          (depends) same as above
//    height:             (optional)
//    width:              (optional)
//    fgcolor:            (optional)
//    bgcolor:            (optional) 
//

/*
Central location for shared icons and corresponding images.
Also can reference font awesome icons. Note images must be imported
to be used because it needs to be transpiled.
Usage: 
<Icon img='Payment.png' height="1" width="1"/> 
<Icon className="fa fa-pencil" height="1" width="1"/>

img property takes precedence. Just adding font awesome capability
because don't want to go look for a few icons images in mockups.
Note will have to do something about the file extension. For now
just making the img property has the extension. 

Font awesome icons does not have size props. would have to transform.
*/
import React, { Component } from 'react';
import Payment from './Images/Payment.png';
import ReturningUser from './Images/ReturningUser.svg';
import Recurring from './Images/Recurring.svg';
import OneTime from './Images/OneTime.svg';
import Shield from './Images/shield@3x.svg';
import Billing from './Images/Billing.png';
import Review from './Images/Review.png';
import Receipt from './Images/Receipt.png';
import Huh from './Images/huh.png';

class Icon extends Component {
  constructor(props){
    super(props);
  }
  createIcon(width, height) {
    try {
      if (this.props.img) {
        const image = "./Images/" + this.props.img;
        return (
          <img src={require(`${image}`)} width={width} height={height} />
        )
      } else if (this.props.className) {
        return (
          <span style={{fontSize: "18px"}}>
            <i className={this.props.className} />
          </span>
        )
      }
    } catch (err) {
      // if images are not imported above then will fail so show something.
      return <img src={Huh} alt='error' width={width} height={height}/>
    }
  }
  render() {
    const icon = this.createIcon(this.props.width, this.props.height);
    const style = {
      display: 'inline-block',
      marginRight: '10px',
      color: this.props.fgcolor,
      backgroundColor: this.props.bgcolor,
    }
    return (
      <div className="icon" style={style}>
        {icon}
      </div>
    );
  }
}

export default Icon;
