import React, { Component } from 'react';
import TempPlaceholder from '../../Placeholders';

//import Country from '../../../../components/Country';
//import DropDown from '../../../../components/common/DropDown';
import './DropDownDemo.css';


class DropDownDemo extends Component {
  constructor(props)
  {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onCountryChange = this.onCountryChange.bind(this);

    this.state = {
      genericValue: "ba",
      countryValue: "us"
    }
  }

  getHash() {
    return {
      wa: "Washington",
      sea: "seattle",
      cn: "Conneticut",
      ba: "Baltimore"
    }
  }
  
  onChange(event) {
    this.setState({genericValue: event.target.value});
    event.preventDefault();
  }
  
  onCountryChange(event) {
    this.setState({countryValue: event.target.value});
    event.preventDefault();
  }

  render() {
    return (
      <div className="DropDownDemo">
       </div>
    );
  }
}


       // <div className="component">
       //    <h4>DropDown</h4>
       //    <div><a href='https://paytrace.atlassian.net/browse/PC2-66'>PC2-66</a></div>
       //    <hr/>
       //    <div>
       //      <TempPlaceholder background="https://paytrace.atlassian.net/secure/attachment/41651/Screen%20Shot%202019-01-14%20at%2011.41.58%20AM.png" />
       //    </div>
       //  </div>
        
       //  <div className="component">
       //    <label>Generic dropdown: ({this.state.genericValue})</label>
       //    <DropDown options={this.getHash} onChange={this.onChange} value={this.state.genericValue} />
       //  </div>
        
       //  <div className="component">
       //    <label>Country List using generic Dropdown: ({this.state.countryValue})</label>
       //    <Country value={this.state.countryValue} onChange={this.onCountryChange} />
       //  </div>
        
       //  <br/>


export default DropDownDemo;