import React, { Component } from 'react';

// html copy and pasted from legacy system
// did not refactor into smaller components or make generic
// so putting it in this namespace. Used by the side menu
class CustomerExportModal extends Component {
  constructor (props){
    super(props);
  }

  render() {
      const exportCustomerLink = process.env.LEGACY_URL + "/export_customers.pay"
      return (
        <div aria-hidden='true' aria-labelledby='myModalLabel' className='modal fade' id='cust-export-modal-dialog' role='dialog' tabIndex='-1'>
          <div className='modal-dialog' role='document'>
            <div className='modal-content' style={{textAlign: 'left'}}>
              <div className='modal-header'>
                <h4 className='modal-title' style={{margin: '0px'}}>Export Customers</h4>
                <button aria-label='Close' style={{margin: '0px', paddingTop: '0px'}} className='close' data-dismiss='modal' id='cust-export-close-btn' type='button'>
                  <span aria-hidden='true'>×</span>
                </button>
              </div>
              <div className='modal-body' style={{textAlign:'left'}}>
                <p>Download customer data as a csv/excel file.</p>
                <p>
                  It may take a few moments before your download starts.
                </p>
                <p>
                  <a href={exportCustomerLink} className='btn btn-primary' style={{backgroundColor:'#F98E39'}}>
                    <i className='icon-download'></i>
                    Export Customers
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      );
  }
}
 
export default CustomerExportModal;
