// ColorPicker
//
//  desc:
//    color picker. uses a library package called react-color for component.
//
//  props:
//    onCloseColorPicker:   (optional) handler called when color wheel and swatch closes.
//    onChangeComplete:     (optional) handler called when color selected
//    color:                (optional) sets the color value
//
import React, { Component } from 'react';
import { SketchPicker } from 'react-color';

// wrapper for react color picker libary component
class ColorPicker extends Component {
  constructor(props){
    super(props);
    this.onChange = this.onChange.bind(this);
  }
  
  onChange(color) {
    if (this.props.onChangeComplete) {
      this.props.onChangeComplete(color.hex);
    }
  }

  render() {
    const popover = {
      position: 'absolute',
      zIndex: '2',
    }
    const cover = {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    }
    return (
      <div className="ColorPicker">
      {
        (this.props.visible) ? (
          <div style={ popover }>
            <div style={ cover } onClick={ this.props.onCloseColorPicker } />
            <SketchPicker
            disableAlpha={true}
            color={ this.props.color }
            onChangeComplete={ this.onChange }/>
          </div>
        ) : (
          null
        )
      }
      </div>
    )
  }
}

export default ColorPicker;
