// EmailAddressDemo
//
//  desc:
//    placeholder demo component to show off the EmailAddressInput component         
//
//
//  props:
//

import EmailAddressInput from '../../EmailAddressInput';
import './index.css'


import React from 'react';
class EmailAddressDemo extends React.Component
{
 constructor(props)
  {
    super(props)
    this.state = {
      enteredEmailAddress: ""
    };

    this.onEmailAddress = this.onEmailAddress.bind(this);
    this.onEmailAddressLostFocus = this.onEmailAddressLostFocus.bind(this);
  }


  onEmailAddress(emailAddress)
  {
      this.setState({enteredEmailAddress: emailAddress});

  }
  
  onEmailAddressLostFocus(emailAddress)
  {
      this.setState({enteredEmailAddress: emailAddress});
  }

  getHelperTextDiv()
  {
    var helperText = 
      <div className="placeholder_demo_help_text"> props:
        <ul>
          <li> title:                (optional) if provided, will display title above the input box </li>
          <li> reasonText:           (optional) if provided, will display the reason for needing a email address below the title and above the input box </li>
          <li> placeholderText:      (optional) if provided, will display ghosted placeholder text in the input box. Note: there is no placeholder text by default </li>
          <li> value:                (optional) if provided, will set the the email address text for the input box </li>
          <li> inError:              if 'true', will display the email address input in red </li>
          <li> hoverText:            (optional) text to display to the user when the mouse cursor hovers over the input box. By default, there is none </li>
          <li> onTextInputCallback:  a callback function for when the user has entered input </li>
          <li> maxLength:            (optional) will set the max length allowed for the email input (defaults to 87 characters)</li>
          <li> onLostFocusCallback   (optional)a parent callback function with the udpated input(removes the comma) when the inputbox loose the focus  </li>
        </ul>
      </div>
      return helperText;
  }

  render() {
    return (
      <div className="EmailAddressDemo">

        <div className="component" id='emailAddressDemoDiv'>
          <h4>EmailAddressInput component</h4>
          <div><a href='https://paytrace.atlassian.net/browse/PC2-110'>PC2-110</a></div>
          <div>like a input text box component but for email addresses</div>
          {this.getHelperTextDiv()}
          <hr/>
          <div>
            <EmailAddressInput 
              value={this.state.enteredEmailAddress}
              title="Email Address" 
              reasonText="Cuz this is a demo" 
              onTextInputCallback={this.onEmailAddress}
              hoverText="make sure to have an @ and a ."
              placeholderText="enter a valid email address"
              onLostFocusCallback = {this.onEmailAddressLostFocus}
            />
          </div>
        </div>
          Entered Email Address: {this.state.enteredEmailAddress}
      </div>
    );
  }
}

export default EmailAddressDemo;
