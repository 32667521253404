import React, { Component } from 'react';
import TempPlaceholder from '../../Placeholders';
import Recaptcha from '../../Recaptcha';

class RecaptchaDemo extends Component {
  constructor(props)
  {
    super(props);
  }

  componentDidMount() {
  }

  render() {
    return (
      <div className="RecaptchaDemo" style={{textAlign:'left', padding: '20px', marginBottom: '20px', border: '2px dashed green'}}>
        <div>
          <h4>Recaptcha</h4>
          <div>Note need to look at the javascript loading. For now reload the page to see it if it is missing</div>
          <Recaptcha />
        </div>

      </div>
    );
  }
}

export default RecaptchaDemo;