// Receipt
//
//  desc:
//    receipt page
//
//  props:
//
import React, { Component } from 'react';
import Table from '../../../Table';
import {FrequencyList} from '../../RecurringPaymentFields';
import Payment, {PaymentType} from '../../../PaymentForm/Steps/Payment';
import {GetCardType, CardType, GetCardTypeName} from '../../../CreditCard/CardTypes';
import './receipt.css';
import './receipt.small.css';


class Receipt extends Component {
  constructor(props){
    super(props);
  }
  
  records() {
    let paymentInfo = "";
    if (this.props.formdata.paymentMethod !== undefined && this.props.formdata.paymentMethod.toLowerCase() == 'cc') {
      const cc = this.props.formdata.cc;
      const type = GetCardTypeName(cc)
      paymentInfo = `${type} *${cc.substr(cc.length - 4)}`;
    } else {
      const checkNum = this.props.formdata.checkingAccount;
      if (checkNum !== undefined)
        paymentInfo = `Checking *${checkNum.substr(checkNum.length - 4)}`;
    }
    const obj = this.parseResponse();
    const paymentType = this.props.formdata.paymentType;
    const payOn = this.props.formdata.payon;
    const amount = this.props.formdata.amount;
    const convFee = this.props.formdata.convenienceFee;
    const totalAmount = (Number(amount) + Number(convFee)).toFixed(2);


    if ( obj === undefined || obj === null || !(obj.transaction_id > 0) ) {
      // console.log(this.props.location);
      // WHAT DO WE DO?
      return [
        {responseMessage: { title: 'Status', value: 'Could not process transaction' } }
        ,{ tranxId: { title: 'Reason', value: 'Unknown Error' } }
        ,{ payment_timestamp: { title: 'Timestamp', value: Date.now() } }
        ,{ total: { title: 'Total', value: `$${totalAmount}` } }
        ,{ payment_type: { title: 'Payment Type', value: paymentType } }
        ,{ payment_on: { title: 'Pay On', value: payOn } }
      ]
    }
    
    let responseMessage = 'Transaction Declined';
    // just cause vbscript and serialized boolean values might be a string.
    // checking for everything.
    if (obj.transaction_status !== undefined 
          && obj.transaction_status != null
          && (
              obj.transaction_status == 'True' || obj.transaction_status == 'true'
            )
        ) {
      responseMessage = 'Transaction Approved';
    }
    const records = [
      {responseMessage: { title: 'Status', value: responseMessage } }
      ,{ tranxId: { title: 'Transaction #', value: '#' + obj.transaction_id } }
      ,{ payment_timestamp: { title: 'Timestamp', value: obj.timestamp } }
      ,{ total: { title: 'Total', value: `$${totalAmount}` } }
      ,{ payment_type: { title: 'Payment Type', value: paymentType } }
      ,{ payment_on: { title: 'Pay On', value: payOn } }
    ]

    if (paymentType == PaymentType.ONE_TIME) {
      return records.concat([
        { payment_card: {title: 'Payment Info', value: paymentInfo } }
      ]);
    } else {
      return records.concat([
        { payment_card: {title: 'Payment Info', value: paymentInfo } },
      ]);
    }
  }
  
  parseResponse() {
    try {
      const response = this.props.location.state;
      // console.log("response", response)
      return response;
    } catch(err) {
      console.log("Error parsing results from service", err);
    }
    return null;
  }
  
  render() {
    const records = this.records();

    return (
      <div className='receipt'>
        <button style={{float: 'right', marginBottom: '30px'}} className='btn btn-secondary no-print' onClick={() => window.print()}>Print</button>
        <Table rows={records}/>
      </div>
    );
  }
}

export default Receipt;
