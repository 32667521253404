// Content
//
//  desc:
//    This component is responsible for choosing and displaying the various views (the actual content!) that the user will
//    interact with. Think view transactions or reports, for example 
//
//  required props:
//
//

import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Switch, withRouter } from "react-router-dom";

import ContentHeader from './ContentHeader';
import ContentView from './ContentView';
import FormDesigner from '../Views/FormDesigner';
import CreatePaymentLink from '../Views/CreatePaymentLink';
import SettingsPrefDesigner from '../Views/SettingsPrefDesigner';
import EmailPaymentLink from '../Views/EmailPaymentLink';
import CloudPrinters from '../Views/CloudPrinters';

class Content extends Component {

  constructor(props)
  {
    super(props);
  } 

  getContentHeaderTitle() {
    if (this.props.location !== undefined) {
      return {
        '/designer': 'Payment Link Designer',
        '/prefs': 'Payment Link Preferences',
        '/createpaymentlink': 'Create Payment Link',
        '/emailpaymentlink': 'Email Payment Link',
        '/cloudprinters': 'Cloud Printers',
      }[this.props.location.pathname.toLowerCase()];
    }
    return null;
  }

  getContentHeader()
  { //TODO this function is stubbed for now. Will need further implementation as site/views/etc are built out

    return (
        <ContentHeader type={this.getContentHeaderTitle()} />
      );

  }


  getContentView()
  { //TODO this function is stubbed for now. Will need further implementation as site/views/etc are built out
    return (
        <ContentView />
      );

  }



  
  render() {
    let contentHeader = this.getContentHeader();
    let contentView = this.getContentView();

    return (
      <div className='content_menu' id='content'>
        {contentHeader}
        <Route path="/designer" component={FormDesigner}/>
        <Route path="/CreatePaymentLink" component={CreatePaymentLink}/>
        <Route path="/prefs" component={SettingsPrefDesigner}/>
        <Route path="/EmailPaymentLink" component={EmailPaymentLink}/>
        <Route path="/CloudPrinters" component={CloudPrinters}/>
        <Route exact path="/" render={() => {
            return contentView
          }
        }/>
      </div>
    );
  }
}

export default withRouter(Content);
