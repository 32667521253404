// AchInput
//
//  desc:
//    inputbox control for ACH processing 
//
//  props:
//    onChange:             (optional) event handler for on change event mainly for updating control state
//    onValidate:           (optional) event handler for validting input field
//    icon:                 (optional) if provided, will display icon in inputbox
//    placeholder:          (optional) if provided, will display ghosted placeholder text in the input box. Note: there is no placeholder text by default
//    width:                (optional) if provided, will set the the width of textbox
//    isValid:              (optional) if 'true', will display the input box outlined in red 
//    value:                (optional) controlled value for input box
//    tooltipText           (optional) tooltip
//    name                  (optional) name attribute of the form input field
//    maxLength             (optional) max length of input
//    id                    (required) required unless name is given. used so input changes can update the data model.

import React, { Component } from 'react';
import {Validation, Change} from '../Utility';

import './AchInput.css';


class AchInput extends Component {
  constructor(props){
    super(props);
    this.state = {
      inError: false
    }
    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }
  
  onChange(event) {  
    if (this.props.onChange) {
      this.props.onChange(new Change(event));
    }
  }
  
  onBlur(event) {
    const inputText = event.target.value;
    const isError = inputText.length > event.target.maxLength || ( parseInt(inputText) < 0 || inputText < 0 || inputText ==  '');

    this.setState( { inError: isError } );

    const id = event.target.name || event.target.id;
    if (this.props.onValidate) {
      this.props.onValidate({
        id: id,
        validity: !isError,
        element: event.target
      });
    }
  }
  
  createIconInside() {
    if (this.props.icon) {
      return (
        <span>
          {this.props.icon}
        </span>
      )
    }
  }
  
  render() {
    const placeholder = this.props.placeholder
    const style = {};
    if (this.props.width) {
      style.width = this.props.width;
    }
    
    if (this.props.isValid !== undefined && !this.props.isValid) {
      style.border = "2px solid red";
    }
    
    const value = this.props.value || '';
    const tooltip = this.props.tooltipText;
    const name = this.props.name;
    const maxLength = this.props.maxLength;
    const id = this.props.id;
    const autoFocus = this.props.autoFocus;

    return (
      <div className="AchInput">
        {this.props.children}
        {this.createIconInside()}
        <input 
            id={id}
            autoFocus={ autoFocus }
            style={style}
            type="text"
            value={value}
            placeholder={placeholder}
            onChange={this.onChange}
            name={name}
            maxLength={maxLength}
            title = {tooltip}
            onBlur = {this.onBlur}
        />
      </div>
    );
  }
}

export default AchInput;
