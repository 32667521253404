import React, { useContext } from 'react';
import hoistStatics from "hoist-non-react-statics";
import invariant from "tiny-invariant";
import PropTypes from "prop-types";
import { GetBranding } from '../comms';
import UserContextProvider, { UserContext } from '../../components/Login/UserContext';
import Logger from '../Logger';


const themes = 
{
  paytrace: {
    primary_color:      '#E7E5E4', 
    secondary_color:    '#3399CC',
    tertiary_color:     '#3399CC',
    footer_color:       '#666666',

    btn_bg_color:       '#5AA2CD',  //denotes the background color of clickable items like buttons and dropdowns. This should be a themeable item, but currently this value is not retrieved as a theme from legacy. This is the default PayTrace value at this time
    btn_text_color:     '#FFFFFF',  //denotes the text color of clickable items like buttons and dropdowns. This should be a themeable item, but currently this value is not retrieved as a theme from legacy. This is the default PayTrace value at this time    
    logo_guid:          null,       //Defualted to null to indicate that we should use a local logo file. If not null, will indicate the guid to be used off of the legacy server
    footer_text:        null,      //Defualted to null to indicate that we should use a local message and Feedback link . If not null, will indicate the Footer_text be used off of the legacy server
  },
  dark: {
    primary_color:      '#E7E5E4', 
    secondary_color:    '#eeeeee',
    tertiary_color:     '#eeeeee',
    footer_color:       '#eeeeee',
    btn_bg_color:       '#5AA2CD', //denotes the background color of clickable items like buttons and dropdowns. This should be a themeable item, but currently this value is not retrieved as a theme from legacy. This is the default PayTrace value at this time
    btn_text_color:     '#000000',  //denotes the text color of clickable items like buttons and dropdowns. This should be a themeable item, but currently this value is not retrieved as a theme from legacy. This is the default PayTrace value at this time
    logo_guid:          null,             ////Defualted to null to indicate that we should use a local logo file. If not null, will indicate the guid to be used off of the legacy server
    footer_text:        null,      //Defualted to null to indicate that we should use a local message and Feedback link . If not null, will indicate the Footer_text be used off of the legacy server
  },
};

const ThemeContext = React.createContext(
  themes.paytrace // default value
);


/*  Example of how to use the theme context from within a component as a consumer. (note: this example is from the ContentHeader component)

      <ThemeContext.Consumer>
          {theme => (
            <div className='ContentHeader' id='content-header'>
              <h1 style={{color: theme.secondary_color}}> {this.props.type} </h1>
            </div>
          )}
      </ThemeContext.Consumer>

      __or__

      export default withBranding(component);

      ***NOTE****
      (must wrap it with Branding provider to call API and override defaults
      If it isn't then the defaults that were originally set will be used. See above!)

      render() {
        return <Branding>
          ...
          <Component>
          ...
        </Branding>
      }
*/
class Branding extends React.Component {
  constructor(props) {
    super(props);
    this.state = themes.paytrace;
  }

  componentDidMount() {
    let merchant = this.context;
    let merchant_id = null;
    if (merchant && merchant.merchantInfo) {
      merchant_id = merchant.merchantInfo.merchant_id;
    }

    GetBranding({id: merchant_id})
    .then(function(resp){
      if (resp)
        Logger.log("API network call to retrieve branding. Id: ", merchant_id);
        const newState = Object.assign({}, this.state, {
            primary_color:      resp.theme.primary_color,
            secondary_color:    resp.theme.secondary_color,
            tertiary_color:     resp.theme.tertiary_color,
            footer_color:       resp.theme.footer_color,
            btn_bg_color:       resp.theme.btn_bg_color || '#5AA2CD',    //denotes the background color of clickable items like buttons and dropdowns. This should be a themeable item, but currently this value is not retrieved as a theme from legacy. This is the default PayTrace value at this time
            btn_text_color:     resp.theme.btn_text_color || '#FFFFFF',  //denotes the text color of clickable items like buttons and dropdowns. This should be a themeable item, but currently this value is not retrieved as a theme from legacy. This is the default PayTrace value at this time    
            logo_guid:          resp.theme.logo_guid || '',       //space indicate that we should use a local logo file because server returned nothing. 
            footer_text:        resp.theme.footer_text || '',
        });
        this.setState(newState);
    }.bind(this));
  }


  render() {
    const children = this.props.children;
    return (
        <ThemeContext.Provider value={this.state}>
            {children}
        </ThemeContext.Provider>
    )
  }
}

/**
 * A HOC component to access the branding context that is retrieved
 * for merchant from branding API.
 * Note: transfering props from source component to target not implemented.
 */
export const withBranding = function(Component) {
  const displayName = `withBranding(${Component.displayName || Component.name})`;

  const C = props => {
    // if we had the spread e6 operator we could have done this.
    //    const { wrappedComponentRef, ...remainingProps } = props;
    //    <Component {...remainingProps} ref={wrappedComponentRef} />
    // I give up, I do not know how to do this without using spread operater.
    // Leaving the code here just some feels up to figuring out how to do it without spread.
    // Or someone desides to put in the E6 polyfill for spread operator.
    //    const ClonedComponent = React.createElement(Component, Object.assign({}, props));
    return (
      <ThemeContext.Consumer>
        {context => {
          // throw error if context is undefined. Needs to be within a ThemeContext Provider
          invariant(
            context,
            `You should not use <${displayName} /> outside a <Branding>`
          );

          // we are not spreading the theme context in other places so will not worry about spreading it here.
          // NOTE other props besides location props from router HOC are not transfered.
          // right now we do not have any other properties so should be ok. So just explicitly setting them all.
          // Optionally we could just use the higher order component for themes and wrap it around the child component
          // in the render instead of using this.
          const theme = context;
          return (
            <Component
              theme={theme}
              ref={props.ref}
              match={props.match}
              location={props.location}
              history={props.history}
              staticContext={props.staticContext}/>
          );
        }}
      </ThemeContext.Consumer>
    )
  }

  C.displayName = displayName;
  C.WrappedComponent = Component;

  const copy = hoistStatics(C, Component);
  // Copies non-react specific statics from a child component to a parent component
  // React static keywords blacklisted from being overridden.
  return copy;
}

Branding.contextType = UserContext;

export {
  ThemeContext,
  themes
}
export default Branding;
