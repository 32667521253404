// HeaderLogo
//
//  desc:
//    you guessed it, it's a logo that appears in the header!  
//
//
//  required props:
//    theme: the theme to be used for styling/branding the components 
//         example theme: {
//            primary_color:  '#E7E5E4', 
//            seconday_color: '#3399CC',
//            tertiary_color: '#3399CC',
//            footer_color:   '#666666',
//            logo_guid:      null,             //Defualted to null to indicate that we should use a local logo file. If not null, will indicate the guid to be used off of the legacy server


import React, { Component } from 'react';
import './index.css';
import logo from '../../../../resources/images/pt.svg';     //the default PayTrace branded logo


class HeaderLogo extends Component {

  constructor(props)
  {
    super(props);
    this.state =
    {

    };
  } 


  // checks to see if the theme contains a logo guid, and if it does grabs the appropriate image
  //.  if there is no theme logo guid, then uses the default image 
  getLogo()
  { 
    let retLogo = null;
    //note: pt_logo class should ONLY be used for the paytrace logo.  merchant/reseller logos should not use it as it affects sizing for consistency with legacy
    if (this.props.theme.logo_guid != null)
    { //a logo guid exists, so build the branded image 
      if (this.props.theme.logo_guid == '') {
        retLogo = <img className='pt_logo' src={logo} />;
      } else {
        const uri = process.env.LEGACY_URL + "/logo.pay?l=" + this.props.theme.logo_guid;
        retLogo = <img src={uri} />;      //TODO: styling may be dependent upon the image we get... for now it's just default styling. This may need to be revisited
      }
    }

    return retLogo;
  }

  render() {
    let currentLogo = this.getLogo();
    return (
      <div className="HeaderLogo">
        <a href="/">
          {currentLogo}
        </a>
      </div>
    );
  }
}

export default HeaderLogo;
