//  comms
//
//  desc:
//    functions that can be called to handle communications with an api 
//    When adding a new function, function should accept a callback, all data that may be needed
//      Function should use the 'callToEndpoint' commsHelper function for making a server call
//      If the function needs an endpoint path that has not yet been defined in the below list, please add it
//      and make use of it in the function when calling 'callToEndpoint'

//
//

import {callToEndpoint} from './commsHelper.js';        //callToEndpoint performs the actual network communications 


// endpoints for api calls should be defined here for use by calling functions
//    endpoint is a hash that contains the endpoint path and the endpoint type
//              path should be of the type "/index.html" 
//              type should be either "GET" or "POST"
//            example const ENDPOINT_GET_SOME_INFO = {path: '/info/path', type: "GET"}


const ENDPOINT_GET_PAYMENT_LINK_PARAMS = {path: '/v1/merchant/get_payment_link', type: "GET"}
const ENDPOINT_GET_CLOUD_PRINTERS = {path: '/v1/merchant/printer_list', type: "GET"}       
const ENDPOINT_UPDATE_CLOUD_PRINTER = {path: '/v1/merchant/update_printer', type: "POST"}       
const ENDPOINT_CREATE_CLOUD_PRINTER = {path: '/v1/merchant/create_printer', type: "POST"}       
const ENDPOINT_DELETE_CLOUD_PRINTER = {path: '/v1/merchant/delete_printer', type: "POST"}       
const ENDPOINT_REGENERATE_CLOUD_PRINTER_TOKEN = {path: '/v1/merchant/generate_token', type: "GET"}       
const ENDPOINT_GET_BRANDING = {
  path: '/v1/merchant/branding'
  ,type: "GET"
}


export function GetPaymentLink(jsonData)
{
  return new Promise(function(resolve, reject) {
    callToEndpoint(ENDPOINT_GET_PAYMENT_LINK_PARAMS, jsonData, function(data) {
      if (data.status == 200) {
        resolve(data);
      } else {
        reject("network error");
      }
    }, null);
  });
}


export function GetBranding(jsonData)
{
  return new Promise(function(resolve, reject) {
    callToEndpoint(ENDPOINT_GET_BRANDING, jsonData, function(data) {
      // console.log("kalsdjfalksdfjsdlkj", data.status, data.message)
      if (data.status == 200) {
        resolve(data);
      } else {
        reject("network error");
      }
    }, null);
  });
  //.catch(error => console.error('Error in calling endpoint:', error, endpointUrl));

}


export function GetCloudPrinters(jsonData)
{ //TODO: partially stubbed function... ensure functionality is working correctly before PR 
  return new Promise(function(resolve, reject) {
    callToEndpoint(ENDPOINT_GET_CLOUD_PRINTERS, jsonData, function(data) {
      if (data.status == 200) {
        resolve(data);
      } else {
        resolve(data);
        //reject("network error");  //TODO: commenting out for now... need to account for 400 error where there is no list. This may need to be adjusted on api side or handled here since an empty printer list is still valid
      }
    }, null);
  });
  //.catch(error => console.error('Error in calling endpoint:', error, endpointUrl));

}


export function UpdateCloudPrinter(jsonData)
{ //TODO: partially stubbed function... ensure functionality is working correctly before PR 
  return new Promise(function(resolve, reject) {
    callToEndpoint(ENDPOINT_UPDATE_CLOUD_PRINTER, jsonData, function(data) {
      if (data.status == 200) {
        resolve(data);
      } else {
        resolve(data);
        reject("network error");  
      }
    }, null);
  });
}


export function CreateCloudPrinter(jsonData)
{ //TODO: partially stubbed function... ensure functionality is working correctly before PR 
  return new Promise(function(resolve, reject) {
    callToEndpoint(ENDPOINT_CREATE_CLOUD_PRINTER, jsonData, function(data) {
      if (data.status == 200) {
        resolve(data);
      } else {
        resolve(data);
        reject("network error");  
      }
    }, null);
  });
}


export function DeleteCloudPrinter(jsonData)
{ //TODO: partially stubbed function... ensure functionality is working correctly before PR 
  return new Promise(function(resolve, reject) {
    callToEndpoint(ENDPOINT_DELETE_CLOUD_PRINTER, jsonData, function(data) {
      if (data.status == 200) {
        resolve(data);
      } else {
        resolve(data);
        reject("network error");  
      }
    }, null);
  });
}


export function RegenerateCloudPrinterToken(jsonData)
{ //TODO: partially stubbed function... ensure functionality is working correctly before PR 
  return new Promise(function(resolve, reject) {
    callToEndpoint(ENDPOINT_REGENERATE_CLOUD_PRINTER_TOKEN, jsonData, function(data) {
      if (data.status == 200) {
        resolve(data);
      } else {
        resolve(data);
        reject("network error");  
      }
    }, null);
  });
}



