import React, { Component } from 'react';

class Placeholders extends Component {
  constructor(props)
  {
    super(props);
  } 

  componentDidMount() {
  }

  render() {
    const background = this.props.background
    return (
      <div>
        <img src={background} />
      </div>
    );
  }
}

export default Placeholders;