import React, { Component } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { Change, Validation, Rule } from "../Utility";
import './Recaptcha.css';


class Recaptcha extends Component {
  constructor(props)
  {
    super(props);
    // this.state = { width: 0, height: 0 };
    // this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.onChange = this.onChange.bind(this);
    this.asyncScriptOnLoad = this.asyncScriptOnLoad(this);
  }

  componentDidMount() {
    // this.updateWindowDimensions();
    // window.addEventListener('resize', this.updateWindowDimensions);
  }
  
  componentWillUnmount() {
    // window.removeEventListener('resize', this.updateWindowDimensions);
  }
  
  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }
  // Note: the recaptcha events are fired after asynchronous
  // calls and promises. So if there is an error in our code
  // it is not alway obvious what it is.
  onChange(token) {
    if (this.props.onChange) {
      this.props.onChange(token);
    }
  }
  
  asyncScriptOnLoad(evt) {
    // the script that renders the recaptcha was loaded event.
    // seems like we should do something here.
  }
  
  getSiteKey() {
    const siteKey = process.env.GOOGLE_RECAPTCHA_SITEKEY;
    return siteKey || '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';
  }

  render() {
    let size = 'normal';
    if ( window.innerWidth < 540 ) {
      size = 'compact';
    }

    return (
      <div className="Recaptcha">
        <ReCAPTCHA
          size={size}
          style={{ display: "inline-block", width: '200px' }}
          theme="light"
          sitekey={ this.getSiteKey() }
          onChange={ this.onChange }
          asyncScriptOnLoad={ this.asyncScriptOnLoad } />
      </div>
    );
  }
}

export default Recaptcha;
