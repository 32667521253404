// PageTools
//
//    provides preview and save buttons for the FormDesigner page          
//
//
//  props:
//    onClickSave:        callback handler for when the save button is clicked on
//    onClickPreview:     callback handler for when the preview button is clicked on
//


import React, { Component } from 'react';
import Icon from '../../common/react-component-library/Icon';

class PageTools extends Component 
{
  constructor(props)
  {
    super(props);

    this.onClickSave = this.onClickSave.bind(this);
    this.onClickPreview = this.onClickPreview.bind(this);
  }


  //called when the save button is clicked on
  onClickSave()
  {
    if (this.props.onClickSave)
    { //double check that the prop exists before calling it
      this.props.onClickSave();
    }
  }

  //called when the save button is clicked on
  onClickPreview()
  {
    if (this.props.onClickPreview)
    { //double check that the prop exists before calling it
      this.props.onClickPreview();
    }
  }


  render() {
    return (
      <div style={{ textAlign: 'left' }}>
        <button className='btn' onClick={this.onClickPreview}>
          <Icon className="fas fa-pencil-alt"/>Preview
        </button>
        <button className='btn pt-primary' onClick={this.onClickSave}>
          <Icon className="fas fa-check-circle" fgcolor="#FFF" bgcolor="#F98E38"/>Save
        </button>
      </div>
    );
  }
}

export default PageTools;
