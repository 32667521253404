// ACHFields
//
//  desc:
//    group of fields related and required for ACH processing
//
//  props:
//    formdata:                       (required)
//      checkingAccount:              data for input field
//      routingNumber:                data for input field
//      checkingAccount:              data for input field
//      routingNumber:                data for input field
//    onValidate:           (optional) a callback function for when the user has entered input
//    onDataChange:         (optional) a callback function for when the user has entered input
//

import React, { Component } from 'react';
import AchInput from '../../AchInput';
import {Validation, Change} from '../../Utility';

class ACHFields extends Component {
  constructor(props){
    super(props);
    this.state = {
      isCheckingAccountValidVisited: false,
      isCheckingRoutingNumberVisited: false,
    }

    this.onBillingCheckingAccountChange = this.onBillingCheckingAccountChange.bind(this);
    this.onBillingRoutingNumberChange = this.onBillingRoutingNumberChange.bind(this);

    this.validateCheckingAccount = this.validateCheckingAccount.bind(this);
    this.validateRoutingNumber = this.validateRoutingNumber.bind(this);
  }

  onBillingCheckingAccountChange(change) {
    if (this.props.onBillingCheckingAccountChange) {
      this.props.onBillingCheckingAccountChange(change.value());
    }
  }

  onBillingRoutingNumberChange(change) {
    if (this.props.onBillingRoutingNumberChange) {
      this.props.onBillingRoutingNumberChange(change.value());
    }
  }
  
  validateRoutingNumber(validity) {
    this.setState({
      isCheckingRoutingNumberVisited: true
    });
  }
  
  validateCheckingAccount(validity) {
    this.setState({
      isCheckingAccountValidVisited: true,
    });
  }

  render() {
    const checkingAccount = this.props.formdata.checkingAccount;
    const routingNumber = this.props.formdata.routingNumber;
    const autoFocus = this.props.autoFocus;
    return (
      <div>  
        <AchInput
          autoFocus={ autoFocus }
          value={ checkingAccount }
          maxLength="20"
          id="checkingAccount"
          tooltipText="Checking Account Number"
          onChange={ this.onBillingCheckingAccountChange }
          onValidate={ this.validateCheckingAccount }
          isValid={ this.props.isCheckingAccountValid || !this.state.isCheckingAccountValidVisited }
        >
          <div>Checking Account</div>
        </AchInput>
        
        <AchInput
          id="routingNumber"
          value={ routingNumber }
          maxLength="9"
          tooltipText="Routing number of the Checking Account"
          onChange={ this.onBillingRoutingNumberChange }
          onValidate={ this.validateRoutingNumber }
          isValid={ this.props.isCheckingRoutingNumber || !this.state.isCheckingRoutingNumberVisited}
        >
              <div>Routing Number</div>
        </AchInput>
      </div>
    );
  }
}

export default ACHFields;
