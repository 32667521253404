// RadioButton
//
//  desc:
//    radio button
//
//  props:
//    value:          (optional) value for radio box
//    placeholder:    (optional) if provided, will display ghosted placeholder text in the input box. Note: there is no placeholder text by default
//    tooltip:        (optional) tooltip
//    selected:       (optional) is radio box checked 
//    id:             (optional) used to update the model
//    onChange:       (optional) a callback function for when the user has entered input
//

import React, { Component } from 'react';
import './RadioButton.css';

// TODO refactor AmountInputBox and DatePicker to use this component
class RadioButton extends Component {
  constructor(props){
    super(props);
    this.state = {
      value: this.props.value
    }
    this.onChange = this.onChange.bind(this);
  }
  
  onChange(event)
  {
    if (this.props.onChange) {
      this.props.onChange(event);
    }
  }
  
  render() {
    const placeholder = this.props.placeholder
    const value = this.props.value;
    const tooltip = this.props.tooltipText;
    const selected = this.props.selected ? true : false;
    const id = this.props.id;
    const name = this.props.name;
    let styleClassName = "radio-button";
    
    //For radio button as checkbox apperence
    if(this.props.style == "checkbox"){
      styleClassName = styleClassName + " checkbox-Apperence";
    }
    
    return (
      <div className={styleClassName}>
        <label>
          <input 
              id={id}
              name={name}
              type="radio"
              value={value}
              title={tooltip}
              checked={selected}
              onChange={this.onChange}
          /> {this.props.labelText}
        </label>
      </div>
    );
  }
}

export default RadioButton;
