import React, { Component } from 'react';
import TempPlaceholder from '../../Placeholders';
import TotalPaymentBox from '../../TotalPaymentBox';
import './TotalPaymentDemo.css';


class TotalPaymentDemo extends Component {
  constructor(props)
  {
    super(props);
  }

  componentDidMount() {
  }

  render() {
    return (
      <div className="TotalPayment"  style={{textAlign:'left', padding: '20px', marginBottom: '20px', border: '2px dashed green'}}>

        <div className="component">
          <h4>Total Payment Component</h4>
          <div><a href='https://paytrace.atlassian.net/browse/PC2-63'>PC2-63</a></div>
          <hr/>
          <div>
            <TempPlaceholder background="https://paytrace.atlassian.net/secure/thumbnail/41613/Screen+Shot+2019-01-14+at+11.34.58+AM.png?default=false" />
          </div>
          <hr/>
          <div className = "c1">
            <TotalPaymentBox placeholderText= ""
                            name="txtTotalPayment"
                            maxLength="15"
                            textBoxWidth="50%"
                            tooltipText="Total Dollar amount of the transaction" 
                            >
              <div>Total Payment</div>
            </TotalPaymentBox>
          </div>
        </div>

      </div>
    );
  }
}

export default TotalPaymentDemo;