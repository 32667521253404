import React, { Component } from 'react';

import {ThemeContext} from './lib/PayTrace/SiteBranding';
import logo from './logo.svg';
import './App.css';


import PTBase from './SiteTemplates/PTBase/PTBase';

import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import RestrictedRoute from "./components/common/routing";
import UserContextProvider, { UserContext, Authenticator } from './components/Login/UserContext';
import Branding from './lib/PayTrace/SiteBranding';

import QATest from './SiteTemplates/PTBase/Test'
import RCLDemo from './components/common/react-component-library';


class App extends Component {
  constructor(props){
    super(props);
  }
  render() {
    if (process.env.NODE_ENV == 'local') {
      console.log("App Rendering");  
    }
    return (
      <UserContextProvider>
        <div className="App">
          <Router>
            <Switch>
              <Route path='/RCLDemo' component={RCLDemo}/>
              <RestrictedRoute path='/' render={
                () => {
                    return (
                      <Branding>
                      <PTBase />
                      </Branding>
                    )
                  }
              } />
            </Switch>
          </Router>
        </div>
      
        
      </UserContextProvider>
      
    );
  }
}

export default App;
