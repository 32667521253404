import React, { Component } from 'react';
import TempPlaceholder from '../../Placeholders';

// Implement the CheckSelection and add it to the src components folder.
// Then take the real implemented component and render it here on demo scratch pad for QA.
class CheckSelectionDemo extends Component {
  constructor(props)
  {
    super(props);
  }

  componentDidMount() {
  }

  render() {
    return (
      <div className="CheckSelection">

        <div className="component">
          <label>CheckSelection Info</label>
          <div><a href='https://paytrace.atlassian.net/browse/PC2-63'>PC2-63</a></div>
          <div>
            <TempPlaceholder background="https://paytrace.atlassian.net/secure/thumbnail/41612/Screen+Shot+2019-01-14+at+11.30.25+AM.png?default=false" />
          </div>
        </div>

      </div>
    );
  }
}

export default CheckSelectionDemo;