import React, { Component } from 'react';
import HyperLink from '../../Shared/HyperLink';

class FooterNavItems extends Component {
  constructor(props){
    super(props);
    this.state = {
      items:[]
    };
  } 
  createJSXItemComponents(item){

    const liKey = "li_" + item.name;
    const name = item.name ;
    const url = item.url;
    const targetWindow = item.targetWindow ;
    const titleText = item.titleText;

    return (
      <li key={liKey}>
        <HyperLink name={name} url={url} targetWindow={targetWindow} titleText={titleText} color={this.props.theme.footer_color}/>
        <span>&nbsp;|&nbsp;</span>
      </li>
    )
  }
  
  componentDidMount(){
    this.items = this.props.items.map((i) => this.createJSXItemComponents.call(this,i));
    this.setState({items: this.items});
  }
  render() {
    const Items = this.state.items;
    return (Items);
  }
}

export default FooterNavItems;
