// ZipCodeInput
//
//  desc:
//    provides a title and a zip code input box (with optional placeholder text)          
//
//  props:
//    data:                   (required) form data
//      customFrequencyCount:       (optional) bill every # (days, months, years, weeks)
//      durationLimit:              (optional) value to run recurring how many times
//    onDataChange:           (optional) a callback to update data model
//    onValidate:             (optional) call back to do validation
//

import React, { Component } from 'react';
import FormInput from '../../FormInput';
import SectionText from '../../SectionText';
import {DateTimeUtility, Rule, Change, Validation} from '../../Utility';
import {ListItem, DropDownList} from '../../DropDown/list';
import {MissingData} from '../../Exceptions';


// Todo. Needs some work should think of a better way to store
// this value.
export const FrequencyList = [
  'Select Frequency',
  'Annually','Semi-Anually','Trimestery','Quarterly',
  'Bi-Monthly','Monthly','Bi-Weekly','1st and 15th',
  'Weekly','Daily','Custom'
];

export class RecurringPayment extends Component {
  constructor(props){
    super(props);
    this.onDataChange = this.onDataChange.bind(this);
    this.onDurationChange = this.onDurationChange.bind(this);
    this.onFrequencyChange = this.onFrequencyChange.bind(this);
    this.onCustomFrequencyChange = this.onCustomFrequencyChange.bind(this);
  
    this.onFrequencyValidate = this.onFrequencyValidate.bind(this);
    this.onFrequencyCountValidate = this.onFrequencyCountValidate.bind(this);
    this.onDurationLimitValidate = this.onDurationLimitValidate.bind(this);
  }

  data(key) {
    if (this.props.data !== undefined && this.props.data.hasOwnProperty(key)) {
      return this.props.data[key].value;
    } else {
      // console.log(this.constructor.name, key);
      throw new MissingData(this.constructor.name);
    }
  }
  
  valid(key) {
    if (this.props.data !== undefined && this.props.data.hasOwnProperty(key)) {
      return this.props.data[key].valid;
    } else {
      throw new MissingData(this.constructor.name);
    }
  }
  
  checkDurationLimit(val) {
    if (this.data("duration") == 'limited') {
      return val.trim().length > 0;
    }
    return true;
  }
  
  // rule. check that bill every value has a value 
  // if custom is picked.
  checkCustomFrequencyCount(val) {
    if (this.data("frequency") == 11) {
       return val.trim().length > 0;
    }
    return true;
  }
  
  onFrequencyValidate(validation) {
    
  }
  
  onFrequencyCountValidate() {
    const rule = new Rule((v) => { return this.checkCustomFrequencyCount.call(this, v) } );
    
    const validation = new Validation({
      target: {
        id: 'payment_customFrequencyCount',
        name: 'payment[customFrequencyCount[value]]',
        value: this.data('customFrequencyCount'),
        validity: {
          valid: true
        }
      }
    });
    validation.addRule(rule);
    if (this.props.onValidate) {
      this.props.onValidate(validation);
    }
  }

  onDurationLimitValidate() {
    const rule = new Rule((v) => { return this.checkDurationLimit.call(this, v) } );
    
    const validation = new Validation({
      target: {
        id: 'payment_durationLimit',
        name: 'payment[durationLimit[value]]',
        value: this.data('durationLimit'),
        validity: {
          valid: true
        }
      }
    });
    validation.addRule(rule);
    if (this.props.onValidate) {
      this.props.onValidate(validation);
    }
  }

  onDataChange(change) {
    if (this.props.onDataChange) {
      this.props.onDataChange(change);
    }
  }
  
  // dumb. if frequency changes and not custom
  // reset the bill every count to empty if not custom frequency.
  // argument represents the frequency value.
  onFrequencyChange(change) {
    // console.log(change.value());
    if (this.props.onDataChange) {
      this.props.onDataChange(change);
    }
    // not custom frequency so reset custom freqency count
    if (change.value() != 11) {
      this.props.onDataChange(new Change({
        target: {
          id: 'payment_customFrequencyCount',
          name: 'payment[customFrequencyCount[value]]',
          value: '', //reset to empty
        }
      }));
    }
    
    const validation = new Validation({
      target: {
        id: 'payment_frequency',
        name: 'payment[frequency[value]]',
        value: change.value(),
        validity: {
          valid: change.value() != 0,
        }
      }
    })
    // validation.addRule(new Rule((val) => { return false; }));
    if (this.props.onValidate) {
      this.props.onValidate(validation);
    }
  }

  // toggle between the days, week, months, and year radio buttons.
  // years really?
  onCustomFrequencyChange(period) {
    if (this.props.onDataChange) {
      this.props.onDataChange(new Change({
        target: {
          id: 'payment_customFrequency',
          value: period,
          name: 'payment[customFrequency[value]]',
        }
      }))
    }
  }

  // toggle between continual and limited cycles radio buttons
  // reset number of payment value to empty if toggled to continual.
  onDurationChange(duration) {
    if (this.props.onDataChange) {
      this.props.onDataChange(new Change({
        target: {
          id: 'payment_duration',
          value: duration,
          name: 'payment[duration[value]]',
        }
      }))
    }
    if (duration != 'limited') {
      this.props.onDataChange(new Change({
        target: {
          id: 'payment_durationLimit',
          name: 'payment[durationLimit[value]]',
          value: '',
        }
      }));
    }
  }

  showCustomFrequencyFields(frequency, period, validation) {
    // frequency 11 is custom so show custom frequency fields
    // don't like how its hard code 11.
    if (frequency == '11') {
      const isPaymentCustomFrequencyCountValid = validation.valid('PaymentCustomFrequencyCount')
      // frequencyCountValid = this.props.data.customFrequencyCount.valid;
      
      return (
        <div>
          <div style={{width: '50%', marginTop: '25px', paddingRight:'10px'}}>
            <FormInput
                inputType='number'
                isValid={ isPaymentCustomFrequencyCountValid }
                placeholder="ex. 1"
                id="payment_customFrequencyCount" 
                name="payment[customFrequencyCount[value]]"
                onChange={ this.onDataChange }
                value={ this.data('customFrequencyCount') }>
              <div>Bill Every</div>
            </FormInput>
          </div>
          <ul className='horizontal' style={{marginTop: '15px'}}>
            <li>
              <button className='flat' onClick={(evt) => this.onCustomFrequencyChange('day')}
                      style={{height: '50px', width: '87px', marginRight: '5px', paddingRight: '20px'}}>
                <div><input readOnly checked={period == 'day'} name='timeperiod' type='radio' style={{margin: '5px'}}/>Day(s)</div>
              </button>
            </li>
            <li>
              <button className='flat' onClick={(evt) => this.onCustomFrequencyChange('week')}
                      style={{height: '50px', width: '87px', marginRight: '5px', paddingRight: '20px'}}>
                <div><input readOnly checked={period == 'week'} name='timeperiod' type='radio' style={{margin: '5px'}}/>Week(s)</div>
              </button>
            </li>
            <li>
              <button className='flat' onClick={(evt) => this.onCustomFrequencyChange('month')}
                      style={{height: '50px', width: '87px', marginRight: '5px', paddingRight: '20px'}}>
                <div><input readOnly checked={period == 'month'} name='timeperiod' type='radio' style={{margin: '5px'}}/>Month(s)</div>
              </button>
            </li>
            <li>
              <button className='flat' onClick={(evt) => this.onCustomFrequencyChange('year')}
                      style={{height: '50px', width: '87px', paddingRight: '20px'}}>
                <div><input readOnly checked={period == 'year'} name='timeperiod' type='radio' style={{margin: '5px'}}/>Year(s)</div>
              </button>
            </li>
          </ul>
        </div>
      )
    }
    return null;
  }
  
  showNumberOfPaymentFields(duration, validation) {
    
    if (duration == 'limited') {
      const isDurationLimitValid = validation.valid('PaymentDurationLimit');
      // durationLimitValid = this.props.data.durationLimit.valid;

      const numOfPaymentText = "Today's payment will be the first payment of the cycle";
      return (
        <div style={{width: '50%', marginTop: '25px', paddingRight:'10px'}}>
          <SectionText title="# of Payments" content={ numOfPaymentText }/>
          <FormInput
            inputType='number'
            isValid={ isDurationLimitValid }
            placeholder="ex. 12"
            id="payment_durationLimit"
            name="payment[durationLimit[value]]"
            onChange={ this.onDataChange }
            onBlur={ this.onDurationLimitValidate }
            value={ this.data('durationLimit') } />
        </div>
      )
    }
    return null;
  }

  render() {
    const frequency = this.data("frequency");
    const duration = this.data("duration");
    const durationText = 'Continued payments will be billed until until canceled by the customer';
    
    const validation = this.props.validation(this.props.data);
    const isPaymentFrequencyValid = validation.valid('PaymentFrequency');
    return (
      <div className='recurring-payment' style={{marginTop: '35px'}}>

        <div className='frequency' >
          <DropDownList 
                id="payment_frequency"
                name="payment[frequency[value]]"
                isValid={ isPaymentFrequencyValid }
                onValidate={ this.onFrequencyValidate } 
                onDataChange={ this.onFrequencyChange }
                items={FrequencyList} value={frequency} />
        </div>

        <div>
          {this.showCustomFrequencyFields(this.data('frequency'), this.data('customFrequency'), validation)}
        </div>

        <br style={{clear: 'both'}} />

        <div style={{marginTop: '35px'}}>
          <div>
            <SectionText title="Duration" content={ durationText }/>
          </div>

          <div>
            <button className='flat' onClick={(evt) => this.onDurationChange('continual')} style={{height: '50px', width: '150px', marginRight: '10px'}}>
              <div>
                <input readOnly checked={duration == 'continual'} value='continual' name='duration' type='radio' style={{margin: '5px'}}/>
                Continual
              </div>
            </button>
            <button className='flat' onClick={(evt) => this.onDurationChange('limited')} style={{height: '50px', width: '150px', marginLeft: '10px'}}>
              <div>
                <input readOnly checked={duration == 'limited'} value='limited' name='duration' type='radio' style={{margin: '5px'}}/>
                Limited Cycles
              </div>
            </button>
          </div>
        </div>

        <div>
          {this.showNumberOfPaymentFields(this.data('duration'), validation)}
        </div>

      </div>
    );
  }
}
