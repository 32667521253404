// PaymentSummary
//
//  desc:
//    summary step of the payment as a table.
//
//  props:
//    totalAmount
//    payon
//    paymentType
//    paymentInfo
import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import {EditButton} from '../Buttons';


class PaymentSummary extends Component {
  constructor(props){
    super(props);
  }
  
  render() {
    const totalAmount = (Number(this.props.totalAmount) + Number(this.props.convenienceFee)).toFixed(2)
    const payon = this.props.payon;
    const paymentType = this.props.paymentType;
    const paymentInfo = this.props.paymentInfo;
    return (
      <table className='table-responsive-md table-bordered table-hover table-sm'>
        <tbody>
          <tr>
            <td style={{verticalAlign:'middle', textAlign:'left'}}>Total</td>
            <td style={{verticalAlign:'middle', textAlign:'left'}}>{ totalAmount }</td>
            <td style={{verticalAlign:'middle', textAlign:'left'}}>
              <Link replace={false} to={{ pathname: '/Payment', state: {autoFocus: 'payment_amount'} }}>edit</Link>
            </td>
          </tr>

          <tr>
            <td style={{verticalAlign:'middle', textAlign:'left'}}>Payment Info</td>
            <td style={{verticalAlign:'middle', textAlign:'left'}}>{ paymentInfo }</td>
            <td style={{verticalAlign:'middle', textAlign:'left'}}>
              <Link replace={false} to={{ pathname: '/Billing', state: {autoFocus: 'billing_account'} }}>edit</Link>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}

export default PaymentSummary;
