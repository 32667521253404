import React, { Component } from 'react';
import TempPlaceholder from '../../Placeholders';
import TextInputBox from '../../TextInputBox';
import AmountInputBox from '../../AmountInputBox';
import SecurityCodeInput from '../../SecurityCodeInput';
import ExpirationDateInput from '../../ExpirationDateInput';
import './FormTextBoxDemo.css';

// Implement the FormTextBox and add it to the src components folder.
class FormTextBoxDemo extends Component {
  constructor(props)
  {
    super(props);
    this.state = {
      value: ''
    }
    
  }


  render() {
    return (
      <div className="FormTextBoxDemo"  style={{textAlign:'left', padding: '20px', marginBottom: '20px', border: '2px dashed green'}}>

        <div className="component">
          <h4>Form Text Box Component</h4>
          <div><a href='https://paytrace.atlassian.net/browse/PC2-51'>PC2-51</a></div>
          <hr/>
          <div>
            <div><a href='https://paytrace.atlassian.net/browse/PC2-106'>PC2-106</a></div>
            <label>This looks like a combination<br/>of Label text and input textbox<br/> and a floating icon.</label>
            <TempPlaceholder background="https://paytrace.atlassian.net/secure/attachment/41618/Screen%20Shot%202019-01-14%20at%2011.37.11%20AM.png" />
            <div className = "c1">
              <AmountInputBox placeholderText= ""
                              name="txtAmount"
                              maxLength="13"
                              textBoxWidth="50%"
                              tooltipText="Dollar amount of the transaction upto 12 digits including 2 decimal places" 
                              >
                <div>Amount</div>
              </AmountInputBox>
            </div>
          </div>
          <hr/>
          <div>
            <div><a href='https://paytrace.atlassian.net/browse/PC2-105'>PC2-105</a></div>
            <label>This looks like a combination<br/>of Label text and input textbox.</label>
            <TempPlaceholder background="https://paytrace.atlassian.net/secure/thumbnail/41621/Screen+Shot+2019-01-14+at+11.37.39+AM.png?default=false" />
            <div className = "c1">
              <TextInputBox placeholderText= ""  name="txtInvoice" maxLength="50" value={this.state.value} textBoxWidth="50%">
                <div>Invoice - optional</div>
              </TextInputBox>
            </div>
            <div className = "c1">
              <TextInputBox placeholderText= ""  name="txtCity" maxLength="50" value={this.state.value} textBoxWidth="50%">
                <div>City</div>
              </TextInputBox>
            </div>
            <div className = "c1">
              <TextInputBox placeholderText= "Full Name"  name="txtCardFullName" maxLength="50" value={this.state.value} textBoxWidth="100%">
                <div>Name on Card</div>
              </TextInputBox>
            </div>
          </div>
          <hr/>
          <div>
            <label>This looks like a combination<br/>of section text and input textbox.</label>
            <TempPlaceholder background="https://paytrace.atlassian.net/secure/thumbnail/41633/Screen+Shot+2019-01-14+at+11.47.05+AM.png?default=false" />
          </div>
          <hr/>
          <div>
            <div><a href='https://paytrace.atlassian.net/browse/PC2-108'>PC2-108</a></div>
            <label>This looks like a combination<br/>of label text that has icon to the right and input textbox.</label>
            <TempPlaceholder background="https://paytrace.atlassian.net/secure/thumbnail/41657/Screen+Shot+2019-01-14+at+11.44.52+AM.png?default=false" />
            <div className = "c1">
              <SecurityCodeInput 
                              title="Security Code"
                              placeholderText= ""
                              name="txtSecurityCode"
                              minLength="3"
                              maxLength="4"
                              textBoxWidth="50%"
                              tooltipText="3-4 digit code usually found on the back of your card" 
                              />
            </div>
          </div>
          <hr/>
          <div>
            <div><a href='https://paytrace.atlassian.net/browse/PC2-101'>PC2-101</a></div>
            <label>This looks like a combination<br/>of label text that has icon to the right and input textbox.</label>
            <TempPlaceholder background="https://paytrace.atlassian.net/secure/attachment/41729/Screen%20Shot%202019-01-14%20at%2011.41.44%20AM.png?default=false" />
            <div className = "c1">
              <ExpirationDateInput 
                              title="Expiration Date"
                              placeholderText= "11/2020"
                              name="txtExpirationDate"
                              maxLength="7"
                              textBoxWidth="50%"
                              tooltipText="CreditCard expiration date" 
                              />
            </div>
          </div>
        </div>
      </div>

    );
  }
}

export default FormTextBoxDemo;