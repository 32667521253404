import React, { Component } from 'react';
import DOMPurify from 'dompurify';
import SectionText from '../common/react-component-library/SectionText';
import InputSubmitBox from '../common/InputSubmitBox';
import ColorPicker from '../common/react-component-library/ColorPicker';

export class PrimaryColorDesigner extends Component {
  constructor(props){
    super(props)
  }
  render() {
    return (
      <ColorDesigner
        onUpdateColor={this.props.onUpdateColor}
        color={this.props.color}
        enabled={true}>
        <div style={{height: '80px'}}>
          <SectionText title="Primary Color" content="Used for buttons, icons, field selections and your header color if no photo is selected"/>
        </div>
      </ColorDesigner>
    )
  }
}

export class SecondaryColorDesigner extends Component {
  constructor(props){
    super(props);
  }

  render() {
    return (
      <ColorDesigner
        onUpdateColor={this.props.onUpdateColor}
        color={this.props.color}
        enabled={this.props.checked}>
        <div style={{height: '80px'}}>
            <input onChange={this.props.onChecked} checked={this.props.checked} type="checkbox" style={{marginTop:"10%"}}/>&nbsp;Add Secondary Color
        </div>
      </ColorDesigner>
    );
  }
}

class ColorDesigner extends Component {
  constructor(props){
    super(props);
    this.state = {
      visible: false,
    }

    this.toggleColorPicker = this.toggleColorPicker.bind(this);
    this.closeColorPicker = this.closeColorPicker.bind(this);
    this.handleChangeComplete = this.handleChangeComplete.bind(this);
  }
  handleChangeComplete(color) {
    color = DOMPurify.sanitize(color);
    if (!this.props.enabled) return;
    
    if (this.props.onUpdateColor) {
      this.props.onUpdateColor(color);
    }
  }
  toggleColorPicker(evt) {
    if (!this.props.enabled) return;

    const visible = this.state.visible;
    this.setState({
      visible: !visible,
    });
  }
  closeColorPicker(evt) {
    this.setState({
      visible: false,
    });
  }
  render() {
    return (
      <div className="primary-color-picker" style={{ width: '350px', textAlign: 'left' }}>
        {this.props.children}
        <InputSubmitBox
          textValue={ this.props.color }
          handleSubmit={ this.toggleColorPicker }
          handleTextChange={ this.handleChangeComplete }>
          <div style={{ backgroundColor: this.props.color }} className="innerButtonColorSwatchSquareThing"></div>
        </InputSubmitBox>
        <ColorPicker
          visible={ this.state.visible }
          color={ this.props.color }
          onChangeComplete={ this.handleChangeComplete } 
          onCloseColorPicker={ this.closeColorPicker }/>
      </div>
    );
  }
}

export default ColorDesigner;
