// TextInputBox
//
//  desc:
//    an inputb box with callback handlers, styling, placeholder text, etc         
//
//
//  props:
//    value:            text be displayed in the input textbox (not placeholder text!)
//    placeholderText:  placeholder text that is displayed in lieu of actual text being available
//    name:             name of the input text box
//    maxLength:        max amount of characters that can be entered into the input text box   (default value of 128)
//    textBoxWidth:     sets the width of the textbox
//    children:         any child components that should be displayed along with the inputbox
//    onChangeCallback: the callback handler to be called from an onchange event
//


import React, { Component } from 'react';
import './TextInputBox.css';

class TextInputBox extends Component {
  constructor(props)
  {
    super(props);
    this.state = { 
      value: this.props.value 
    }
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) 
  {
    event.preventDefault();
    if (this.props.onChangeCallback)
    {
      this.props.onChangeCallback(event.target.value);
    }
  }
  
  componentDidMount() {
  }

  render() {
  
    const pText = this.props.placeholderText != null ? this.props.placeholderText : "" ;
    const tWidth = this.props.textBoxWidth != null ? this.props.textBoxWidth : "50%" ;
    const maxLength = this.props.maxLength ? this.props.maxLength : 128;                  //default the max length to 128 if no maxlength is available 
    const value = this.props.value ? this.props.value : "";
    return (
      <div className="TextInputBox">
        {this.props.children}
        <input style ={{width:tWidth}}
              type="text"
              value={value}
              placeholder={pText}
              onChange={this.handleChange}
              name={this.props.name}
              maxLength={maxLength}
        />
      </div>
    );
  }
}

export default TextInputBox;
