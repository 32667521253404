// ContentView
//
//  desc:
//    displays the appropriate view
//    
//    TODO: appropriate props will need to be passed in (or some other mechanism determined) for allowing this component
//      to determine which specific view needs to be displayed to the user
//
//  required props:
//      TBD
//

import React, { Component } from 'react';

import HealthPing from '../../common/AppHealth';
import ViewTransactions from '../../Views/ViewTransactions';

class ContentView extends Component {

  constructor(props)
  {
    super(props);
  } 


  getView()
  { //determines the specific view and returns the component necessary for rendering

    //TODO: this function is stubbed functionality at this point
    return (
        <div>
          <HealthPing />
          <ViewTransactions />
        </div>
    );
  }
  

  render() {
    let view = this.getView();
    return (
      <div className='page-content' id='content-view'>
        {view}
      </div>
    );
  }
}

export default ContentView;
