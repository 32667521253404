import React, { Component } from 'react';
import FeedbackAlert from './FeedbackAlert';


class Feedback extends Component {
  constructor(props){
    super(props);
    this.state={ 
  
    };
  }
  render() {
    return (
      <div className="Feedback" >
        <FeedbackAlert theme={this.props.theme} />
      </div>
    );
  }
}

export default Feedback;
