let logLevelTypes = ['error', 'warn', 'info'];
let defaultLogLevel = null;
if (process.env.NODE_ENV == 'local') {
    defaultLogLevel = 'info';
}
/*
The available log levels are: 
  :info, :warn, :error, 
  corresponding to the log level numbers from 0 up to 2, respectively.
  To change the default log level, use the env file. -1 means will not log.
*/
const Logger = (function () {

    const logLevel = process.env.logLevel || defaultLogLevel;

    return {
        log: function() {
            if ( logLevel && logLevelTypes.indexOf(logLevel) <= 2 ) { // error 0, warn 1, info 2
              var args = Array.prototype.slice.call(arguments);
              console.log.apply(console, args);  
            }
        },
        warn: function() {
            if ( logLevel && logLevelTypes.indexOf(logLevel) <= 1 ) { // error 0, warn 1, info 2
              var args = Array.prototype.slice.call(arguments);
              console.warn.apply(console, args);  
            }
        },
        error: function() {
            if ( logLevel && logLevelTypes.indexOf(logLevel) == 0 ) { // error 0, warn 1, info 2
              var args = Array.prototype.slice.call(arguments);
              console.error.apply(console, args);  
            }
        }
    }
}());

export default Logger;