// PrinterItem
//
//  desc:
//           
//
//
//  props:
//    printerInfo:                  hash array made of up of printer values below
//          PrinterID:                id of the printer
//          PrinterName:              given name of the printer
//          NickName:                 user assigned nickname of the printer
//          CloudToken:               unique generated token assigned to this printer (used for printer url generation)
//          newID:                    (optional) if this id is included, it indicates that this is a *new* printer and potentially temporary
//    updatePrinterCallback:        callback handler for updated printer info
//    deletePrinterCallback:        callback handler for deleting this printer info 
//    generatePrinterTokenCallback: callback handler for generating a new cloud printer token
//    startInEditMode:              (optional) indicates that this component should begin in an editable mode. Defaults to false, if not given. 


import React from 'react';
import Select from 'react-select';        //dropdown selection box from https://github.com/JedWatson/react-select and https://react-select.com/home

import './PrinterItem.css'
import {ThemeContext} from '../../../lib/PayTrace/SiteBranding';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {ListItem, DropDownList} from '../../common/react-component-library/DropDown/list';
import Icon from '../../common/react-component-library/Icon';
import DialogOverlay from './DialogOverlay.js'

const CLOUD_PRINTER_QUEUE_ENDPOINT = '/v1/print/receipts';        


  // jira options to be supplied to the dropdown list for the merchant to select from.  Pre-setup for dropdown's needs 
  const PRINTER_BRAND_MODEL_LIST = [       //final supported printer list as provided by CRP-58
    {
      label: "PayTrace Supported Models *",
      options: [
        { value: 'Star - TSP654IICLOUDPRNT', label: 'Star - TSP654IICLOUDPRNT' },
        { value: 'Star - MCP30', label: 'Star - MCP30' },
      ]
    },
    {
      label: "Other CloudPRNT Options *",
      options: [
        { value: 'Star - TSP654CloudPRNT', label: 'Star - TSP654CloudPRNT' },
        { value: 'Star - SP742CLOUDPRNT', label: 'Star - SP742CLOUDPRNT' },
        { value: 'Star - TSP654IIW', label: 'Star - TSP654IIW' },
        { value: 'Star - SP742MW', label: 'Star - SP742MW' },
        { value: 'Star - TSP743IIW', label: 'Star - TSP743IIW' },
        { value: 'Star - TSP743IICLOUDPRNT', label: 'Star - TSP743IICLOUDPRNT' },
        { value: 'Star - TSP847IIW', label: 'Star - TSP847IIW' },
        { value: 'Star - TSP847IICLOUDPRNT', label: 'Star - TSP847IICLOUDPRNT' },
        { value: 'Star - MCP31LB', label: 'Star - MCP31LB' },
        { value: 'Star - MCP20', label: 'Star - MCP20' },
        { value: 'Star - MCP21', label: 'Star - MCP21' },
      ]
    },
  ];



class PrinterItem extends React.Component
{
 constructor(props)
  {
    super(props)

    let editModeState = this.props.startInEditMode ? this.props.startInEditMode : false;

    this.state = {
      isEditMode: editModeState,
    };
 
    this.onEditClick = this.onEditClick.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onSavePrinterInfo = this.onSavePrinterInfo.bind(this);
    this.onCancelEditClick = this.onCancelEditClick.bind(this);
    this.handleEditPrinterChange = this.handleEditPrinterChange.bind(this);
    this.handleEditNickChange = this.handleEditNickChange.bind(this);
    this.onGeneratePrinterTokenClick = this.onGeneratePrinterTokenClick.bind(this);
    this.onCodeCopied = this.onCodeCopied.bind(this);
    this.onHideDialog = this.onHideDialog.bind(this);
    this.onConfirmDelete = this.onConfirmDelete.bind(this);
    this.onConfirmGeneratePrinterToken = this.onConfirmGeneratePrinterToken.bind(this);

  }


  //helper function for creating the full url with params for the cloud printer queue (including token)
  getCloudURL()
  {
    let retUrl = '';
    if (this.props.printerInfo)
    {
      let token = this.props.printerInfo.CloudToken
      retUrl = process.env.API_URL + CLOUD_PRINTER_QUEUE_ENDPOINT + '?printer_token=' + token
    }

    return retUrl;
  }


  onCodeCopied()
  { 
    this.setState({codeCopied: true});
  };


  onEditClick()
  {
    //setup local editing of this printer info .... callback to parent may not be necessary at this point... (only after user has clicked to save edited info)
    
    //setup the default selected value for the printer drowndown list.  Setting up value according to needs of dropdown list
    let selectedPrinter = {value: this.props.printerInfo.PrinterName, label: this.props.printerInfo.PrinterName}

    this.setState({isEditMode: true,
                    selectedPrinter: selectedPrinter
              //    editedPrinterName: this.props.printerInfo.PrinterName,
             //     editedPrinterNickname: this.props.printerInfo.NickName
     });
    this.editedPrinterName = this.props.printerInfo.PrinterName
    this.editedPrinterNickname = this.props.printerInfo.NickName
  }


  onSavePrinterInfo()
  {
    //called when the user clicks to save the edited printer info 
    if (this.props.updatePrinterCallback)
    { //if a callback function has been provided for handling update printer info button click, call it

      //gather the state data to create the updated printer info to be sent to callback
      let newInfo = this.props.printerInfo;
      let selectedPrinter = this.state.selectedPrinter ? this.state.selectedPrinter.value : ""      //TODO: future version should probably check to make sure they have a valid printer selected before allowing saving
      newInfo.PrinterName = selectedPrinter;
      newInfo.NickName = this.editedPrinterNickname;

      this.props.updatePrinterCallback(newInfo);
    }

    //theoretically printer info has been updated so we need to exit edit mode (though re-rendering from updated parent info may also do this for us)
    this.setState({isEditMode: false });
  }


  onCancelEditClick()
  {
    if (this.props.printerInfo.newID)
    { //since this is a new printer...and editing was cancelled... we want to call the parent to remove this temp new printer from the list
        //this is treated like a printer deletion 
        this.onConfirmDelete();
    }
    this.setState({isEditMode: false });
  }


  onDeleteClick()
  {
    //call props callback handler to parent to delete this printer info
    let confirmDialog = <DialogOverlay dialogText='Are you sure you want to delete the selected printer?' onConfirmCallback={this.onConfirmDelete} onCancelCallback={this.onHideDialog}/> 
    this.setState({showConfirmDialog: true, confirmDialog: confirmDialog})
  }


  onConfirmDelete()
  {
    this.onHideDialog();

    if (this.props.deletePrinterCallback)
    { //if a callback function has been provided for handling delete button click, call it
      this.props.deletePrinterCallback(this.props.printerInfo);
    }  
  }


  //stop showing the cancel dialog box... take no further action 
  onHideDialog()
  {
    this.setState({showConfirmDialog: false})    
  }


  onGeneratePrinterTokenClick()
  {
    //call props callback handler to parent to generate new printer token

    let confirmDialog = <DialogOverlay dialogText='Are you sure you want to regenerate printer token url?' onConfirmCallback={this.onConfirmGeneratePrinterToken} onCancelCallback={this.onHideDialog}/> 
    this.setState({showConfirmDialog: true, confirmDialog: confirmDialog})
 
  }


  onConfirmGeneratePrinterToken()
  {
    this.onHideDialog();

    if (this.props.generatePrinterTokenCallback)
    { //if a callback function has been provided for handling generate token button click, call it
      this.props.generatePrinterTokenCallback(this.props.printerInfo);
    }
  }


  handleEditPrinterChange(selectedPrinter) 
  {
    this.setState({selectedPrinter: selectedPrinter });
/*
    event.preventDefault();
    this.setState({editedPrinterName: event.target.value });
    this.editedPrinterName = event.target.value
*/
  }


  handleEditNickChange(event) 
  {
    event.preventDefault();
    this.setState({editedPrinterNickname: event.target.value });
    this.editedPrinterNickname = event.target.value
  }


  //return ths appropriate button for rendering
  getCopyButton()
  {
    let button =
     <ThemeContext.Consumer>
        {theme => (
          <button className="btn cpi_token_button"  style={{'backgroundColor': theme.btn_bg_color, 'color': theme.btn_text_color}} onClick={this.onCopyCodeClicked}>
            <i className="far fa-copy"></i>  Copy Url
          </button>
        )}
      </ThemeContext.Consumer>

    return button;
  }


  //for further info on how to render/style the chosen dropdown component, please refer to these documents:
  // https://github.com/JedWatson/react-select
  // https://react-select.com/
  renderDropdownBrandList()
  {
    let selectedPrinter = this.state.selectedPrinter
    let placeHolderText = "Select Printer..."
    let curSelection = null; 

    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#a8c5df' : null ,      // if previously selected, use background color... else we dont want a background color (currently)
        borderBottom: '1px dotted pink',
        color: 'black',  //state.isSelected ? 'yellow' : 'black',   //previously selected option's text color
        padding: 2,
      }),
      singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return { ...provided, opacity, transition };
      }
    }


    return <Select className="cpi_printer_dropdown"
        styles={customStyles}
        value={selectedPrinter}
        placeholder={placeHolderText}
        onChange={this.handleEditPrinterChange}
        options={PRINTER_BRAND_MODEL_LIST}
      />

  }


  renderActions()
  {
    return  <div className="cpi_actions">
              <div className='cpi_actions_item' onClick={this.onDeleteClick}>
                <Icon className="fas fa-trash-alt"/>
              </div>
              <div className='cpi_actions_item' onClick={this.onEditClick}>
                <Icon className="fas fa-pencil-alt"/>
              </div>
            </div>
  }


  //function will render the printer info in an editable state 
  renderEditInfo(printerInfo)
  {
    const nameID = "cpi_edit_printer_name_" + printerInfo.PrinterID
    const nickID = "cpi_edit_printer_nick_" + printerInfo.PrinterID
    let nick = this.editedPrinterNickname;

    let tokenDiv =  <div className='cpi_info printerCloudToken'>                
                      <div className="printerCloudToken_url">
                        {this.getCloudURL()}
                      </div>                     
                      <div className="printerCloudToken_action">
                        <span>
                          <button className="btn cpi_token_button_regen" onClick={this.onGeneratePrinterTokenClick}>  
                            <span className="tooltiptext">Warning: This action cannot be undone! If you regenerate this token, you must update this information within your CloudPRNT service in Star printer configurations.</span>
                            Regenerate token
                          </button>
                        </span>
                      </div>
                    </div>

    //conditionally render if this is a new printer vs one being updated 
    if (printerInfo.newID)
    {
      tokenDiv =  <div className='cpi_info printerCloudToken'>
                    Cloud Token Url will be assigned when printer is saved
                  </div>
    }

    return  <div className="cpi_specs" >

            <div className='cpi_info printerEditName'>

              <div className='cpi_edit_printer_info'>
                {this.renderDropdownBrandList()}
              </div>

            </div>
            <div className='cpi_info printerEditNick'>
              <div className='cpi_edit_printer_info'>
                <div className='cpi-edit-div cpi-input-div'>
                  <input  className="cpi-input-input"
                          type="text"
                          value={nick}
                          placeholder="Optional Nickname"
                          onChange={this.handleEditNickChange}
                          name="cpi_edit_nickname"
                          maxLength="50"
                  />                
                </div>
              </div>

             </div>

            {tokenDiv}
          </div>
  }


  //function will render the printer actions in an editable state 
  renderEditActions()
  {
    return  <div className="cpi_actions">
              <div className='cpi_actions_item' onClick={this.onCancelEditClick}>
                <Icon className="fas fa-times"/>
              </div>
              <div className='cpi_actions_item' onClick={this.onSavePrinterInfo}>
                <Icon className="fas fa-check"/>
              </div>
            </div>
  }


  renderInfo(printerInfo)
  {
    let button = <div className='cpi_actions_item' onClick={this.onCopyCodeClicked}>
                  <Icon className="far fa-copy"/>
                 </div>


    return  <div className="cpi_specs" >
              <div className='cpi_info printerName'>{printerInfo.PrinterName}</div>
              <div className='cpi_info printerNick'>{printerInfo.NickName}</div>
              
              <div className='cpi_info printerCloudToken'>
                
                <div className="printerCloudToken_url">
                  {this.getCloudURL()}
                </div>
                
                <div className="printerCloudToken_action">
                  <CopyToClipboard
                    onCopy={this.onCodeCopied}
                    options={{message: 'copied!'}}
                    text={this.getCloudURL()}>
                    <span>{button}</span>
                  </CopyToClipboard>
                </div>

              </div>
            </div>
  }



  render()
  {
    let printerInfo = this.props.printerInfo
    let divID = 'cpi_printer_' + printerInfo.PrinterID

    let renderedActions = null;
    let renderedInfo = null;

    let itemClass = "cloud_printer_item"    //default non-edit mode class for the printer item

    //check to see if we are rendering for editing mode or default view
    if (this.state.isEditMode)
    {
      //show editable fields
      renderedActions = this.renderEditActions();
      renderedInfo = this.renderEditInfo(printerInfo);
      itemClass = "cloud_printer_item_edit"     //different class is provided for styling when in edit mode
    }
    else
    {
      //show default fields
      renderedActions = this.renderActions();    
      renderedInfo = this.renderInfo(printerInfo);
    }

    return(
      <div className={itemClass} id={divID}>
        

        {this.state.showConfirmDialog ? this.state.confirmDialog : null}


        <div className="cpi_section cpi_section_left">
          {renderedActions}
        </div>

        <div className="cpi_section cpi_section_right">
          {renderedInfo}
        </div>

      </div>
    );
  }

}

export default PrinterItem;
