// DialogOverlay
//
//  desc:
//    presents a fullscreen dialog box with a confirm and cancel button            
//    
//    TODO: may make sense to move this to the RCL if it ends up being needed/used more  
//
//  props:
//    dialogText:         (required) text presented to the user explaining the confirm/cancel. ie, "Are you sure you want to delete item?"
//    onConfirmCallback:  (required) callback function to be called when user hits confirm
//    onCancelCallback:   (required) callback function to be called when user hits cancel


import React from 'react';
import Icon from '../../common/react-component-library/Icon';


import './DialogOverlay.css'


class DialogOverlay extends React.Component
{
 constructor(props)
  {
    super(props)
    this.state = 
    {

    };

    this.onCancel = this.onCancel.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
  }


  onCancel()
  {
    if (this.props.onCancelCallback)
    {
      this.props.onCancelCallback();
    }

  }


  onConfirm()
  {
    if (this.props.onConfirmCallback)
    {
      this.props.onConfirmCallback();
    }
  }


  render()
  {
    return(
      <div id="do_dialog" className="do_dialog_overlay">
        
        <div id="do_box" className="do_dialog_box">

          <div className="overlay-content">
            <div className='do_content_text'>
              {this.props.dialogText}
            </div>
            <div className='do_center_buttons'>
              <div className='do_action_item do_action_cancel' onClick={this.onCancel}>
                <Icon className="fas fa-times do_action_icon"/>
              </div>
              <div className='do_action_item do_action_confirm' onClick={this.onConfirm}>
                <Icon className="fas fa-check do_action_icon"/>
              </div>
            </div>
          </div>
          
        </div>

      </div>
    );
  }

}

export default DialogOverlay;

