// HeaderGroupRight
//
//  desc:
//  
//
//
//  required props:
//    theme: the theme to be used for styling/branding the components 
//         example theme: {
//            primary_color:  '#E7E5E4', 
//            seconday_color: '#3399CC',
//            tertiary_color: '#3399CC',
//            footer_color:   '#666666',
//            btn_bg_color:   '#5AA2CD',
//            logo_guid:      null,             //Defualted to null to indicate that we should use a local logo file. If not null, will indicate the guid to be used off of the legacy server

import React, { Component } from 'react';
import './index.css';
import SearchMerchantBox from '../../common/SearchMerchantBox';
import ProfileMenu from '../../common/ProfileMenu';
import SignOutButton from '../../common/SignOutButton';

class HeaderGroupRight extends Component {

  constructor(props)
  {
    super(props);
    this.state =
    {

    };
  } 


  render() {
    return (
      <div className='HeaderGroupRight'>
        <div className='HeaderGroupRight_item' >
          <SearchMerchantBox theme={this.props.theme} />
        </div>
        <div className='HeaderGroupRight_item' >
          <ProfileMenu theme={this.props.theme} />
        </div>
        <div className='HeaderGroupRight_item' >
          <SignOutButton theme={this.props.theme} />
        </div>
      </div>
    );
  }
}

export default HeaderGroupRight;
