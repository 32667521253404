import React, { Component } from 'react';
import {ContinueButton} from '../../Buttons';

class ContinueButtonDemo extends Component {
  constructor(props){
    super(props);
  }
  render() {
    return (
      <div style={{textAlign:'left', padding: '20px', marginBottom: '20px', border: '2px dashed green'}}>
        <h4>
        Create continue component
        </h4>
        <div>
          <a href="https://paytrace.atlassian.net/browse/PC2-104">
            https://paytrace.atlassian.net/browse/PC2-104
          </a>
        </div>
        <ContinueButton />
      </div>
    );
  }
}

export default ContinueButtonDemo;
