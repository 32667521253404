// FormCheckbox
//
//  desc:
//    provides a label and a checkbox
//
//  Example usage : 
//    Merchant Settins Page
//
//  props:
//    id :                  (optional) if provided, will be an id for the checkbox
//    title:                (optional) text to display to the user when the mouse cursor hovers over the input box. By default, there is none
//    checked:              (optional) if 'true' checkbox will be selected and vice versa
//    name:                 (optional) if provided, it will be a name for the checkbox
//    onChange:              a callback function for when checkbox is changed due to any user action
//    value:                 (optional) if provided, will set the value for the checkbox
//    labelText:            (optional) if provided, will set the label for the checkbox

import React, { Component } from 'react';
import './FormCheckbox.css';

class FormCheckbox extends Component {
  constructor(props){
    super(props);
    this.state = {
      value: this.props.value
    }
    this.onChange = this.onChange.bind(this);
  }
  
  onChange(event)
  {
    if (this.props.onChange) {
      this.props.onChange(event);
    }
  }
  
  render() {

    const value = this.props.value;
    const tooltip = this.props.tooltipText;
    const name = this.props.name;
    const selected = this.props.checked ? true : false;
    const id = this.props.id;

    return (
      <div className="FormCheckbox">
        {this.props.children}
        <label>
          <input 
              id={id}
              type="checkbox"
              value={value}
              title={tooltip}
              checked={selected}
              name={name}
              onChange={this.onChange}
          /> {this.props.labelText}
        </label>
      </div>
    );
  }
}

export default FormCheckbox;
