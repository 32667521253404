// ContactFields
//
//  desc:
//    groups of fields related to contact info on payment form
//
//  props:
//    formdata:      (required) the form data
//      zipcode:        (optional) data
//      state:          (optional) data
//      phone:          (optional) data
//      country:        (optional) data
//      city:           (optional) data
//      email:          (optional) data
//      address:        (optional) data

//    onDataChange:   (optional) a callback function to update data model
//    onValidate:     (optional) a callback function to validate data model
//

import React, { Component } from 'react';
import Country from '../../Country';
import Address from '../../Address';
import ZipCodeInput from '../../ZipCodeInput';
import FormInput from '../../FormInput';
import SectionText from '../../SectionText';


class ContactFields extends Component {
  constructor(props){
    super(props);
    this.state = {
      validateAddressVisited: false,
      validateZipcodeVisited: false,
      validateCityVisited: false,
      validateStateVisited: false,
      validateEmailVisited: false,
    }

    this.onBillingCountryChange = this.onBillingCountryChange.bind(this);
    this.onBillingAddressChange = this.onBillingAddressChange.bind(this);
    this.onBillingZipcodeChange = this.onBillingZipcodeChange.bind(this);
    this.onBillingCityChange = this.onBillingCityChange.bind(this);
    this.onBillingStateChange = this.onBillingStateChange.bind(this);
    this.onBillingPhoneChange = this.onBillingPhoneChange.bind(this);
    this.onBillingEmailChange = this.onBillingEmailChange.bind(this);
    
    this.onValidateAddress = this.onValidateAddress.bind(this);
    this.onValidateZipcode = this.onValidateZipcode.bind(this);
    this.onValidateCity = this.onValidateCity.bind(this);
    this.onValidateState = this.onValidateState.bind(this);
    this.onValidateEmail = this.onValidateEmail.bind(this);
  }
  
  onBillingCountryChange(value) {
    if (this.props.onBillingCountryChange) {
      this.props.onBillingCountryChange(value);
    }
  }
  
  onBillingAddressChange(change) {
    if (this.props.onBillingAddressChange) {
      this.props.onBillingAddressChange(change.value());
    }
  }

  onBillingZipcodeChange(change) {
    if (this.props.onBillingZipcodeChange) {
      this.props.onBillingZipcodeChange(change.value());
    }
  }

  onBillingCityChange(change) {
    if (this.props.onBillingCityChange) {
      this.props.onBillingCityChange(change.value());
    }
  }
  
  onBillingStateChange(change) {
    if (this.props.onBillingStateChange) {
      this.props.onBillingStateChange(change.value());
    }
  }
  
  onBillingPhoneChange(change) {
    if (this.props.onBillingPhoneChange) {
      this.props.onBillingPhoneChange(change.value());
    }
  }
  
  onBillingEmailChange(change) {
    if (this.props.onBillingEmailChange) {
      this.props.onBillingEmailChange(change.value());
    }
  }
  
  // just for UI purposes to set invalid state on blur
  onValidateAddress(evt) {
    this.setState({
      validateAddressVisited: true
    });
  }
  
  onValidateZipcode(evt) {
    this.setState({
      validateZipcodeVisited: true
    });
  }
  
  onValidateCity(evt) {
    this.setState({
      validateCityVisited: true
    });
  }
  
  onValidateState(evt) {
    this.setState({
      validateStateVisited: true
    });
  }
  
  onValidateEmail(evt) {
    this.setState({
      validateEmailVisited: true
    });
  }
  
  render() {
    const phoneSubText = 'We will only contact you if there are issues with your payment';
    const addressSubText = 'We send a verification email with your receipt after your payment is approved';

    const zipcode = this.props.formdata.zipcode;
    const state = this.props.formdata.state;
    const phone = this.props.formdata.phone;
    const country = this.props.formdata.country;
    const city = this.props.formdata.city;
    const email = this.props.formdata.email;
    const addresses = [];
    // Need to think about if we are going to ever pull over addresses for the customer
    // and if they have 2 addresses and is an array how we will convert the array in the mapping
    // in the paymentlink component. for now just commenting it out because to soon to deal with it.
    if (this.props.formdata.address !== undefined) {
      this.props.formdata.address.map((street) => { 
        addresses.push( {value:street, placeholder: 'Enter Address'} );
      });
    }

    const isBillingContactCityValid = true;//validation.valid('BillingContactCity');
    const isBillingContactStateValid = true;//validation.valid('BillingContactState');
    const isBillingContactPhoneValid = true;//validation.valid('BillingContactPhone');
    const isBillingContactEmailValid = true;//validation.valid('BillingContactEmail');
    return (
      <div className='contact-fields'>
        <Country
          onChange={this.onBillingCountryChange}
          value={ country || 'us' }
        />

        <div>
          <span>Address</span>
          <Address
            onValidate={ this.onValidateAddress }
            isValid={ this.props.validateAddress || !this.state.validateAddressVisited }
            values={ addresses }
            id="address"
            onChange={ this.onBillingAddressChange } />
        </div>

        <ZipCodeInput
          id="zip"
          title="Zip Code"
          isValid={ this.props.validateZipcode || !this.state.validateZipcodeVisited }
          onValidate={ this.onValidateZipcode }
          onTextInputCallback={ this.onBillingZipcodeChange }
          hoverText="Numbers only please and no more than 10"
          zipCode={ zipcode }
        />

        <div className='side-by-side'>
          <div className='control'>
            <FormInput
              id="city"
              onChange={ this.onBillingCityChange }
              value={ city }
              onBlur={ this.onValidateCity }
              isValid={ this.props.validateCity || !this.state.validateCityVisited }>
              <div>City</div>
            </FormInput>
          </div>
          <div className='control'>
            <FormInput
              id="state"
              onChange={ this.onBillingStateChange }
              value={ state }
              onBlur={ this.onValidateState }
              isValid={ this.props.validateState || !this.state.validateStateVisited }>
              <div>State</div>
            </FormInput>
          </div>
        </div>

        <FormInput
          id="phone"
          value={ phone }
          onChange={ this.onBillingPhoneChange }
          inputType='tel'
          isValid={ isBillingContactPhoneValid }>
          <SectionText title="Phone Number" content={phoneSubText} />
        </FormInput>
        {
        // <FormInput
        //   id="email"
        //   value={ email }
        //   onChange={ this.onBillingEmailChange }
        //   inputType='email'
        //   onBlur={ this.onValidateEmail }
        //   isValid={ this.props.validateEmail || !this.state.validateEmailVisited }>
        //   <SectionText title="Email Address" content={addressSubText} />
        // </FormInput>
        }
      </div>
    );
  }
}

export default ContactFields;
