// BDButton
//
//  desc:
//    a button with icon that a user can click on with callback functionality   
//
//
//  required props:
//    title:              title to be displayed on the main button
//    buttonCallback:     a callback for when the main button is selected (not when a list item is selected)
//    theme:              the theme to use for styling this button 
//

import React, { Component } from 'react';
import './index.css';


class BDButton extends Component {
  constructor(props)
  {
    super(props)

    this.state = {

    };

    this.onButtonClick = this.onButtonClick.bind(this);
  }


  //handle the button being clicked, including calling the props callback function
  onButtonClick()
  {
    //call the props callback function
    this.props.buttonCallback();
   }


  render() {
    return (
      <div className='BDButton' onClick={this.onButtonClick} >
        <div className='buttonTitle'>
          <button className="btn BDButton_button"  style={{'backgroundColor': this.props.theme.btn_bg_color}} onClick={this.onButtonClick}>
            <div className="BDButton_inner" style={{'color': this.props.theme.btn_text_color}} >
              {this.props.title}
            </div>
            <i className="BDButton_inner fa fa-caret-down"></i>
          </button>
        </div>
      </div>
    );
  }
}

export default BDButton;
