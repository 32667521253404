// CloudPrinters
//
//  desc:
//         
//
//
//  props:
//

import React from 'react';

import './index.css'

import {UserContext} from '../../Login/UserContext';
import {ThemeContext} from '../../../lib/PayTrace/SiteBranding';
import PrinterTabBar from './PrinterTabBar.js';
import PrinterItem from './PrinterItem.js';
import InstructionsButton from './InstructionsButton.js';
import {GetCloudPrinters, 
        RegenerateCloudPrinterToken, 
        DeleteCloudPrinter, 
        CreateCloudPrinter,
        UpdateCloudPrinter} from '../../../lib/comms';
import Logger from '../../../lib/Logger';

class CloudPrinters extends React.Component
{
 constructor(props)
  {
    super(props)
    this.state = 
    {
      printer_list:  [],
      new_printer_list: [],                       //will hold rows of temp *new*, unsaved printer data 
      printerListNeedsUpdate:   true,             //if true, we need to call out to get the cloud printer list. This happens on mount, and should happen when new printers are added as well
    };
  

    this.updatePrinterCallback = this.updatePrinterCallback.bind(this);
    this.deletePrinterCallback = this.deletePrinterCallback.bind(this);
    this.generatePrinterTokenCallback = this.generatePrinterTokenCallback.bind(this);
    this.onNewPrinterClick = this.onNewPrinterClick.bind(this);
    this.removeTempPrinter = this.removeTempPrinter.bind(this);

  }

  componentDidMount() 
  {

  }  


  removeTempPrinter(printerInfo)
  {
    //remove temp printer from temp new_printer_list array in state
    let pos = -1;
    let newPrinterList = this.state.new_printer_list
    newPrinterList.forEach(function(info, index) {
      if (info.newID == printerInfo.newID)
      {
        pos = index;
      }
    });
    if (pos != -1)
    { //remove the temporary 'new' printer row as it will be replaced with 'real' data from the server on the next list refresh 
      newPrinterList.splice(pos, 1);
    }

    this.setState({new_printer_list: newPrinterList})
  }


  //    updatePrinterCallback:  callback handler for updated printer info
  // This function will be called for both new and existing printers to be saved
  updatePrinterCallback(printerInfo)
  {
    //check for a printer id. If there is no printer id associated with the printer info, then we are creating a new printer
    if (printerInfo.PrinterID)
    {   //updating existing printer
      UpdateCloudPrinter({printer_nickname: printerInfo.NickName, 
                          printer_name: printerInfo.PrinterName, 
                          printer_id: printerInfo.PrinterID})    
      .then(function(resp)
      {
        if (resp)
        {
          Logger.log("API network call to update a printer. resp: ", resp);
          
          if (resp.success)
          {
            this.getCloudPrinterList(this.state.merchant_id);
          } 
        }
      }.bind(this));
    }
    else
    {   //saving data for a new printer!
      CreateCloudPrinter({printer_nickname: printerInfo.NickName, 
                          printer_name: printerInfo.PrinterName
                          })    
      .then(function(resp)
      {
        if (resp)
        {
          Logger.log("API network call to create a new printer. resp: ", resp);
          
          this.removeTempPrinter(printerInfo);

          if (resp.success)
          {
            this.getCloudPrinterList(this.state.merchant_id);
          } 
        }
      }.bind(this));
     
    }
  }


  //    deletePrinterCallback:  callback handler for deleting this printer info 
  deletePrinterCallback(printerInfo)
  {
    if (printerInfo.newID)
    { //temp new printers are removed locally, no call to server necessary 
      this.removeTempPrinter(printerInfo);
    }
    else
    {
      DeleteCloudPrinter({printer_id: printerInfo.PrinterID})    
      .then(function(resp)
      {
        if (resp)
        {
          Logger.log("API network call to delete a printer. resp: ", resp);
          if (resp.success)
          {
            this.getCloudPrinterList(this.state.merchant_id);
          } 
          else
          {
            //TODO: handle possible errors, such as "{success: false, message: "Error - This printer is assigned to the current user."}
          }
        }
      }.bind(this));
    }
  }


  generatePrinterTokenCallback(printerInfo)
  {
    RegenerateCloudPrinterToken({printer_nickname: printerInfo.NickName, 
                        printer_name: printerInfo.PrinterName, 
                        printer_id: printerInfo.PrinterID})    
    .then(function(resp)
    {
      if (resp)
      {
        Logger.log("API network call to regenerate printer token. resp: ", resp);
        if (resp.success)
        {
          this.getCloudPrinterList(this.state.merchant_id);
        } 
      }
    }.bind(this));

  }


  onNewPrinterClick()
  {
    //should create a placeholder printer item. When checkmark is clicked to save placeholder item, should actually make call to save printer! (elswhere)
    let newID = new Date().getUTCMilliseconds();      //create a temporary new id (NOT the printer id) that will be used later on for determining new rows
    let newPrinterInfo = {PrinterID: "", PrinterName: "", NickName: "", CloudToken: "", startInEditMode: true, newID: newID}   
    let newPrinterData = this.state.new_printer_list;
    newPrinterData.push(newPrinterInfo);

    this.setState({new_printer_list: newPrinterData});
  }


  getCloudPrinterList(merchant_id)
  {
    GetCloudPrinters({merchant_id: merchant_id})    
    .then(function(resp)
    {
      if (resp)
      {
        Logger.log("API network call to retrieve printer list. resp: ", resp);

        //sort the printers by id (newest first) in order to keep the list consistent and help the ux when creating new printers 
        let cloudPrinters = resp.cloud_printers ? resp.cloud_printers : [];
        cloudPrinters = cloudPrinters.sort(function(a, b) {
            return b.PrinterID - a.PrinterID;
          });

        const newState = Object.assign({}, this.state, 
        {
            printer_list:        cloudPrinters,
            printerListNeedsUpdate:     false,              //if an update was just performed, we should no longer need an update 
            merchant_id: merchant_id,
        });
        this.setState(newState);
      }
    }.bind(this));

  }


  renderPrinterList()
  {
    let printerData = this.state.printer_list;
    let newPrinterData = this.state.new_printer_list;
    let mergedPrinterData = newPrinterData.concat(printerData);

    let listItems = mergedPrinterData.map((printer) => <PrinterItem key={printer.CloudToken.length > 0 ? printer.CloudToken : printer.newID} 
                                                            printerInfo={printer} 
                                                            deletePrinterCallback={this.deletePrinterCallback} 
                                                            updatePrinterCallback={this.updatePrinterCallback} 
                                                            generatePrinterTokenCallback={this.generatePrinterTokenCallback} 
                                                            startInEditMode={printer.startInEditMode}
                                                            />);
    return <div>
      {listItems}
    </div>
  }


  getTopRowButtons()
  {
    let topRowButtons = 
      <div id='cpi_toprow_buttons'>
        <div className='cpi_toprow_left'>
          {this.getNewPrinterButton()}
        </div>
        <div className='cpi_toprow_right'>
          {this.getInstructionsButton()}
        </div>
      </div>

    return topRowButtons
  }


  getInstructionsButton()
  {
    let button =
     <ThemeContext.Consumer>
        {theme => (
          <InstructionsButton theme={theme}/>
        )}
      </ThemeContext.Consumer>

    return button;    
  }


  //return ths appropriate button for rendering
  getNewPrinterButton()
  {
    let button =
     <ThemeContext.Consumer>
        {theme => (
          <button className="btn cp_button cp_new_button"  style={{'backgroundColor': theme.btn_bg_color, 'color': theme.btn_text_color}} onClick={this.onNewPrinterClick}>
            <i className="far fa-plus-square"></i> New
          </button>
        )}
      </ThemeContext.Consumer>

    return button;
  }


  render()
  {

    return(
      <UserContext.Consumer>
      { 
        ({user, merchantInfo}) => 
        {
          if (merchantInfo)
          { //checking that proper merchant info exists (ie, authenticated properly) before continuing 
            if (this.state.printerListNeedsUpdate)
            {
              this.getCloudPrinterList(merchantInfo.merchant_id);
            }
          }
          return <div className='cloudPrinters'>
  
            <div style={{ color: '#3499cc', fontWeight: 'bold', padding: '10px', height: '40px', backgroundColor: '#f5f5f5', textAlign: 'left' }}>
            </div>
            
            <PrinterTabBar />

            {this.getTopRowButtons()}

            <div className="cpi_list">


              {this.renderPrinterList()}
            </div>
          </div>
        }
      }
      </UserContext.Consumer>
    );
  }

}

export default CloudPrinters;
