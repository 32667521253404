// CompanyInfo
//
//  desc:
//    CompanyInfo section. consists of Logo. website. company name. email.
//
//  props:
//    handleLogoClick:        (optional) handler for logo overlay clicked event. so can upload images
//    handleEmail:            (optional) handler for email clicked event
//    handleViewTerms:        (optional) handler for terms clicked event
//    companyLogoOverlay:            (optional) a small div covering logo to register click events so can upload images when edit mode
//    companyInfo:            (required) consists of the properties logoUrl, name, website, and email
//
import React, { Component } from 'react';
import CompanyLogo from './CompanyLogo';
import './company_info.css';

class CompanyInfo extends Component {
  constructor(props)
  {
    super(props);
    this.handleEmail = this.handleEmail.bind(this);
    this.handleViewTerms = this.handleViewTerms.bind(this);
    this.handleLogoClick = this.handleLogoClick.bind(this);
  }

  handleLogoClick(event) {
    if (this.props.handleLogoClick) {
      this.props.handleLogoClick(event);
    }
    event.preventDefault();
  }
  
  handleEmail(event) {
    this.props.handleEmail(event);
    event.preventDefault();
  }
  
  handleViewTerms(event) 
  {
    if (this.props.handleViewTerms)
    {
      this.props.handleViewTerms(event);
      event.preventDefault();
    }
  }

  render() {
    // TODO no print not implemented right
    return (
      <div className="CompanyInfo no-print">
        <CompanyLogo 
          overlay={this.props.companyLogoOverlay}
          imageUrl={this.props.companyLogo}
          handleClick={this.handleLogoClick} />
        <div className="companyName">
          <div>{this.props.companyName}</div>
        </div>
        <div className="companyWebsite">
          <div>{this.props.companyWebsite}</div>
        </div>
        <div className="companyEmail">
          <div className="clearButton" onClick={this.handleEmail}>
            {this.props.companyEmail}
          </div>
        </div>
        <div className="companyTerms">
          <div className="clearButton" onClick={this.handleViewTerms}>
            View Company Terms
          </div>
        </div>
      </div>
    );
  }
}

export default CompanyInfo;
