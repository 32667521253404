// MissingData
//
//  desc:
//    package for custom exceptions objects
//

export class MissingData extends Error {
  constructor(className) {
    super(className);
    this.message += ". Check if it is missing data property.";
  }
}
