import React, { Component } from 'react';
import TempPlaceholder from '../../Placeholders';
import CustomButton from '../../CustomButton';
import Icon from '../../Icon';

// Notes:
// Probably do not need to add the icon displayed on the screen shot.
const ONE_TIME = 'OneTime';

class BoxDemo extends Component {
  constructor(props)
  {
    super(props);
    this.state = {
      selectedPaymentType: ONE_TIME
    }
  }

  render() {
    return (
      <div className="BoxDemo" style={{textAlign: 'left',  marginBottom: '20px', border: '2px dashed green'}}>
        <h4>Box Component</h4>
        <br/><br/>
        <a href="https://paytrace.atlassian.net/browse/PC2-112">https://paytrace.atlassian.net/browse/PC2-112</a>
        <div>
        <br/>
        File Path:<br/>
        ./src/components/common/react-component-library/CustomButton
        </div>
        <br/>
        Example below:
        <hr/>
        <div style={{padding: '10px', backgroundColor:'white'}}>
          <CustomButton labelText='One-Time' {...this.state.selectedPaymentType == ONE_TIME && {selected:true}}>
            <Icon img='OneTime.svg' height='32px' width='32px' />
          </CustomButton>
          <span>&nbsp;&nbsp;&nbsp;</span>
          <CustomButton labelText='Recurring' {...this.state.selectedPaymentType == ONE_TIME && {selected:true}}>
            <Icon img='Recurring.svg' height='32px' width='32px' />
          </CustomButton>
        </div>
      </div>
    );
  }
}

export default BoxDemo;