// DatePicker
//
//  desc:
//    provides date picker control. Uses third party library to render the control.
//
//  props:
//    value:                (optional) date value of the control
//    onDataChange:         (optional) a callback function when date changes to update model
//    onValidate:           (optional) a callback function validating the input
//    isValid:              (optional) if true, will display the date box input in red 
//    id:                   (required) used to update the appropriate data model
//    name:                 (optional) name of the component
//    autoFocus:            (required) to focus on control when edit link on review page navigates back to page with control
//

import React, { Component } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { DateUtils } from "react-day-picker";

import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from 'react-day-picker/moment';
import {DateTimeUtility, Change, Validation, Rule} from '../Utility';
import Calendar from '../resources/images/calendar.png';
import Icon from '../Icon';

import 'react-day-picker/lib/style.css';
import './date_picker.css';

class DatePicker extends Component {
  constructor(props)
  {
    super(props);
    this.handleDayChange = this.handleDayChange.bind(this);
    this.validateDateFormat = this.validateDateFormat.bind(this);
  }

  handleDayChange(selectedDay, modifiers, dayPickerInput) {
    const input = dayPickerInput.getInput();
    const validity = this.validateDateFormat(input.value) && DateTimeUtility.isDate(input.value);

    const change = new Change({
      target: input
    });
    const validation = new Validation({
      target: input
    });
    validation.addRule(new Rule(this.validateDateFormat));
    validation.addRule(new Rule(DateTimeUtility.isDate));

    if (this.props.onDataChange) {
      this.props.onDataChange(change);
    }
    if (this.props.onValidate) {
      this.props.onValidate(validation);
    }
  }

  // not sure how to restrict the datepicker input
  validateDateFormat(dateString) {
    if (/\d+\/\d+\/\d{4}/.test(dateString)) {
      return true;
    } else { 
      return false;
    }
  };

  render() {
    const id = this.props.id;
    const name = this.props.name;
    const autoFocus = this.props.autoFocus;
    const FORMAT = 'MM/DD/YYYY';
    const errorClass = this.props.isValid ? null : "date-input-error";
    const inputClass = `${errorClass}`;
    // do not enable the datepicker for onetime payment.
    return (
      <div className="DatePicker">
        <div className={inputClass}>
          <DayPickerInput onDayChange={this.handleDayChange}
            formatDate={formatDate} 
            format={FORMAT}
            parseDate={parseDate}
            inputProps={ {id: id, name: name, autoFocus: autoFocus, disabled: true} }
            dayPickerProps={ {className:"DayPickerPanel"} }
            value={ this.props.value }
            placeholder='dd-mm-yyyy'
          />
          <span className="calendar-icon">
            <Icon className="fas fa-calendar-alt" />
          </span>
        </div>
      </div>
    );
  }
}

export default DatePicker;
