// HeaderGroupLeft
//
//  desc:
//  
//
//
//  required props:
//    theme: the theme to be used for styling/branding the components 
//         example theme: {
//            primary_color:  '#E7E5E4', 
//            seconday_color: '#3399CC',
//            tertiary_color: '#3399CC',
//            footer_color:   '#666666',
//            btn_bg_color:   '#5AA2CD',
//            logo_guid:      null,             //Defualted to null to indicate that we should use a local logo file. If not null, will indicate the guid to be used off of the legacy server


import React, { Component } from 'react';
import './index.css';
import HeaderLogo from '../HeaderLogo';


class HeaderGroupLeft extends Component {

  constructor(props)
  {
    super(props);
    this.state =
    {

    };
  } 


  render() {
    return (
      <div className="HeaderGroupLeft">
        <HeaderLogo theme={this.props.theme}/>
      </div>
    );
  }
}

export default HeaderGroupLeft;
