import React, { Component } from 'react';
import TempPlaceholder from '../../Placeholders';
import Address from '../../Address';

// Implement the AddressDemo and add it to the src components folder.
// Then take the real implemented component and render it here on demo scratch pad for QA.
class AddressDemo extends Component {
  constructor(props)
  {
    super(props);
    //this is just for the demo purpose.
    this.addressRef = React.createRef();
    this.test = this.test.bind(this);
  }
  test(){
    const addresses = this.addressRef.current.getAddresses();
    console.log("Addresses:", addresses);
  }


  render() {
    const addresses = [];
    return (
      <div className="component">
        <div className="AddressDemo">
            <h4>Address Component</h4>
            <div>
              <div><a href='https://paytrace.atlassian.net/browse/PC2-70'>PC2-70</a></div>
              <div>like a input text box component but with +Add Address link</div>
                <hr/>
                <div>Screen Shot</div>
                <TempPlaceholder background="https://paytrace.atlassian.net/secure/thumbnail/41663/Xnip2019-01-15_09-55-16.jpg?default=false" />
              <hr/>
              <div>
                <label>Address</label>
                <Address values={ addresses } addressData = {[{value: '1234 Main',placeholder:'Test'}]} ref={this.addressRef}/>
                <button onClick={this.test}>Get Addresses</button>
              </div>
            </div>
        </div>
      </div>
    );
  }
}

export default AddressDemo;
