import React, { Component } from 'react';
import TempPlaceholder from '../../Placeholders';
import SectionText from '../../SectionText';
import './SectionTextDemo.css';

// Notes:
// Probably do not need to add the icon displayed on the screen shot.
class SectionTextDemo extends Component {
  constructor(props)
  {
    super(props);
  }

  render() {
    const content="Omnium Rerum Principia Parva Sunt." +
                  "Non Omnia Possumus Omnes." +
                  "Permitte Divis Cetera";
    return (
      <div className="SectionTextDemo" style={{textAlign: 'left', padding: '20px', marginBottom: '10px', border: '2px dashed green'}}>
        <h4>Create Header Component - Section Text Component</h4>
        <div>
          <a href="https://paytrace.atlassian.net/browse/PC2-102">
          https://paytrace.atlassian.net/browse/PC2-102</a>
        </div>
        <br/>
        consist of a title on top and content section. (also can include chilren components).
        <br/>
        Parent surrounding component can size it. Styling should be in Section component
        <br/><br/>
        Since am using it in comus and aphrodite, decided to add it to the demo page for completeness
        <br/>
        Example below:
        <hr/>
        <div style={{width: '250px'}}>
          <SectionText title="Carpe Diem" content={content}/>
        </div>
      </div>
    );
  }
}

export default SectionTextDemo;