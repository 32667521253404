// SearchButton
//
//  desc:
//    you guessed it, a search button! 
//
//
//  required props:
//    onSearch: a callback function for when this button is clicked 
//    searchActive:   boolean indicating the search state. If true, the search button will be active

import React, { Component } from 'react';
import './index.css'
import {ThemeContext} from '../../../../lib/PayTrace/SiteBranding';


class SearchButton extends Component {

  constructor(props)
  {
    super(props);
    this.state =
    {

    };

    this.onButtonClick = this.onButtonClick.bind(this);

  } 


  //handle the clicking of the button (disbale button?) and call the callback handler
  onButtonClick()
  {
    this.props.onSearch();
  }

  //return ths appropriate button for rendering
  getButton()
  {
    let button = null;
    if (this.props.buttonStatus == true)
    { //return an enabled button
      button =  <ThemeContext.Consumer>
                  {theme => (
                    <button className="btn"  style={{'backgroundColor': theme.btn_bg_color}} onClick={this.onButtonClick}>
                      <i className="fa fa-search"></i>
                    </button>
                  )}
                </ThemeContext.Consumer>
    }
    else
    { //otherwise this button is disabled 
      button =  <ThemeContext.Consumer>
                  {theme => (
                    <button className="btn"  style={{'backgroundColor': theme.btn_bg_color}} disabled onClick={this.onButtonClick}>
                      <i className="fa fa-search"></i>
                    </button>
                  )}
                </ThemeContext.Consumer>

    }

    return button;
  }

  render() {
    let button = this.getButton();
    return (
          <div className="SearchButton">
            {button}
          </div>
    );
  }
}

export default SearchButton;
