// AppHealth
import React, { Component } from 'react';


class HealthPing extends Component {

  constructor()
  {
    super();
    this.state =
    {
      reason: "none received",
      message: "",
    };
  } 

  componentWillMount()
  {   
    // TODO: this component is a proto type
    // either remove it or leave it or whatever but returning
    // nothing for now so i don't try to fetch when working locally
    var health_url = process.env.API_URL + '/health/ping';
    fetch(health_url, {credentials: "include"}).then(results => 
    {
      return results.json();
    }).then(data => 
    {

      this.setState({message: data.message})
      console.log("health ping state", this.state.reason);
    })

  }

  render() {
    return (
      <div className="HealthPing">
        This is the health component monitor for the Leviathan API 
        <div> The server response is: 
          <div>
            {this.state.message}
          </div>
        </div>
      </div>
    );
  }
}

export default HealthPing;
