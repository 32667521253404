import React, { Component } from 'react';
import './PTBase.css';

import PTBody from './PTBody';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import CustomerExportModal from '../../components/CustomerExportModal';
import { withBranding } from '../../lib/PayTrace/SiteBranding';

class PTBase extends Component {
  render() {
    const primaryColor = this.props.theme ? this.props.theme.primary_color : null;
    return (
      <div className="PTBase" style={{backgroundColor: primaryColor}}>
        <Header />
        <PTBody />  
        <Footer />
        <CustomerExportModal />
      </div>
    );
  }
}

// need to wrap PTBase with HOC branding allowing PTBase to use 
// theme property retrieved from API. And needs to be wrapped
// and be a child of userContext because branding needs merchant id.
export default withBranding(PTBase);
