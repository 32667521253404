// BButton
//
//  desc:
//    a button that a user can click on with callback functionality   
//
//
//  required props:
//    title:              title to be displayed on the main button
//    buttonCallback:     a callback for when the main button is selected (not when a list item is selected)
//

import React, { Component } from 'react';
import {ThemeContext} from '../../../../lib/PayTrace/SiteBranding';
import './index.css';


class BButton extends Component {
  constructor(props)
  {
    super(props)

    this.state = {

    };

    this.onButtonClick = this.onButtonClick.bind(this);
  }


  //handle the button being clicked, including calling the props callback function
  onButtonClick()
  {
    //call the props callback function
    this.props.buttonCallback();
   }


  render() {
    return (
      <ThemeContext.Consumer>
        {theme => (
          <button className='btn BButton' style={{'color': theme.btn_text_color, 'backgroundColor': theme.btn_bg_color}} onClick={this.onButtonClick}>
              {this.props.title}
          </button>
        )}
      </ThemeContext.Consumer>
    );
  }
}

export default BButton;
