// PhoneNumberInput
//
//  desc:
//    provides a title and a phone number input box (with optional placeholder text)          
//
//  props:
//    title:                (optional) if provided, will display title above the input box
//    reasonText:           (optional) if provided, will display the reason for needing a phone number below the title and above the input box
//    placeholderText:      (optional) if provided, will display ghosted placeholder text in the input box. Note: there is no placeholder text by default
//    phoneNumber:          (optional) if provided, will set the the phonenumber text for the input box (will be marked out with ***)
//    inError:              if 'true', will display the phone number input in red 
//    hoverText:            (optional) text to display to the user when the mouse cursor hovers over the input box. By default, there is none
//    onTextInputCallback:  a callback function for when the user has entered input
//

import React from 'react';
import './index.css'

class PhoneNumberInput extends React.Component
{
 constructor(props)
  {
    super(props)
    this.state = 
    {

    };
    this.onTextInput = this.onTextInput.bind(this)
  }


  getPlaceholderText()
  { //defaults to empty string if no prop provided
    return this.props.placeholderText != null ? this.props.placeholderText : ""
  }


  getReasonText()
  { //defaults to empty string if no prop provided
    return this.props.reasonText != null ? this.props.reasonText : ""
  }


  getHoverText()
  { //defaults to empty string if no prop provided
    return this.props.hoverText != null ? this.props.hoverText : ""
  }

  getPhoneNumber()
  { //defaults to 25 if no prop provided
    return this.props.phoneNumber != null ? this.props.phoneNumber : ""
  }


  getInputClasses()
  {
    var retClass = 'phoneNumberInputBox';
    if (this.props.inError != null && this.props.inError == true)
    {
      retClass += ' phoneNumberInput_phoneNumberError'
    }
    return retClass;
  }
  
  getTitleAndReasonDiv()
  {
    var reasonText = this.getReasonText();
    return  <div> 
              <div className='phoneNumberInputTitle'>
                {this.props.title}
              </div>
              <div className="phoneNumberInput_reason">{reasonText}</div>
            </div>

  }


  onTextInput(evt)
  {
    let inputText = evt.target.value;
    if (this.props.onTextInputCallback != null)
    {
        this.props.onTextInputCallback(inputText);      
    }
  }


  validatePhoneNumber()
  {
    // regex   ((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}
    //. better regex.    1?(?:[.\s-]?[2-9]\d{2}[.\s-]?|\s?\([2-9]\d{2}\)\s?)(?:[1-9]\d{2}[.\s-]?\d{4}\s?(?:\s?([xX]|[eE][xX]|[eE][xX]\.|[eE][xX][tT]|[eE][xX][tT]\.)\s?\d{3,4})?|[a-zA-Z]{7})
  }


  render() {
    return (
      <div className='phoneNumberInput'>
        {this.getTitleAndReasonDiv()}
        <input className={this.getInputClasses()} 
                onChange={this.onTextInput}
                title={this.getHoverText()} 
                onKeyPress={this.onKeyPress}
                placeholder={this.getPlaceholderText()}
                type='tel' 
                pattern='[\+]\d{2}[\(]\d{2}[\)]\d{4}[\-]\d{4}'
                />
     </div>
     );
  }
}

export default PhoneNumberInput;
