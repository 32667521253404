import React, { Component } from 'react';
import { Route, Redirect } from "react-router-dom";
import { UserContext } from '../../Login/UserContext';
import Login from '../../Login/Login';

const PendingAuthentication = (props) => {
  return (
    <div><br/><br/>Pending</div>
  )
}
// Component to restrict routing to private components.
// Checks isLoggedIn state of the user context to 
// determine if it should redirect to an external login page.
class RestrictedRoute extends Component {
    constructor(props) {
        super(props);
        this.state = {
          isLoggedIn: true
        }
    }
    render() {
      // mimicking react's Route api. Render property taking precedence
      let RenderedComponent = this.props.render;
      if (RenderedComponent === undefined) {
        console.log("Rendered undefined. Trying component");
        RenderedComponent = this.props.component;
      }
      const path = this.props.path;
      const referrer = this.props.path;
      return (
        <UserContext.Consumer>
        { 
          ({isLoggedIn}) => {
            if (isLoggedIn == null) {
              // console.debug("Check if logged on not ready. Return pending component");
              return <Route path={path} component={PendingAuthentication} />
            }
            if (isLoggedIn) {
              // console.debug("Logged on so return component matching route");
              return <Route path={path} render={RenderedComponent} />
            }
            if (!isLoggedIn) {
              console.debug("Not logged route to login page. Referrer is", referrer);
              return <Route path={path} render={() => <Login referrer={referrer}/>} />
            }
          }
        }
        </UserContext.Consumer>
      )
    }
}

export default RestrictedRoute;