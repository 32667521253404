import React, { Component } from 'react';
import Styled, { ThemeConsumer } from 'styled-components';
import { ThemeContext } from '../../../lib/PayTrace/SiteBranding';
import FormInput from '../../common/react-component-library/FormInput';
import FormCheckbox from '../../common/react-component-library/FormCheckbox';
import EmailAddressInput from '../../common/react-component-library/EmailAddressInput';
import Icon from '../../common/react-component-library/Icon';
import AmountInputBox from '../../common/react-component-library/AmountInputBox';
import FileUploaderInput from '../../common/react-component-library/FileUploaderInput';
import {EmailLinkSender} from './SendEmailPaymentLink.js';
import {EmailLinkSenderAttachment} from './SendEmailPaymentLink_Attachment.js';
import './EmailPaymentLink.css';

import {GetPaymentLink} from '../../../lib/comms';
import ParamParseLib from '../../common/react-component-library/lib/ParamParse/paramParse.js';

import {UserContext} from '../../Login/UserContext';


const PRIMARY_COLOR = '#4696CA';
const SECONDARY_COLOR = '#B2B7C3';
const MERCHANT_CUSTOM_TITLE_DEFAULT = "Payment"
const ParameterSurchargeFee = {
  name: "surFee"
};

class EmailPaymentLink extends Component {
  constructor(props){
    super(props);
    const PRIMARY = '#4696CA';
    const SECONDARY = '#B2B7C3';
    
    this.state = {
      emailAmount:"",
      invoiceNumber:"",
      isInvoiceReadOnly:false,
      isAmountReadOnly:false,
      isSurFeeEnabled:false,
      omitSurcharge:false,
      recipientEmails:"",
      bccRecipientEmails:"",
      replyEmails:"",
      validAmount:true,
      errorInRecipientEmails:false,
      errorInBccRecipientEmails:false,
      errorInReplyEmails:false,
      fromName:"",
      errorLabelText:"",
      emailMessage:"",
      sendDisabled:false,
      merchantCustomTitle:MERCHANT_CUSTOM_TITLE_DEFAULT,
      merchantTermsLink:"",
      filenames:["No attachments added"]
      
    }

    this.onRecipientEmailChange = this.onRecipientEmailChange.bind(this);
    this.onBCCRecipientEmailChange = this.onBCCRecipientEmailChange.bind(this);
    this.handleAmountChange = this.handleAmountChange.bind(this);
    this.handleCheckAmountReadOnly = this.handleCheckAmountReadOnly.bind(this);
    this.handleCheckOmitSurcharge = this.handleCheckOmitSurcharge.bind(this);
    this.handleInvoiceNumberChange = this.handleInvoiceNumberChange.bind(this);
    this.handleCheckInvoiceReadOnly = this.handleCheckInvoiceReadOnly.bind(this);
    this.onReplyEmailAddressChange = this.onReplyEmailAddressChange.bind(this);
    this.handleFromNameChange = this.handleFromNameChange.bind(this);
    this.handleMessageChange = this.handleMessageChange.bind(this);
    this.handleAddAttachmentChange = this.handleAddAttachmentChange.bind(this);
    this.handleSendClick = this.handleSendClick.bind(this);
    
    this.onReceipientEmailLostFocus = this.onReceipientEmailLostFocus.bind(this);
    this.onBCCReceipientEmailLostFocus = this.onBCCReceipientEmailLostFocus.bind(this);
    this.onReplyEmailLostFocus = this.onReplyEmailLostFocus.bind(this);

  }

  onRecipientEmailChange(value){
    this.setState({recipientEmails: value});
  }
  
  handleAmountChange(value){
    this.setState({emailAmount: value});
  }
  
  handleCheckAmountReadOnly(evt)
  {   
    this.setState( {isAmountReadOnly: !this.state.isAmountReadOnly} ); 
  }

  handleCheckOmitSurcharge(evt)
  {
    this.setState( {omitSurcharge: !this.state.omitSurcharge} );
  }

  handleInvoiceNumberChange(evt){
    this.setState({invoiceNumber: evt.value()});
  }
  
  handleCheckInvoiceReadOnly(evt)
  {   
    this.setState( {isInvoiceReadOnly: !this.state.isInvoiceReadOnly} ); 
  }  

  onBCCRecipientEmailChange(value){
    this.setState({bccRecipientEmails: value});
  }
  
  onReplyEmailAddressChange(value)
  {
    this.setState({replyEmails: value});
  }
  
  handleFromNameChange(evt){
    this.setState( {fromName: evt.value()} );
  }
  
  handleMessageChange(evt){
    this.setState({emailMessage:evt.target.value });
  }
  
  handleSendClick(evt){
    evt.preventDefault();
    let sendEmail = false;
    let isFileUploaded = false;
    
    //Check if it is good to send email request
    sendEmail = this.inputValidation(evt);
    
    var formData = new FormData();
    let file = document.querySelector("#email_attachment");
    
    //check if any file is selected
    if(file.files.length > 0 ){
      formData.append('FileName', file.files[0]);
      isFileUploaded = true;

      formData.append("amount", this.state.emailAmount)
      formData.append("invoice", this.state.invoiceNumber)
      formData.append("invoiceReadOnly", this.state.isInvoiceReadOnly)
      formData.append("amountReadOnly", this.state.isAmountReadOnly)
      if(this.state.omitSurcharge) {
        formData.append("omitSurcharge", this.state.omitSurcharge)
      }
      formData.append("recipient_emails", this.state.recipientEmails) 
      formData.append("bcc_recipientemails", this.state.bccRecipientEmails)
      formData.append("replay_emails", this.state.replyEmails )
      formData.append("from_name", this.state.fromName )
      formData.append("message", this.state.emailMessage)
      formData.append("merchant_custom_title", this.state.merchantCustomTitle)
      formData.append("merchant_terms_link", this.state.merchantTermsLink)
    }
    
    if(sendEmail) {
      if(isFileUploaded){
        let sendAttachmentEmailResponse = {};
        sendAttachmentEmailResponse = EmailLinkSenderAttachment.send(formData,
          (sendAttachmentEmailResponse) => {
              console.log("Here is the Result:", sendAttachmentEmailResponse);
              if(sendAttachmentEmailResponse.success){
                  this.clearCurrentState();
                  //clear selected fileNames
                  document.querySelector("#email_attachment").value = "";
              }
              this.setState({errorLabelText: sendAttachmentEmailResponse.status_message, sendDisabled: false, invoiceNumber: ""});
              window.scrollTo(0, 0);
        });
      }
      else {
         // Disable the send button 
         // this.setState({sendDisabled: true});
         let params = { 
           "amount": this.state.emailAmount
          , "invoice": this.state.invoiceNumber
          , "invoiceReadOnly": this.state.isInvoiceReadOnly
          , "amountReadOnly": this.state.isAmountReadOnly
          , "recipient_emails": this.state.recipientEmails 
          , "bcc_recipientemails": this.state.bccRecipientEmails
          , "replay_emails": this.state.replyEmails
          , "from_name": this.state.fromName
          , "message": this.state.emailMessage
          , "merchant_custom_title": this.state.merchantCustomTitle
          , "merchant_terms_link": this.state.merchantTermsLink
        };

        if(this.state.omitSurcharge) {
          params["omitSurcharge"] = this.state.omitSurcharge;
        }
        let sendEmailResponse = {};
        sendEmailResponse = EmailLinkSender.send(params,
              (sendEmailResponse) => {
                console.log("Here is the Result:",sendEmailResponse);
                if(sendEmailResponse.success){
                  this.clearCurrentState();
                  //clear selected fileNames
                  document.querySelector("#email_attachment").value = "";
                }
                this.setState({errorLabelText: sendEmailResponse.status_message, sendDisabled: false, invoiceNumber: ""});
                window.scrollTo(0, 0);
          });
      }
  
    } //SendEmail
    
  }
  
  onReceipientEmailLostFocus(value, isError){
    //set the error flag as well
    this.setState({recipientEmails: value , errorInRecipientEmails: isError});
  }
  
  onBCCReceipientEmailLostFocus(value, isError){
    this.setState({bccRecipientEmails: value, errorInBccRecipientEmails: isError});
  }
  
  onReplyEmailLostFocus(value, isError){
    this.setState({replyEmails: value, errorInReplyEmails: isError});
  }
  
  handleAddAttachmentChange(evt){
    let files = evt.target.files;
    let fileNames = [] ;
     
    this.tempFiles = evt.target.files;
    
    //let reader = new FileReader();
    for(var i = 0; i < files.length; i++) { 
       
     fileNames.push(files[i].name ); 
    }
    this.setState({filenames: fileNames });

  }
  
  isInvoiceRequired() {
    let parameterInvoice =
    {
      name: "invoice"  
    }
    let isVisRequired = ParamParseLib.getComponentRequired(parameterInvoice.name); 

    return isVisRequired;
  }

  isValidInvoice() {
    // if invoice is set to read only then merchant needs to supply a invoice because
    // customer will not be able to change it.
    if (this.isInvoiceRequired() && this.state.isInvoiceReadOnly) {
      return this.state.invoiceNumber.trim() != "";
    } else {
      return true;
    }
  }
 
  // checks if the amount is valid.
  // must be greater than 0 if transaction type is a sale.
  isValidEmailAmount() {
    var parameterTransactionType =
    {
      name: "tranxType",
      keys: {type: "type"},
      type: {sale: "sale", auth: "auth", strFwd: "strFwd"},
    }
    let typeKey = parameterTransactionType.keys.type
    let typeName = parameterTransactionType.name;
    let typeValue = ParamParseLib.getComponentKeyValue(typeName, typeKey)
    let dollarAmt = parseFloat(this.state.emailAmount).toFixed(2);
    
    if (!this.state.isAmountReadOnly) {
      return true;
    }
    
    if (typeValue != undefined && typeValue.toUpperCase() == "SALE") {
      return !isNaN(dollarAmt) && dollarAmt > 0;
    } else { // type is not defined or is auth so we do not do anything checks with those. we probably should but not doing that now.
      return true;
    }
  }
  
  // this function checks if inputs are valid and ready to go
  inputValidation(evt){
    
    let tempErrorMsg = "";
    let allGood = false;

    
    if(this.isValidInvoice() 
        && this.isValidEmailAmount()
        && !this.state.errorInRecipientEmails
        && this.state.recipientEmails != ""){
      //moving forward - checking rest of the fiels without error 
      if(!this.state.errorInBccRecipientEmails && !this.state.errorInReplyEmails ){
        //tempErrorMsg = "Optional values are correct."; 
        allGood = true;
      }
      else{
        tempErrorMsg = "Please provide valid values for the optional fields.";
        allGood = false;
      }
      //tempErrorMsg = "All required fields are available. ";
    }
    else {
      tempErrorMsg="Please provide values for the required fields. ";
      if (!this.isValidEmailAmount()) {
        tempErrorMsg+="Sales transaction amount greater than 0 required. ";
      }
      if (!this.isValidInvoice()) {
        tempErrorMsg+="Invoice required."
      }
      allGood = false ;
    }
    this.setState({errorLabelText : tempErrorMsg});
    window.scrollTo(0, 0);
    return allGood ;
  }
  
  clearCurrentState(){
    this.setState({
      emailAmount:0.00,
      recipientEmails:"",
      bccRecipientEmails:"",
      replyEmails:"",
      validAmount:true,
      errorInRecipientEmails:false,
      errorInBccRecipientEmails:false,
      errorInReplyEmails:false,
      fromName:"",
      emailMessage:"",
      filenames:["No attachments added"]
    });
  }
  
  setupMerchantCustomTitle()
  {
    let tempCustomTitle = ParamParseLib.getComponentKeyValue("customTitle", "customTitle");
    if(tempCustomTitle && tempCustomTitle.length > 0)
    {
        this.setState( { merchantCustomTitle: tempCustomTitle } );
    }
    
  }

  setupMerchantTermsLink()
  {
    let tempTermsLink = ParamParseLib.getComponentKeyValue("termsLink", "link");
    if(tempTermsLink && tempTermsLink.length > 0)
    {
        this.setState( { merchantTermsLink: tempTermsLink } );
    }
  }
  
  setupMerchantSurFeeEnabled()
  {
    let surFeeEnabled = ParamParseLib.getComponentVisible(ParameterSurchargeFee.name) === true;
    if (surFeeEnabled) 
    {
      this.setState( { isSurFeeEnabled: surFeeEnabled } );
    }
  }

  getLinkParams()
  {
    if (this.state.isDoneLoading != true)
    {
      GetPaymentLink().then(function(paymentLinkParams)
      {
        var encodedParamString = ""
        try 
        {
          encodedParamString = window.atob(paymentLinkParams.LinkParams); // try to convert the base64 string
        } 
        catch(e) 
        { // invalid base64 so try to just deconstructEncodedParameterString
          encodedParamString = paymentLinkParams;
        }

        ParamParseLib.deconstructEncodedParameterString(encodedParamString);

      }.bind(this)).finally(function()
      {
        this.setState(
        {
          isDoneLoading: true,
        });
        this.setupMerchantCustomTitle();
        this.setupMerchantTermsLink();
        this.setupMerchantSurFeeEnabled();
      }.bind(this));
    }
  }

  renderAmountInput()
  {
    let isValid = this.isValidEmailAmount();
    let amountLabelText = "Provide a payment amount to be processed.";

    let retHtml = <div style={{textAlign: 'left' }}>
                    <div className="errorLabel">{this.state.errorLabelText}</div>

                    <h6>Payment Amount</h6>
                    <div className="labelDesc">{amountLabelText}</div>
                    <div>{isValid || <div className="labelDesc">An amount greater than zero is required for a sale transaction.</div>}</div>
                    <br/>
                    <AmountInputBox 
                        id="email_amount"
                        amount={ this.state.emailAmount }
                        isValid={this.state.validAmount}
                        name="emailAmount"
                        maxLength="13"
                        width="20%"
                        autoFocus={true}
                        tooltipText="Dollar amount of the transaction upto 12 digits including 2 decimal places"
                        onChange={this.handleAmountChange}/>
                    <FormCheckbox
                        value="Y" 
                        name="pref_Amount_Read_Only"
                        tooltipText="Do not allow the amount to be edited by the customer"
                        labelText="Do not allow the amount to be edited by the customer"
                        checked={this.state.isAmountReadOnly}
                        onChange={this.handleCheckAmountReadOnly}/>
                    {this.state.isSurFeeEnabled && (
                      <FormCheckbox
                          value="Y"
                          name="omit_surcharge"
                          tooltipText="Do not add surcharge to this transaction"
                          labelText="Do not add surcharge to this transaction"
                          checked={this.state.omitSurcharge}
                          onChange={this.handleCheckOmitSurcharge}/>
                    )}  
                  </div>

    return retHtml
  }
  

  renderInvoiceInput()
  {
    let invoiceLabelText = "Provide an invoice number to be displayed on the payment page. If this field is left blank, no invoice number will be provided to the recipient.";
    let retHtml = <div style={{textAlign: 'left' }}>
                    <h6>Invoice Number{(this.isInvoiceRequired() && this.state.isInvoiceReadOnly) || " - Optional"}</h6>
                    <div className="labelDesc">{invoiceLabelText}</div>
                    <br/>
                    <FormInput
                        inputType="text"
                        value={this.state.invoiceNumber}
                        id="email_invoiceNumber"
                        name="email_invoiceNumber"
                        maxLength="50"
                        onChange={this.handleInvoiceNumberChange}
                        required={false}/>
                    <FormCheckbox
                        value="Y" 
                        name="pref_Invoice_Read_Only"
                        tooltipText="Do not allow the invoice number to be edited by the customer"
                        labelText="Do not allow the invoice number to be edited by the customer"
                        checked={this.state.isInvoiceReadOnly}
                        onChange={this.handleCheckInvoiceReadOnly}/>
                  </div>
  
    return retHtml
  }


  render() {
    
    // Set the label info     
    let recipientEmailLabelText = "Please provide the email address(es) where the link should be sent. Please note that multiple email addresses may be provided with commas separating them.";

    let bccRecipientEmailLabelText = "Please provide the email address(es) where the link should be sent via a blind carbon copy. ";
    bccRecipientEmailLabelText = bccRecipientEmailLabelText + "This allows you to send out multiple payment links at once, without giving the recipients the ability to see who else is receiving the link. ";
    bccRecipientEmailLabelText = bccRecipientEmailLabelText + "Please note that multiple email addresses may be provided with commas separating them.";
    
    let replyAddressLabelText = "Email address(es) all replies will be sent to. Please note that multiple email addresses may be provided with commas separating them.";
    
    let fromNameLabelText = "This will provide the recipient(s) with a name in the From header of the email they receive. If this field is left blank, the first Reply address you provided will be used.";
    
    let messageLabelText = "Add a message to send to your recipients.";
    
    let attachmentLableText = "Must be a .txt, .doc, .rtf, .pdf, .csv, .zip, .xls or .xlsx file, and less than 1 MB.";
    
    let amountRender = this.renderAmountInput();
    let invoiceRender = this.renderInvoiceInput();

    return (
      <UserContext.Consumer>
      { 
        ({user, merchantInfo}) => {
          if (merchantInfo)
          { //checking that proper merchant info exists (ie, authenticated properly) before continuing 
            this.getLinkParams()
          }


      return (
        <ThemeContext.Consumer>
           {theme => (
            <div className="email-payment-link" style={{ backgroundColor: '#FFF' }}>
              <div style={{ color: theme.tertiary_color, fontWeight: 'bold', padding: '10px', height: '40px', backgroundColor: '#f5f5f5', textAlign: 'left' }}>
                Email details
              </div>
    
              <div className="designer-controls">
                <form encType="multipart/form-data" method="post">
                <div style={{ clear: 'both', paddingBottom: '40px' }} />
            {amountRender}

            <div style={{ clear: 'both', paddingBottom: '40px' }} />
            
            {invoiceRender}

            <div style={{ clear: 'both', paddingBottom: '40px' }} />





            <div style={{textAlign: 'left' }}>
              <h6>Recipient Email(s)</h6>
              <div className="labelDesc">{recipientEmailLabelText}</div>
              <br/>
              <EmailAddressInput 
                value={this.state.recipientEmails}
                onTextInputCallback={this.onRecipientEmailChange}
                hoverText="make sure to have an @ and a ."
                placeholderText="name@example.com"
                allowMultipleEmails="true"
                inError={this.state.errorInRecipientEmails}
                onLostFocusCallback = {this.onReceipientEmailLostFocus}
                required={true}
              />
            </div>
            
            <div style={{ clear: 'both', paddingBottom: '40px' }} />
            
            <div style={{textAlign: 'left' }}>
              <h6>BCC Recipient Email(s) - Optional</h6>
              <div className="labelDesc">{bccRecipientEmailLabelText}</div>
              <br/>
              <EmailAddressInput 
                value={this.state.bccRecipientEmails}
                onTextInputCallback={this.onBCCRecipientEmailChange}
                hoverText="make sure to have an @ and a ."
                placeholderText="name@example.com"
                allowMultipleEmails="true"
                inError={this.state.errorInBccRecipientEmails}
                onLostFocusCallback = {this.onBCCReceipientEmailLostFocus}
                required={false}
              />
            </div>
            
            <div style={{ clear: 'both', paddingBottom: '40px' }} />
            
            <div style={{textAlign: 'left' }}>
              <h6>Reply Address - Optional</h6>
              <div className="labelDesc">{replyAddressLabelText}</div>
              <br/>
              <EmailAddressInput 
                value = {this.state.replyEmails}
                onTextInputCallback={this.onReplyEmailAddressChange}
                hoverText="make sure to have an @ and a ."
                placeholderText="name@example.com"
                allowMultipleEmails="true"
                inError={this.state.errorInReplyEmails}
                onLostFocusCallback = {this.onReplyEmailLostFocus}
                required={false}
              />
            </div>
            
            <div style={{ clear: 'both', paddingBottom: '40px' }} />
            
            <div style={{textAlign: 'left' }}>
              <h6>From Name - Optional</h6>
              <div className="labelDesc">{fromNameLabelText}</div>
              <br/>
              <FormInput
                  inputType="text"
                  value={this.state.fromName}
                  id="email_FromName"
                  name="email_FromName"
                  maxLength="50"
                  onChange={this.handleFromNameChange}
                  required={false}/>
            </div>
            
            <div style={{ clear: 'both', paddingBottom: '40px' }} />
            
            <div style={{textAlign: 'left' }}>
              <h6>Message - Optional</h6>
              <div className="labelDesc">{messageLabelText}</div>
              <br/>
              <textarea 
                className="email-payment-link-text-area" 
                id="email_message" 
                name="email_message" 
                value={this.state.emailMessage}
                rows={5} 
                cols={100}
                maxLength="1500" 
                placeholder="Add your message here..."
                required={false}
                onChange={this.handleMessageChange}/>
            </div>
            
            <div style={{ clear: 'both', paddingBottom: '30px' }} />

            <div style={{textAlign: 'left' }}>
              <h6>Add an Attachment - Optional</h6>
              <div className="labelDesc">{attachmentLableText}</div>
              <br/>
              <FileUploaderInput 
                  id="email_attachment"
                  Name="email_attachment"
                  allowMultipleFile="true"
                  accept=".txt, .doc, .pdf, .rtf, .csv, .zip, .xls, .xlsx"
                  customTitle={"Add Attachment"}
                  onChange={this.handleAddAttachmentChange}>
                  <i className="fa fa-paperclip"></i>
              </FileUploaderInput>
              <label className="labelDesc">{this.state.filenames.join(', ')} </label>
            </div>

            <div style={{ clear: 'both', paddingBottom: '60px' }} />
            
            <div style={{textAlign: 'center' }}>
              <button className='email-send-button btn pt-primary' disabled={this.state.sendDisabled} onClick = {this.handleSendClick}>
                <Icon className="far fa-envelope" fgcolor="#FFF" bgcolor="#F98E38" size="2x"/>Send
              </button>
            </div>
            
            <div style={{ clear: 'both', paddingBottom: '40' }} />
            </form>
    
            </div>
                </div>
              )}
            </ThemeContext.Consumer>
          );
        }}
      </UserContext.Consumer>
    );
  }
}

export default EmailPaymentLink;