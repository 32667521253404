// ProfileMenu
//
//  desc:
//    provides a clickable button that will then present the user with a dropdown menu of profile options       
//
//
//  required props:
//
//  *NOTE: Legacy Dependency*  Currently, will redirect user to legacy system....  This functionality may need to change in a future version

import React, { Component } from 'react';
import './index.css';

import {UserContext} from '../../Login/UserContext';
import ButtonDropdown from '../Branded/ButtonDropdown';

//profile dropdown items
const profile_items =[
  { title: "Edit Profile", value: process.env.LEGACY_URL + "/modifyuserprofile.pay"},
  { title: "Change Validation Image", value: process.env.LEGACY_URL + "/modifyvalidationimage.pay"},
  { title: "Change Password", value: process.env.LEGACY_URL + "/changepassword.pay"},
];

class ProfileMenu extends Component {
  constructor(props)
  {
    super(props)

    this.state = {

    };

    this.onButtonClick = this.onButtonClick.bind(this);
    this.onDropdownSelection = this.onDropdownSelection.bind(this);
  }


  onButtonClick()
  {
    //currently not doing anything with this button click

  }


  onDropdownSelection(title, value)
  {
    //send the user to the link selected in the dropdown menu
    window.location = encodeURI(value);
  }


  //grab the user context to get the necessary userinfo (user.name in this case)
  render() {
    return (
        <UserContext.Consumer>
        { 
          ({user}) => {
            return <ButtonDropdown title={user.name} optionsList={profile_items} selectionCallback={this.onDropdownSelection}/>
          }
        }
        </UserContext.Consumer>
     );
  }
}

export default ProfileMenu;
