import React, { Component } from 'react';
import Feedback from './Feedback' ;
import FooterNav from './FooterNav';
import {ThemeContext} from '../../lib/PayTrace/SiteBranding';

import './index.css';

//FooterNavigation links
const links =[
  { name: "Support", url: process.env.LEGACY_URL + "/support_internal.pay", targetWindow: "_self", titleText: "" },
  { name: "Status", url: "http://status.paytrace.com/", targetWindow:"_self", titleText: "" },
  { name: "Privacy Policy", url: "https://paytrace.net/privacy-policy/", targetWindow: "_blank", titleText: "View the PayTrace privacy policy" },
  { name: "Terms & Condition", url: process.env.LEGACY_URL + "/terms.html", targetWindow: "_blank", titleText: "View the PayTrace terms and conditions" }  
];

class Footer extends Component {
  constructor (props){
    super(props);
    this.state = {
  
    }
  }
  render() {
      return (
        <ThemeContext.Consumer>
          {
            (theme) =>{
              return(
                <div className="Footer" id="footer">
                  <div className="container_fluid">
                    <Feedback theme={theme} />
                    <FooterNav links={links} theme={theme} />
                  </div>
                </div>
              )
            }
          }
          </ThemeContext.Consumer>
      );
  }
}
 
export default Footer;
