// EmailAddressInput
//
//    provides a title and an email address input box (with optional placeholder text)          
//
//
//  props:
//    title:                (optional) if provided, will display title above the input box
//    reasonText:           (optional) if provided, will display the reason for needing a email address below the title and above the input box
//    placeholderText:      (optional) if provided, will display ghosted placeholder text in the input box. Note: there is no placeholder text by default
//    value:                (optional) if provided, will set the the emailAddress text for the input box
//    inError:              if 'true', will display the email address input in red 
//    hoverText:            (optional) text to display to the user when the mouse cursor hovers over the input box. By default, there is none
//    onTextInputCallback:  a callback function for when the user has entered input
//    maxLength:            (optional) will set the max length allowed for the email input (defaults to 87 characters)
//    allowMultipleEmails   (optional) if provided with true value , it will allow multiple emails or viceversa. Default will be false and single email will be allowed.
//    onLostFocusCallback:  a call back function for inputbox OnBlue event
//
// Example:
//      Good example is on Merchant email payment link page - Aphrodite.

import React from 'react';
import './index.css';

class EmailAddressInput extends React.Component
{
 constructor(props)
  {
    super(props)
    this.state = 
    {
      inError: props.inError,
      allowMultipleEmails: this.props.allowMultipleEmails
    };
    this.onTextInput = this.onTextInput.bind(this)
    this.lostFocus = this.lostFocus.bind(this)
  }


  getPlaceholderText()
  { //defaults to empty string if no prop provided
    return this.props.placeholderText != null ? this.props.placeholderText : ""
  }


  getReasonText()
  { //defaults to empty string if no prop provided
    return this.props.reasonText != null ? this.props.reasonText : ""
  }


  getMaxLength()
  {
    return this.props.maxLength != null ? this.props.maxLength : '87'           //defaults to a max length of 87, cuz GRONK
  }

  getHoverText()
  { //defaults to empty string if no prop provided
    return this.props.hoverText != null ? this.props.hoverText : ""
  }

  getInputClasses()
  {
    var retClass = 'emailAddressInputBox';
    // if (this.props.inError != null && this.props.inError == true)
    if (this.state.inError != null && this.state.inError == true)
    {
      retClass += ' emailAddressInput_emailAddressError'
    }
    return retClass;
  }
  
  getTitleAndReasonDiv()
  {
    var reasonText = this.getReasonText();
    return  <div> 
              <div className='emailAddressInputTitle'>
                {this.props.title}
              </div>
              <div className="emailAddressInput_reason">{reasonText}</div>
            </div>

  }


  onTextInput(evt)
  {
    let inputText = evt.target.value;

    if (this.props.onTextInputCallback != null)
    {
        this.props.onTextInputCallback(inputText);
    }
  }


  setInError(inError)
  { 
    this.setState({inError: inError});
  }


  //the element has lost focus and needs to handle that
  lostFocus(evt)
  {  
    let email = evt.target.value ;
    let tempErrorFlag ;
    //Remove any trailing whitespace
    email = email.trim();
    
    //Remove the comma from the end if any 
    email = email.replace(/,\s*$/, "");
    
    let isAllowMultipleEmails = this.state.allowMultipleEmails ;
    
    if(isAllowMultipleEmails){
      email = this.trimEmails(email);
    }
    
    // storing the error value (to be set) into the variable to send it to parent
    // latest state is not available in the same function.
    tempErrorFlag = (!evt.target.validity.valid && !this.validateEmailAddress(email))
    this.setInError(tempErrorFlag);

    // Send the modified string with trimed email address(es) to the parent
    if (this.props.onLostFocusCallback != null)
    {
        //using local variable to send it to the parent as current state of the inError is not avaialble in same function.
        this.props.onLostFocusCallback(email, tempErrorFlag);
    }
    
  }

  trimEmails(emailStr)
  {
    // This function is trimming the whitespaces 
    // between emails in comma seperated string
    let emailList = [];
    let temp = [];
    
    emailList = emailStr.split(",");
    
    emailList.map((k) => {
      if(k != ""){
        temp.push(k.trim());
      }
    });
    
    emailStr = temp.join(",");
    return emailStr;
  }

  //check the given email address against regex to verify it
  validateEmailAddress(email) 
  { 
    let isAllowMultipleEmails = this.state.allowMultipleEmails ;
    let re = "";
    let emailList = [];
    let temp = [];
    
    if(isAllowMultipleEmails){
      re = /^((([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}),?))+$/; 
    } 
    else{
      // for single email verification
      re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    }
    return re.test(email);
  }
  
  render() {

    return (
      <div className='emailAddressInput'>
        {this.getTitleAndReasonDiv()}
        <input className={this.getInputClasses()} 
                onChange={this.onTextInput}
                title={this.getHoverText()} 
                onKeyPress={this.onKeyPress}
                placeholder={this.getPlaceholderText()}
                type='email' 
                maxLength={this.getMaxLength()}
                onBlur={this.lostFocus}
                value={this.props.value}
                required={this.props.required}
                />
     </div>
     );
  }

}

export default EmailAddressInput;
