// Payment
//
//  desc:
//    Payment step / page for the payment link form
//    aphrodite shares this so beware of any changes.
//    would be nice not to share this component.
//
//  props:
//    formdata:             (required) form data
//      payon:                (optional) date to pay on or start payment for processing transaction
//      amount:               (optional) amount to process
//      invoice:              (optional) invoice
//      paymentType:          (optional) ACH or CC
//    onValidate:           (optional) a callback to update the data model
//    onDataChange:         (optional) a callback to validate the data model
//

import React, { Component } from 'react';
import CreditCard, {CreditCardSmartIcon} from '../../../CreditCard';
import SectionText from '../../../SectionText';
import Country from '../../../Country';
import Address from '../../../Address';
import ZipCodeInput from '../../../ZipCodeInput';
import ExpirationDateInput from '../../../ExpirationDateInput';
import SecurityCodeInput from '../../../SecurityCodeInput';
import Icon from '../../../Icon';
import FormInput from '../../../FormInput';
import AmountInputBox from '../../../AmountInputBox';
import {default as RadioButton} from '../../../CustomButton';
import DatePicker from '../../../DatePicker';
import {DateTimeUtility, Rule, Change, Validation} from '../../../Utility';
import {RecurringPayment} from '../../RecurringPaymentFields';
import './payment.css';
import './payment.med.css';

export const PaymentType = {
  ONE_TIME: 'One-Time',
  RECURRING: 'Recurring'
}

const VALID = true;
const INVALID = false;

class Payment extends Component {
  constructor(props){
    super(props);
    this.state = {
      validAmount: VALID,
      validPayOn: VALID,
    }
    this.onPaymentInvoiceChange = this.onPaymentInvoiceChange.bind(this);
    this.onPaymentAmountChange = this.onPaymentAmountChange.bind(this);
    this.validateAmount = this.validateAmount.bind(this);
  }

  validateAmount() {
    this.setState({
      validAmount: this.props.validAmount
    });
  }

  onPaymentAmountChange(change) {
    if (this.props.onPaymentAmountChange) {
      this.props.onPaymentAmountChange(change);
    }
  }
  
  onPaymentInvoiceChange(evt) {
    if (this.props.onPaymentInvoiceChange) {
      this.props.onPaymentInvoiceChange(evt.target.value);
    }
  }

  invoice() {
    return (
      <div className='row'>
        <div>Invoice # - optional</div>
        <input
          id="invoice"
          name="invoice"
          type="text"
          maxLength="50"
          value={ this.props.formdata.invoice }
          onChange={ this.onPaymentInvoiceChange } />
      </div>
    )
  }


  payOnDatePicker()
  {
    if (!this.state.allowPayOnDate) 
    {
      return null;
    }

    return (
      <div style={{width: '50%', marginTop: '25px', paddingRight:'10px'}}>
        <div>Pay On</div>
        <DatePicker
          autoFocus={ this.hasAutoFocus('payment_payon') }
          id="payon"
          value={ payonValue }
          isValid={ validPayOn }
          onDataChange={ this.onDataChange } />
      </div>
    )
  }
  
  hasAutoFocus(fieldId) {
    if (this.props.location === undefined) return false;
    if (this.props.location.state === undefined) return false;

    return this.props.location.state.autoFocus == fieldId;
  }
  
  componentDidMount() {
    if (this.props.location != null && this.props.location.pathname.toLowerCase() == '/payment') {
      // clear autofocus state for navigation here from review page
      if (this.props.history !== undefined) {
        this.props.history.replace({
          pathname: '/Payment',
          state: {}
        });
      }
    }
  }

  render() {
    const payonText = 'Pay On';
    const payonPlaceholder = 'Select Date';

    const payonValue =  this.props.formdata.payon || '';
    const amount = this.props.formdata.amount || '';

    const validAmount = this.state.validAmount;
    const validPayOn = this.state.validPayOn;

    return (
      <div className="payment-fields row">
        <div className="col-md-12">
          <div className="row">
            <AmountInputBox 
                            autoFocus={ this.hasAutoFocus('payment_amount') }
                            id="amount"
                            amount={ amount }
                            isValid={ validAmount }
                            placeholderText= ""
                            maxLength="13"
                            tooltipText="Dollar amount of the transaction upto 12 digits including 2 decimal places" 
                            onChange={this.onPaymentAmountChange}
                            onValidate={this.validateAmount}
                            >
              <div>Amount</div>
            </AmountInputBox>
          </div>

          {this.invoice()}

        </div>

        <div className="col-md-6">
        </div>

        {this.payOnDatePicker()}
        

      </div>
    )
  }
}

export default Payment;
