import React, { Component } from 'react';
import Icon from './Icon'

class HyperLink extends Component {
  constructor(props){
    super(props);
  }

  render() 
  {
    // The hyper link could have multiple children.
    // Example the menu links consist of an icon and text.
    const InnerNodes = this.props.children;
    return (
      <a onClick={this.props.clickHandler} href={this.props.href}>
      {
        InnerNodes.map(function(node){
          return node;
        })
      }
      </a>
    );
  }
}

export default HyperLink;