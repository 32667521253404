import React, { Component } from 'react';
import TempPlaceholder from '../../Placeholders';
import Country from '../../Country';
import './CountryDemo.css';

class CountryDemo extends Component {
  constructor(props)
  {
    super(props);
  }

  render() {
    return (
      <div className="CompanyInfoDemo">

        <div>
          <h4>Country</h4>
          <div><a href='https://paytrace.atlassian.net/browse/PC2-63'>PC2-63</a></div>
          <hr/>
          <div>
            <div>Screen Shot</div>
          </div>
        </div>
        <hr/>

        <div>
          <Country value="us" />
        </div>

      </div>
    );
  }
}

export default CountryDemo;