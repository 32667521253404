

// Promises are async so creating an object that 
// accepts a callback to be called. For example
// can be called in components did mount and accepting
// a callback.

class CreatePaymentLinkResult {
  constructor(success, status_message) {
    this.success = success;
    this.status_message = status_message;
  } 
}

export const CreatePaymentLinkSender = {
  send: function(params, callbackFn){
    
    let uri = process.env.API_URL + "/v1/merchant/set_link_button" ;
    
    console.debug("process.env.NODE_ENV = ", process.env.NODE_ENV);
    console.debug("process.env.API_URL = ", process.env.API_URL);
  
    let result = new CreatePaymentLinkResult(null, null);
    
    if ( process.env.NODE_ENV == 'local' || process.env.NODE_ENV === undefined ) {
      console.debug("Environment set to dev or undefined.");
      result.success = false;
      result.status_message = 'This feature is not available locally.';
      return callbackFn(result);  
    }
    
    //console.debug("Request sent to:", uri);

    let requestSettings = {
      method: "POST",
      body: JSON.stringify(params),
      headers: {
        "Content-Type": "application/json",
        "PayTrace-Product": "paymentlink"
      },
      credentials: "include"
    };
    
    fetch(uri, requestSettings)
      .then(function(response){
        return response.json();
      })
      .then(function(response){
          result.success = response.success;
          result.status_message = response.error;
          
          callbackFn(result);
      })
      .catch(function(error){
        //console.error("Error while saving Create Payment link data", error);
        result.success = false;
        result.status_message = "Error while saving Create Payment link button info. Please try again later." + error ;
        callbackFn(result); 
      });
  }
}

export const CreatePaymentLinkRetriver = {
  getButtonInfo: function(callbackFn){
    
    let uri = process.env.API_URL + "/v1/merchant/get_link_button" ;
    
    console.debug("process.env.NODE_ENV = ", process.env.NODE_ENV);
    console.debug("process.env.API_URL = ", process.env.API_URL);
  
    let result = new CreatePaymentLinkResult(null, null);
    
    if ( process.env.NODE_ENV == 'local' || process.env.NODE_ENV === undefined ) {
      console.debug("Environment set to dev or undefined.");
      result.success = false;
      result.status_message = 'This feature is not available locally.';
      return callbackFn(result);  
    }

    let requestSettings = {
      credentials: "include",
      headers: {
        "PayTrace-Product": "paymentlink"
      }
    };
    
    fetch(uri, requestSettings)
      .then(function(response){
        return response.json();
      })
      .then(function(response){
        //console.log("here is the result:", response);
        callbackFn(response);
      })
      .catch(function(error){
        //console.error("Error while loading Create Payment link data", error);
        result.success = false;
        result.status_message = "Error while loading Paymentlink button info. Please try again later." + error ;
        callbackFn(result);
      });
    
      
  }
}

