// BDDropdown
//
//  desc:
//    a dropdown that gives different menu options for the user to select      
//
//
//  required props:
//    optionsList:        array of selectable items to be displayed in the dropdown {title : value}
//    selectionCallback:  a callback for when an option is selected from the dropdown list  
//    theme:              the theme to use for styling this button 
//

import React, { Component } from 'react';
import './index.css';


class BDDropdown extends Component {
  constructor(props)
  {
    super(props)

    this.state = {

    };

    this.onDropdownSelection = this.onDropdownSelection.bind(this);
  }


  //callback for when a dropdown list item is selected/clicked on 
  onDropdownSelection(evt)
  {
    this.props.selectionCallback(evt.target.innerHTML, evt.target.dataset.value);
  }


  //generate the list item components to be displayed 
  getListItems()
  {
    let listItems = null;
    listItems = this.props.optionsList.map((option) =>
        <li onClick={this.onDropdownSelection}> <div className='BDDropdown-item' data-value={option.value}> {option.title} </div></li>
      );

    return listItems;
  }

  render() {
    const listItems = this.getListItems();
    return (
      <div className='BDDropdown'>
        <ul className=' BDDropdown-menu'>
          {listItems}
        </ul>
      </div>
    );
  }
}

export default BDDropdown;
