

// Promises are async so creating an object that 
// accepts a callback to be called. For example
// can be called in components did mount and accepting
// a callback. Not implemented but we can also 
// set up an abort controller to abort on component unmount event.
// Example this.abortController = new AbortController();
// Not worrying about it now though.

class EmailResult {
  constructor(success, status_message) {
    this.success = success;
    this.status_message = status_message;
  } 
}

export const EmailLinkSender = {
  send: function(params, callbackFn){
    
    let uri = process.env.API_URL + "/v1/merchant/send_email_payment_link" ;
    
    console.debug("process.env.NODE_ENV = ", process.env.NODE_ENV);
    console.debug("process.env.API_URL = ", process.env.API_URL);
  
  
    let result = new EmailResult(null, null);
    
    if ( process.env.NODE_ENV == 'local' || process.env.NODE_ENV === undefined ) {
      console.debug("Environment set to dev or undefined.");
      
      result.success = false;
      result.status_message = 'This feature is not available locally.';
      return callbackFn(result);  
    }
    
    //console.debug("Email Request send to:", uri);

    let requestSettings = {
      method: "POST",
      body: JSON.stringify(params),
      headers: {
        "Content-Type": "application/json",
        "PayTrace-Product": "paymentlink"
      },
      credentials: "include"
    };
    
    fetch(uri, requestSettings)
      .then(function(response){
        return response.json();
      })
      .then(function(response){
        if (response["success"]) 
        {
          result.success = response.success ;
          result.status_message = response.status_message;
          //console.log("Result from the API call:", result);

          callbackFn(result);  
        } 
        
      })
      .catch(function(error){
        console.error("Error while sending email in UI", error);
        result.success = false;
        result.status_message = "Error while sending email in UI. Please try again later." + error ;
        callbackFn(result); 
      });
  }
}

