import React, { Component } from 'react';
import TempPlaceholder from '../../Placeholders';
  import CreditCard, {CreditCardSmartIcon} from '../../CreditCard';

// Implement the CreditCard and add it to the src components folder.
// Then take the real implemented component and render it here on demo scratch pad for QA.
class CreditCardDemo extends Component {
  constructor(props)
  {
    super(props);

    this.state = {
      cardNumber: "41111xxxxxxx1111"
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleValidate = this.handleValidate.bind(this);
  }

  handleChange(event) {
    this.setState({
      cardNumber: event.target.value
    })
    event.preventDefault();
  }
  
  handleValidate(event) {
    // console.log(event.target.value);
    event.preventDefault();
  }

  render() {
    return (
      <div className="CreditCardDemo">

        <div className="component">
          <h4>CreditCard component</h4>
          <div><a href='https://paytrace.atlassian.net/browse/PC2-67'>PC2-67</a></div>
          <div>like a input text box component but for credit cards</div>
          <hr/>
          <div>
            <TempPlaceholder background="https://paytrace.atlassian.net/secure/thumbnail/41652/Screen+Shot+2019-01-14+at+11.44.13+AM.png?default=false" />
          </div>
          <div>
            <label>Test Handling Password Change Event:</label>
            <br/>
            <label>{this.state.cardNumber}</label>
          </div>

          <hr/>
          <div>
            <CreditCard 
              number={this.state.cardNumber}
              onChange={this.handleChange}
              onValidate={this.handleValidate}>
              <CreditCardSmartIcon cardNumber={this.state.cardNumber} />
            </CreditCard>
          </div>
        </div>

      </div>
    );
  }
}

export default CreditCardDemo;