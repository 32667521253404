
// FileUploaderInput 
//
//  desc:
//    provides a customized label with file input 
//
//  Example usage : 
//    Merchant Email payment link page - Add Attachment
//
//  props:

//    id:                   (optional) if provided, will be an id for the file input
//    name:                 (optional) if provided, will be an name for the file input
//    accept:               list of the file types that is accepted 
//    checked:              (optional) if 'true' checkbox will be selected and vice versa
//    name:                 (optional) if provided, it will be a name for file input
//    onClick :              a callback function for when File input is changed due to any user action
//    allowMultipleFile:     (optional) if provided, will allow multiple files to be uploaded for the file input
//    customTitle:           (optional) if provided, will set the label for the file input. Default is : Add Attachment



import React, { Component } from 'react';

class FileUploaderInput extends Component {
  constructor(props)
  {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    if(this.props.onChange){
      this.props.onChange(event);
    }
  }


  render() {

    const allowMultipleFile = this.props.allowMultipleFile ? "multiple" : null ; 
    const customTitle = this.props.customTitle ? this.props.customTitle : "Add Attachment" ;
    const id = this.props.id ? this.props.id : "" ;
    const name = this.props.name ? this.props.name  : "" ;
    const accept = this.props.accept ? this.props.accept : "" ;
    
    return (
      <div className="FileUploaderInput">
        <input 
          type="file" 
          id={id} 
          name={name} 
          accept={accept} 
          multiple={allowMultipleFile} 
          onChange={this.handleChange} />
        <label htmlFor={id}>
          { this.props.children } {customTitle} 
        </label>
      </div>
    );
  }
}

export default FileUploaderInput;
