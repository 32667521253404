// Header
import React, { Component } from 'react';
import './Header.css';

import {ThemeContext} from '../../lib/PayTrace/SiteBranding';
import HeaderGroupLeft from './HeaderGroupLeft';
import HeaderGroupRight from './HeaderGroupRight';

class Header extends Component {
  constructor(props)
  {
    super(props)

    this.state = {

    };

  }



  render() {
    return (
      <ThemeContext.Consumer>
          {theme => (
            <div className="Header container-fluid" id="pt_header" >
              <div className="row h-100">
                <div className="headerGroup col-sm-4">
                  <HeaderGroupLeft theme={theme}/>
                </div>
                <div className="headerGroup col-sm-8">
                  <HeaderGroupRight theme={theme}/>
                </div>
              </div>
            </div>
          )}
      </ThemeContext.Consumer>
    );
  }
}

export default Header;
