// ZipCodeDemo
//
//  desc:
//    placeholder demo component to show off the ZipCodeInput component         
//
//
//  props:
//

import ZipCodeInput from '../../ZipCodeInput';
import './index.css'


import React from 'react';
class ZipCodeDemo extends React.Component
{
 constructor(props)
  {
    super(props)
    this.state = {
      enteredNumber: ""
    };

    this.onZipCode = this.onZipCode.bind(this);
  }


  onZipCode(zipCode)
  {
      this.setState({enteredNumber: zipCode});

  }

  getHelperTextDiv()
  {
    var helperText = 
      <div className="placeholder_demo_help_text"> props:
        <ul>
          <li> title:                (optional) if provided, will display title above the input box </li>
          <li> placeholderText:      (optional) if provided, will display ghosted placeholder text in the input box. Note: there is no placeholder text by default </li>
          <li> zipCode:              (optional) if provided, will set the the zipcode text for the input box (will be marked out with ***) </li>
          <li> inError:              if 'true', will display the zip code input in red </li>
          <li> hoverText:            (optional) text to display to the user when the mouse cursor hovers over the input box. By default, there is none </li>
          <li> onTextInputCallback:  a callback function for when the user has entered input </li>
        </ul>
      </div>
      return helperText;
  }

  render() {
    return (
      <div className="ZipCodeDemo">

        <div className="component" id='zipCodeDemoDiv'>
          <h4>ZipCodeInput component</h4>
          <div><a href='https://paytrace.atlassian.net/browse/PC2-109'>PC2-109</a></div>
          <div>like a input text box component but for zip codes</div>
          {this.getHelperTextDiv()}
          <hr/>
          <div>
            <ZipCodeInput 
              title="Zip Code" 
              zipCode={this.state.enteredNumber}
              reasonText="Cuz this is a demo" 
              onTextInputCallback={this.onZipCode}
              hoverText="Numbers only please and no more than 10"
              placeholderText="enter numbers only (unless you live outside US)"
            />
          </div>
        </div>
          Entered Zip Code: {this.state.enteredNumber}
      </div>
    );
  }
}

export default ZipCodeDemo;
