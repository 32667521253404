// FormInput
//
//  desc:
//    generic input field
//
//  props:
//    value:                (optional) value of the input box
//    tooltipText:          (optional) tooltip of the input box
//    id:                   (required) id of the input box used to update the model
//    name:                 (optional) name of the control
//    maxLength:            (optional) maximum length of the value
//    required:             (optional) makes input box required. just uses html5 validation
//    placeholder:          (optional) placeholder of input box
//    icon:                 (optional) optional icon to place in inputbox
//    isValid:              (optional) if true, will highlight the input box in red
//    inputType:            (optional) can set the type of the input tag
//    width:                (optional) width of the input tag
//    pattern:              (optional) html5 pattern validation
//    {children}:           (optional) optional UI components can be added like labels for the input box.
//    onChange:             (optional) a callback function for when the user has entered input
//    onBlur:               (optional) a callback function for validating user input
//

import React, { Component } from 'react';
import { Validation, Change } from '../Utility';
import './FormInput.css';

// TODO refactor AmountInputBox and DatePicker to use this component
class FormInput extends Component {
  constructor(props){
    super(props);
    this.state = {
      value: this.props.value
    }
    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }
  
  onChange(event)
  {
    if (this.props.onChange) {
      this.props.onChange(new Change(event));
    }
  }
  
  onBlur(event)
  {
    if (this.props.onBlur) {
      this.props.onBlur(new Validation(event));
    }
  }
  createIconInside() {
    if (this.props.icon) {
      return (
          <i className='input-left-icon'>{ this.props.icon } </i>
      )
    }
  }
  render() {
    const placeholder = this.props.placeholder
    const style = {};
    if (this.props.width) {
      style.width = this.props.width;
    }
    if (this.props.isValid !== undefined && !this.props.isValid) {
      style.border = "2px solid red";
    }
    
    const value = this.props.value || '';
    const tooltip = this.props.tooltipText;
    const name = this.props.name;
    const maxLength = this.props.maxLength;
    const id = this.props.id;
    const required = this.props.required;
    const inputType = this.props.inputType || 'text';
    return (
      <div className="form-input">
        { this.props.children }
        { this.createIconInside() }
        <input 
            id={ id }
            type={ inputType }
            style={ style }
            value={ value }
            placeholder={ placeholder }
            name={ name }
            maxLength={ maxLength }
            title={ tooltip }
            pattern={ this.props.pattern }
            onChange={ this.onChange }
            onBlur={ this.onBlur }
            required={ required }
        />
      </div>
    );
  }
}

export default FormInput;
