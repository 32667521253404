// MerchantInputBox
//
//  desc:
//    input box for merchant search. On change will call parent component callback     
//
//
//  required props:
//    placeholderText: placeholder text to be used on input box. Will default to "Find Merchants..." if none is provided
//    onTextInputCallback:  a callback function for when the user has entered input
//    onSubmitCallback:     callback function for when the user has hit the enter key  

import React, { Component } from 'react';
import './index.css'

class MerchantInputBox extends Component {

  constructor(props)
  {
    super(props);
    this.state =
    {

    };

    this.onTextInput = this.onTextInput.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
} 

  getPlaceholderText()
  {
    let placeholderText = "Find Merchants...";
    if (this.props.placeholderText != null)
    {
      placeholderText = this.props.placeholderText;
    }

    return placeholderText;
  }


  onTextInput(evt)
  {
    let inputText = evt.target.value;
    this.props.onTextInputCallback(inputText);
  }


  //binding for specific enter key press
  onKeyPress(evt)
  {
    if (evt.key === 'Enter') 
    { //enter key has been pressed, so notify the parent
      this.props.onSubmitCallback();
    }


  }

  render() {
    return (
      <div className="MerchantInputBox">
        <input className='merchantInput' onChange={this.onTextInput} onKeyPress={this.onKeyPress} placeholder={this.getPlaceholderText()} type="text"/>
      </div>
    );
  }
}

export default MerchantInputBox;
