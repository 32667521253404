import React, { Component } from 'react';
import './PTBody.css';

import PTCanvas from '../../components/Canvas/PTCanvas';

class PTBody extends Component {
  render() {
    return (
      <div className="PTBody">
        <PTCanvas />  
      </div>
    );
  }
}

export default PTBody;
