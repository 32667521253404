import React, { Component } from 'react';
import Styled from 'styled-components';

import './index.css';

import {UserContext} from '../../Login/UserContext';
import {PrimaryColorDesigner, SecondaryColorDesigner} from '../../ColorDesigner';
import PaymentForm from '../../common/react-component-library/PaymentForm';
import ImageUploader from '../../common/react-component-library/ImageUploader';
import PageTools from './PageTools';
import Steps from '../../common/react-component-library/PaymentForm/Steps';
import Billing from '../../common/react-component-library/PaymentForm/Steps/Billing';
import Payment from '../../common/react-component-library/PaymentForm/Steps/Payment';
import Receipt from '../../common/react-component-library/PaymentForm/Steps/Receipt';
import Review from '../../common/react-component-library/PaymentForm/Steps/Review';
import HeaderLabel from '../../common/react-component-library/HeaderLabel';
import Icon from '../../common/react-component-library/Icon';
import NavigationButtons from '../../common/react-component-library/PaymentForm/NavigationButtons';
import PaymentSteps from '../../common/react-component-library/PaymentSteps';
import SampleForm from './SampleForm';
import ParamParseLib from '../../common/react-component-library/lib/ParamParse/paramParse.js';
import {GetPaymentLink} from '../../../lib/comms';

import TotalPaymentBox from '../../common/react-component-library/TotalPaymentBox';

import './FormDesigner.css';
import { ThemeContext, themes } from '../../../lib/PayTrace/SiteBranding';

const PRIMARY_COLOR = '#4696CA';
const SECONDARY_COLOR = '#B2B7C3';

const LogoToolTip = Styled.div`
  visibility: hidden;
  z-index: 2;
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
`;

const LogoOverlay = Styled.div`
  background-color: red;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  color: gray;
  padding-top: 40%;
  pointer-events: all;
  background: rgba(100,100,100, 0.0);
  /* border: 5px outset pink; */
  &:hover {
    background: rgba(100,100,100, 0.5);
    color: #FFFFFF;
    font-weight: bold;
  }
  &:hover ${LogoToolTip} {
    visibility: visible;
  }
`;

class FormDesigner extends Component {
  constructor(props){
    super(props);
    const PRIMARY = '#4696CA';
    const SECONDARY = '#B2B7C3';

    this.state = {
      logo: null,
      currentPageIndex: 0,
      secondaryColorChecked: true,
      primaryColor: PRIMARY,                    //provide defaults until user has made choise or the parmParseLib is loaded
      secondaryColor: SECONDARY,                //provide defaults until user has made choise or the parmParseLib is loaded
      companyInfo: {
        logoUrl: this.initialLogo(),
        name: "Company Name",
        website: "www.website.com",
        email: "user@email.com",
      },
      errorLabelText:"",
      errorLableClassName:""
  }

    this.uploader = React.createRef();

    this.handleResetSecondary = this.handleResetSecondary.bind(this);
    this.openUploader = this.openUploader.bind(this);
    this.handleFileLoad = this.handleFileLoad.bind(this);
    this.updatePrimaryColor = this.updatePrimaryColor.bind(this);
    this.updateSecondaryColor = this.updateSecondaryColor.bind(this);
    this.saveSettings = this.saveSettings.bind(this);
    this.saveLogo = this.saveLogo.bind(this);
    this.saveParameters = this.saveParameters.bind(this);

    this.showPreview = this.showPreview.bind(this);
    this.getColors = this.getColors.bind(this);
  }
  
  fakeFormdata() {
    let formData =  {
      // set defaults. When browser refreshes will use these values
        amount: '9.99',
        paymentMethod: 'CC',
        payon: '10/2/2019',
        invoice: '',
        specialInstruction:'',
        tranxType: 'sale', 
        processingMethod: 'OneTime',      
    }

    return formData
  }


  getColors()
  {
    let state = this.state;

    let primaryColor = ParamParseLib.getPrimaryColor();
    let secondaryColor = ParamParseLib.getSecondaryColor();

    //only overrides the defaults if a color is found 
    if (primaryColor)
    {
      state.primaryColor = primaryColor
    }

    if (secondaryColor)
    {
      state.secondaryColor = secondaryColor
    }

    this.setState(state);
  }


  getLinkParams()
  {

    if (this.state.isDoneLoading != true)
    {
      GetPaymentLink().then(function(paymentLinkParams)        
      {
        var encodedParamString = ""
        try 
        {
          encodedParamString = window.atob(paymentLinkParams.LinkParams); // try to convert the base64 string
        } 
        catch(e) 
        { // invalid base64 so try to just deconstructEncodedParameterString
          encodedParamString = paymentLinkParams;
        }


        ParamParseLib.deconstructEncodedParameterString(encodedParamString);

      }.bind(this)).finally(function()
      {
        this.setState(
        {
          isDoneLoading: true,
        });
        this.getColors();
      }.bind(this));
    }
  }


  showPreview()
  { 
    let encodedStr = ParamParseLib.getEncodedParameterString();
    let uri = process.env.PAYLINK_URL  + encodedStr ;
    window.open(uri, '_blank');
  }


  saveLogo()
  {

    var logo = this.state.fullLogo      
    if (logo)
    { 
      var formData = new FormData();

      formData.append('logo', logo); 

      let uri = process.env.API_URL + "/v1/merchant/set_link_logo" ;
      let result = {success: null, status_message:null};
      let requestSettings = {
        method: "POST",
        body: formData,  
        credentials: "include",
        headers: {
          "PayTrace-Product": "paymentlink"
        }
      };
      
      fetch(uri, requestSettings)
      .then(function(response)
      {
        return response.json();
      })
      .then(function(response)
      {
        var tempErrorMsg = "";
        var tempErrorClassName = "";

        if (response["success"]) 
        {     
          result.success = response.success ;
          result.status_message = response.message;      
          tempErrorMsg = "Your selections have been saved successfully."; 
          tempErrorClassName = "alert alert-success"
          // tempErrorMsg = "Your logo has been saved successfully."; 
        } 
        else {
          tempErrorMsg = "Error while saving selected logo." ;
          tempErrorClassName = "alert alert-danger"
        } 
        
        this.setState({errorLabelText: tempErrorMsg, errorLableClassName: tempErrorClassName })
      
      }.bind(this))
      .catch(function(error)
      {
        console.error("Error while saving logo in UI", error);
 
        var tempErrorMsg = "Error while saving a logo. ";
        var tempErrorClassName = "alert alert-danger";
  
        result.success = false;
        result.status_message = tempErrorMsg + error ;
       
        this.setState({errorLabelText: tempErrorMsg, errorLableClassName: tempErrorClassName })

      }.bind(this));


    }

  }


  //save both the color and the logo image (separately)
  saveSettings()
  {
    if(this.state.errorLableClassName != "alert alert-danger"){
      //save the logo
      this.saveLogo();
      //save the colors
      this.saveParameters();
    }
  }


  saveParameters()
  {
    let encodedStr = ParamParseLib.getEncodedParameterString();

    ///TODO: *NOTE* double encoding due to urlsearchparams not being encoded enough for transmission and storage
    encodedStr = window.btoa(encodedStr);
    let result = {success: null, status_message:null};
 
    let uri = process.env.API_URL + "/v1/merchant/create_payment_link";
    let requestSettings = {
      method: "post",
      body: JSON.stringify({link_params: encodedStr}),
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "PayTrace-Product": "paymentlink"
      }
    };

    fetch(uri, requestSettings)
    .then(function(response){
      return response.json();
    })
    .then(function(response)
    {

      var tempErrorMsg = "";
      var tempErrorClassName = "";

      if (response["success"]) 
      {     
        result.success = response.success ;
        result.status_message = response.message;      
        tempErrorMsg = "Your selections have been saved successfully."; 
        tempErrorClassName = "alert alert-success"
      } 
      else {
        tempErrorMsg = "Error while saving your selections. Please try again later." ;
        tempErrorClassName = "alert alert-danger"
      } 

      this.setState({errorLabelText: tempErrorMsg, errorLableClassName: tempErrorClassName })
    
    }.bind(this))
    .catch(function(error)
    {
      var tempErrorMsg = "Error while saving design preferences. Please try again later.";
      var tempErrorClassName = "alert alert-danger";

      result.success = false;
      result.status_message = tempErrorMsg + error ;
      
      this.setState({errorLabelText: tempErrorMsg, errorLableClassName: tempErrorClassName })

    }.bind(this));

  }


  // Not sure how the preview button will work for logo
  // it looks like local storage is no longer used for logo
  handleFileLoad(base64Image, file) {
        let parent = this;
        let state = this.state;
        //build out an image so that we can check the file size, etc
        let img = new Image();
        img.src = URL.createObjectURL(file);
        img.onload = function () 
        {
          if (this.width > 760 || this.height > 250)
          {
            var tempErrorMsg = "Error: Invalid Logo. Please make sure logo is less than 1mb and no more than 320x160 or aspect ratio of 2:1"
            var tempErrorClassName = "alert alert-danger";
            state.errorLabelText = tempErrorMsg;
            state.errorLableClassName = tempErrorClassName;
            state.companyInfo.logoUrl = null;
            parent.setState(state);
            return false;
          }
          return true
        };

    if(file.size > 1048576)
    { 
      //file is too larger for upload
      state.errorLabelText = "Error: Invalid Logo. Please make sure logo is less than 1mb and no more than 320x160 or aspect ratio of 2:1"
      state.errorLableClassName = "alert alert-danger"; 
      state.companyInfo.logoUrl = null;
    }
    else 
    {
      state.errorMsg = null;          //reset the error msg. In the future, there may be needs for more than just logo error msgs. mvp baby!
      state.fullLogo = file;         
      state.companyInfo.logoUrl = URL.createObjectURL(file);
      state.logo = base64Image;
    }

    this.setState(state);

  }

  setupCompanyInfo(merchantInfo)
  { 
    //default values
    let companyInfo = {
        logoUrl: this.initialLogo(),
        name: "Company Name",
        website: "www.website.com",
        email: "user@email.com",
      }

      if (merchantInfo)
      {
        ParamParseLib.setMerchantID(merchantInfo.merchant_id );                      //ensuring that the params lib has the proper merchant id included 

        companyInfo.name = merchantInfo.dba
        companyInfo.website = merchantInfo.website  
        companyInfo.email = merchantInfo.email

        if (this.state.companyInfo.logoUrl && !this.state.errorMsg)
        {
          companyInfo.logoUrl = this.state.companyInfo.logoUrl
        }
        else
        {
          companyInfo.logoUrl = process.env.API_URL + "/v1/guest/get_merchant_logo?m=" + merchantInfo.merchant_id + "&d=" + Date.now()       //merchant id is used to retrieve the proper logo (NOTE: *not* the mid) Add a date parameter to force a browser cache reload
        }
      }

      return companyInfo
  }

  openUploader(event) {
    this.uploader.current.triggerFileUpload(this);
    this.setState({ errorLabelText:"", errorLableClassName:"" });
    event.preventDefault();
  }
  
  updatePrimaryColor(color) {
    ParamParseLib.setPrimaryColor(color)
    this.setState({ primaryColor: color, errorLabelText:"", errorLableClassName:"" });
  }

  updateSecondaryColor(color) {
    ParamParseLib.setSecondaryColor(color)
    this.setState({ secondaryColor: color, errorLabelText:"", errorLableClassName:"" })
  }
  
  initialLogo(){
    let img = null;
    if (this.props.logoImage) 
    {
      img = this.props.logoImage;
    } else 
    {

    }
    return img;
  }
  
  getLogoOverlay() {
    // <div style={overlay} onClick={this.openUploader}>
    // </div>
    return (
      <LogoOverlay onClick={this.openUploader}>
        <div>Edit Logo</div>
        <LogoToolTip>(Aspect Ratio 2:1 - ex: 320x160)</LogoToolTip>
      </LogoOverlay>
    )
  }

  handleResetSecondary(evt) {
    const state = this.state;
    const checkboxChecked = evt.target.checked;
    state.secondaryColorChecked = checkboxChecked;

    if (!checkboxChecked) {
      // this.updateSecondaryColor(SECONDARY_COLOR);
      state.secondaryColor = SECONDARY_COLOR;
      ParamParseLib.setSecondaryColor(SECONDARY_COLOR)

    }
    this.setState(state);
  }
  
  validation(model) {
    return {
      valid: function(prop) {
        return true;
      }
    }
  }
  

  getErrorMsgHtml()
  {
    let errorHtml = null
    if (this.state.errorMsg)
    {
      errorHtml = <div className='errorMsg'> {this.state.errorMsg} </div>
    }

    return errorHtml
  }


  renderPageHeader() {
    return (
      <HeaderLabel label={{text: "payment", size: 25}}>
        <Icon img="Payment.png" width="36" height="36"/>
      </HeaderLabel>
    )
  }
  renderNavigation() {
    const isAllGood = true;
    return (
      <NavigationButtons
        itsAllGoodInTheHood={ isAllGood }
        primaryColor={ this.state.primaryColor } 
        currentState={ this.state }
        onSubmit={ () => {} }
        onNavigate={ () => {} }
        onReset={ () => {} }
        onRecaptcha={ () => {} }
        />
    )
  }
  
  renderBreadCrumbs() {
    const color = this.state.secondaryColor;
    const primaryColor = this.state.primaryColor;
    const stepIndex = this.state.currentPageIndex;
    const stepList = ['payment','billing','review', 'receipt'];
    return (
      <PaymentSteps 
          color={color}
          primaryColor={primaryColor}
          steps={stepList}
          path='/Payment'
          initial={stepIndex} 
          ref={this.child} />
    )
  }
  
  // copied from comus but with hard coded values
  renderTotalPaymentControl() {
    return (
      <TotalPaymentBox 
          totalAmount='9.99'
          placeholderText= ""
          name="txtTotalPayment"
          maxLength="13"
          textBoxWidth="50%"
          tooltipText="Total Dollar amount of the transaction" 
      >
        <div>Total Payment</div>
      </TotalPaymentBox>
    )
  }
  
  render() {

    let errorMsgHtml = this.getErrorMsgHtml()
    let formData = this.fakeFormdata();

    return (
      <UserContext.Consumer>
        {({ user, merchantInfo }) => {
          var passInfo = this.state;
          passInfo.companyInfo = this.setupCompanyInfo(merchantInfo);
    
          if (merchantInfo) {
            // Checking that proper merchant info exists (i.e., authenticated properly) before continuing
            this.getLinkParams();
          }
    
          return (
            <ThemeContext.Consumer>
              {theme => (
                <div className="payment-form-designer" style={{ backgroundColor: '#FFF' }}>
                  <div style={{ color: theme.tertiary_color, fontWeight: 'bold', padding: '10px', height: '40px', backgroundColor: '#f5f5f5', textAlign: 'left' }}>
                    Branding Options
                  </div>
    
                  <div className="designer-controls">
                    <div style={{ float: 'right', marginRight: '50px' }}>
                      <PageTools onClickSave={this.saveSettings} onClickPreview={this.showPreview} />
                    </div>
    
                    <div style={{ clear: 'both' }} />
                    <div className={this.state.errorLableClassName}>{this.state.errorLabelText}</div>
                    <div style={{ clear: 'both', paddingBottom: '40px' }} />
    
                    <div style={{ float: 'left' }}>
                      <PrimaryColorDesigner
                        onUpdateColor={this.updatePrimaryColor}
                        color={this.state.primaryColor}
                      />
                    </div>
                    <div style={{ float: 'right' }}>
                      <SecondaryColorDesigner
                        onChecked={this.handleResetSecondary}
                        onUpdateColor={this.updateSecondaryColor}
                        color={this.state.secondaryColor}
                        checked={this.state.secondaryColorChecked}
                      />
                    </div>
                    <div style={{ clear: 'both' }} />
                  </div>
    
                  {errorMsgHtml}
    
                  <div>{this.state.bar}</div>
    
                  <div className="sample-form col">
                    <ImageUploader ref={this.uploader} onFileLoad={this.handleFileLoad} />
                    <PaymentForm
                      totalPaymentControl={this.renderTotalPaymentControl()}
                      navigationPageHeader={this.renderPageHeader()}
                      navigationBreadCrumbs={this.renderBreadCrumbs()}
                      logoOverlay={this.getLogoOverlay()}
                      formdata={formData}
                      logoUrl={passInfo.companyInfo.logoUrl}
                      contactInfo={passInfo.companyInfo}
                      handleViewTerms={this.handleViewTerms}
                      primaryColor={this.state.primaryColor}
                      secondaryColor={this.state.secondaryColor}
                      termsLink={this.state.termsLink}
                      isProcessing={false}
                    />
                  </div>
                </div>
              )}
            </ThemeContext.Consumer>
          );
        }}
      </UserContext.Consumer>
    )}}

    export default FormDesigner;