import React, { Component } from 'react';

import RecaptchaDemo from './Placeholders/Recaptcha';
import FormTextBoxDemo from './Placeholders/FormTextBox';
import SectionTextDemo from './Placeholders/SectionTextDemo';
import CheckSelectionDemo from './Placeholders/CheckSelection';
import DropDownDemo from './Placeholders/DropDown';
import {default as RadioBoxDemo} from './Placeholders/BoxDemo';
import ContinueButtonDemo from './Placeholders/ContinueButtonDemo';

import CompanyInfoDemo from './Placeholders/CompanyInfo';
import TotalPaymentDemo from './Placeholders/TotalPayment';
import DatePickerDemo from './Placeholders/DatePicker';
import TableDemo from './Placeholders/Table';
import PasswordInputDemo from './Placeholders/PasswordInput';
import CreditCardDemo from './Placeholders/CreditCard';
import AddressDemo from './Placeholders/Address';
import CountryDemo from './Placeholders/Country';
import HeaderLabelDemo from './Placeholders/HeaderLabel';
import ImageUploaderDemo from './Placeholders/ImageUploaderDemo';
import ColorPickerDemo from './Placeholders/ColorPickerDemo';
import PhoneNumberDemo from './Placeholders/PhoneNumberDemo';
import ZipCodeDemo from './Placeholders/ZipCodeDemo';
import EmailAddressDemo from './Placeholders/EmailAddressDemo';
import PaymentSummaryDemo from './Placeholders/PaymentSummaryDemo';

import './index.css';


// I'd like to run automated tests with reCAPTCHA. What should I do?
// 
//     For reCAPTCHA v3, create a separate key for testing environments. Scores may not be accurate as reCAPTCHA v3 relies on seeing real traffic.
// 
//     For reCAPTCHA v2, use the following test keys. You will always get No CAPTCHA and all verification requests will pass.
// 
//         Site key: 6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI
//         Secret key: 6LeIxAcTAAAAAGG-vFI1TnRWxMZNFuojJ4WifJWe


class RCLDemo extends Component 
{
  constructor(props)
  {
    super(props);
  } 

  componentDidMount() {
  }

  render() {
    return (
        <div className="RCLDemo">
          <label>TODO - Restrict this view/component to PayTrace Only</label>
          <label>Should we move it into admin folder.</label>
          <label>Or should we make it a restricted content but this only restricts to login.</label>
          <label>Below is list of generic components that will be used to create the payment pages. </label>
          <label>(Screen shots seem to look better in Firefox. Also any update to screen shots on JIRA will break the links.)</label>
          <hr/>
          <div className="GenericComponents">
            <div>
              <RecaptchaDemo />
              <ImageUploaderDemo />
              <ColorPickerDemo />
            </div>
            <div className="BasicComponents">
              <RadioBoxDemo />
              <FormTextBoxDemo />
              <SectionTextDemo />
              <HeaderLabelDemo />
              <hr/>
              <ContinueButtonDemo />
            </div>
            <div className="CompositeComponents">
              <CompanyInfoDemo />
              <TotalPaymentDemo />
              <DatePickerDemo />
              <PaymentSummaryDemo />
              
              <DropDownDemo />
              <PasswordInputDemo />
              <CreditCardDemo />
              <AddressDemo />
              <CountryDemo />
              <PhoneNumberDemo />
              <ZipCodeDemo />
              <EmailAddressDemo />
            </div>
          </div>
        </div>
    );
  }
}

export default RCLDemo;
