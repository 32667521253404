import React, { Component } from 'react';
import TempPlaceholder from '../../Placeholders';
import CompanyInfo from '../../CompanyInfo';
import './CompanyInfoDemo.css';

// Implement the CompanyInfo and add it to the src components folder.
// Then take the real implemented component and render it here on demo scratch pad for QA.

// Notes: 
// Could use a combination other possible common components.
// The logo needs to register mouse click event.
// The positioning of the logo will also overlap header and body of page but probably 
// should be controlled from the parent level of this component.
class CompanyInfoDemo extends Component {
  constructor(props)
  {
    super(props);
    this.state = {
      companyInfo: {
        logoUrl:  "https://bit.ly/2Ftf1HZ",
        name: "Company Name",
        website: "www.website.com",
        email: "user@email.com",
      }
    }

    this.handleLogoUrlChange = this.handleLogoUrlChange.bind(this);
  }

  handleLogoUrlChange(event) {
    this.setState({
      companyInfo: {
        logoUrl: event.target.value
      }
    });
    event.preventDefault();
  }
  
  handleLogoClick(event) {
    alert("Do something");
    event.preventDefault();
  }
  
  handleEmail(event) {
    alert("Fake Emailing");
    event.preventDefault();
  }
  
  handleViewTerms(event) {
    alert("Fake View Terms");
    event.preventDefault();
  }

  render() {
    return (
      <div className="CompanyInfoDemo">

        <div>
          <h4>Company Info</h4>
          <div><a href='https://paytrace.atlassian.net/browse/PC2-63'>PC2-63</a></div>
          <div>the header logo will be positioned overlapping header and body</div>
          <br/>
          <div>test by pasting image url for logo</div>
          <input type="text" 
              value={this.state.companyInfo.logoUrl}
              onChange={this.handleLogoUrlChange}/>

          <hr/>

          <div>
            <div>Screen Shot</div>
            <TempPlaceholder background="https://paytrace.atlassian.net/secure/thumbnail/41643/Screen+Shot+2019-01-14+at+11.30.25+AM.png?default=false" />
          </div>
        </div>

        <hr/>

        <div>
          {
            //
            // CompanyInfo component.
            // The look and feel is handled by css.
            // The background of component is transparent.
            // The positioning will be controlled by parent component.
            // 
          }
          <CompanyInfo 
            data={this.state} 
            handleEmail={this.handleEmail}
            handleViewTerms={this.handleViewTerms} 
            handleLogoClick={this.handleLogoClick}/>
        </div>

      </div>
    );
  }
}

export default CompanyInfoDemo;