import React, { Component } from 'react';
import TempPlaceholder from '../../Placeholders';
import HeaderLabel from '../../HeaderLabel';
import PaymentIcon from '../../../../../../resources/images/icon/payment.png';

class HeaderLabelDemo extends Component {
  constructor(props){
    super(props);
  }
  render() {
    return (
      <div style={{textAlign: 'left', padding: '20px', marginBottom: '20px', border: '2px dashed green'}}>
        <h3>Header Label</h3>
        <div className="screenshot">
          <div>Screen Shot</div>
          <TempPlaceholder background="https://paytrace.atlassian.net/secure/attachment/41617/Screen%20Shot%202019-01-14%20at%2011.37.04%20AM.png" />
        </div>
        <br/>
        <hr/>
        <br/>
        <div>Example:</div>
        <HeaderLabel
            label={{text: 'Payment', size: 25}}>
          <img src={PaymentIcon} alt="payment" width="36" height="36" />
        </HeaderLabel>
      </div>
    );
  }
}

export default HeaderLabelDemo;
