// ButtonDropdown
//
//  desc:
//    a branded button and dropdown that gives different menu options for the user to select      
//
//
//  required props:
//    title:              title to be displayed on the main button
//    buttonCallback:     (optional) a callback for when the main button is selected (not when a list item is selected)
//    optionsList:        array of selectable items to be displayed in the dropdown {title : value}
//    selectionCallback:  a callback for when an option is selected from the dropdown list that will accept (title, value)

import React, { Component } from 'react';
import './index.css';

import {ThemeContext} from '../../../../lib/PayTrace/SiteBranding';
import BDButton from './BDButton';
import BDDropdown from './BDDropdown';


class ButtonDropdown extends Component {
  constructor(props)
  {
    super(props)

    this.state = {
      showDropdown: false,
    };

    this.onButtonClick = this.onButtonClick.bind(this);
    this.onDropdownSelection = this.onDropdownSelection.bind(this);
  }


  //handle the main BDButton being clicked
  onButtonClick()
  {
    this.setState({showDropdown: !this.state.showDropdown});      //per the react docs, this is the wrong way to do it, but the 'correct' way does not work
 
    if (this.props.buttonCallback)
    { //if a callback function has been provided for handling button click, call it
      this.props.buttonCallback();
    }
  }


  //handle a dropdown list item being selected/clicked on
  onDropdownSelection(title, value)
  {
    this.props.selectionCallback(title, value) 
  }


  render() {
    return (
      <ThemeContext.Consumer>
        {theme => (
          <div className='ButtonDropdown'>
            <BDButton title={this.props.title} buttonCallback={this.onButtonClick} theme={theme} />
            {this.state.showDropdown == true &&
              <BDDropdown optionsList={this.props.optionsList} selectionCallback={this.onDropdownSelection} theme={theme} />
            }
          </div>

        )}
      </ThemeContext.Consumer>
    );
  }
}

export default ButtonDropdown;
