import React, { Component } from 'react';
import './index.css';
import TopLiLegacy from './TopLiLegacy';
import SubmenuLegacy from './SubmenuLegacy';

class MenuLegacy extends Component {
  
  constructor(props)
  {
    super(props);
    
    // Bind the this context to the handler function
    this.onClickSubmenu = this.onClickSubmenu.bind(this);

    this.state =
    {
      menu_html: props.menu_nodes,

    };
  } 

  componentWillMount()
  {  

    console.log(this.props.menu_nodes);
    const htmlSections = this.props.menu_nodes.children[0].childNodes;


   
    var contents = [];
    this.sections = Object.keys(htmlSections).map((key, i) => 
    {
      let el = htmlSections[key];

 
      if (el.tagName == "LI") 
        contents.push(el);

      return <div key={i}>{contents}</div>;
    });

    this.setState({sections: contents});
  }


  // Loops through all the menu subitems and toggles
  // the menu item that was clicked for selection.
  // And closes all other menu items that are not selected.
  onClickSubmenu(e, node)
  {
    e.preventDefault();
    var submenus = this.state.sections;
    for(var x =0; x < submenus.length; x++)
    {
      if (node.isEqualNode(submenus[x]) == true)
      {
        submenus[x].classList.toggle("open");
      } else {
        submenus[x].classList.remove("open");
      }
    
    }

    this.setState({sections: submenus});
  }


  render() {
    return (
      <div id='legacy_menu'>
        <ul>
         {
              this.state.sections.map ( (n,index) =>
              {
                  if (n.classList.contains("submenu")) 
                    return <SubmenuLegacy key={index} node={n} action={this.onClickSubmenu}/>
                  else
                    return <TopLiLegacy key={index} node={n} />
              })
          }
        </ul>
      </div>
    );
  }
}

export default MenuLegacy;
