// PhoneNumberDemo
//
//  desc:
//    placeholder demo component to show off the PhoneNumberInput component         
//
//
//  props:
//

import PhoneNumberInput from '../../PhoneNumberInput';
import './index.css'


import React from 'react';
class PhoneNumberDemo extends React.Component
{
 constructor(props)
  {
    super(props)
    this.state = {
      enteredNumber: ""
    };

    this.onPhoneNumber = this.onPhoneNumber.bind(this);
  }


  onPhoneNumber(phoneNumber)
  {
      this.setState({enteredNumber: phoneNumber});

  }

  getHelperTextDiv()
  {
    var helperText = 
      <div className="placeholder_demo_help_text"> props:
        <ul>
          <li> title:                (optional) if provided, will display title above the input box </li>
          <li> reasonText:           (optional) if provided, will display the reason for needing a phone number below the title and above the input box </li>
          <li> placeholderText:      (optional) if provided, will display ghosted placeholder text in the input box. Note: there is no placeholder text by default </li>
          <li> phoneNumber:          (optional) if provided, will set the the phonenumber text for the input box (will be marked out with ***) </li>
          <li> inError:              if 'true', will display the phone number input in red </li>
          <li> hoverText:            (optional) text to display to the user when the mouse cursor hovers over the input box. By default, there is none </li>
          <li> onTextInputCallback:  a callback function for when the user has entered input </li>
        </ul>
      </div>
      return helperText;
  }

  render() {
    return (
      <div className="PhoneNumberDemo">

        <div className="component" id='phoneNumberDemoDiv'>
          <h4>PhoneNumberInput component</h4>
          <div><a href='https://paytrace.atlassian.net/browse/PC2-109'>PC2-109</a></div>
          <div>like a input text box component but for phone numbers</div>
          {this.getHelperTextDiv()}
          <hr/>
          <div>
            <PhoneNumberInput 
              title="Phone Number" 
              reasonText="Cuz this is a demo" 
              onTextInputCallback={this.onPhoneNumber}
              hoverText="Keep it around 10 numbers"
              placeholderText="enter numbers only and no more than 10"
            />
          </div>
        </div>
          Entered Phone Number: {this.state.enteredNumber}
      </div>
    );
  }
}

export default PhoneNumberDemo;