// ExpirationDateInput
//
//  desc:
//    expiration control for CC
//
//  props:
//    title:              (optional) label text for exp input box
//    tooltipText:        (optional) tool tip for exp input box
//    value:              (optional) the exp value
//    textBoxWidth:       (optional) with of the input box
//    id:                 (required) used to proprogate changes to the data model
//    name:               (optional) name of the input control
//    maxLength:          (optional) maximum allowed length of input box value
//    onChange:           (optional) a callback function for when the user has changes input to update model
//    onValidate:         (optional) a callback function to valid user input 
//    isValid:            (optional) if true, will highlight red border around input box.
//

import React, { Component } from 'react';
import { DateUtils } from "react-day-picker";
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from 'react-day-picker/moment';
import { Validation, Change, Rule } from '../Utility';

import './ExpirationDateInput.css';


class ExpirationDateInput extends Component {
  constructor(props)
  {
    super(props);
    this.state = { 
      inError: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  handleChange(event)
  {
    if (this.props.onChange) {
      this.props.onChange(event.target.value);
    }
    event.preventDefault();
  }
   
  handleBlur(event)
  {
    // let inputText = event.target.value;
    // let isValid = this.validateExpirationDate(event.target.value) && this.validateFutureDate(inputText);
    // const id = event.target.name || event.target.id;
    const validation = new Validation(event);
    validation.addRule(new Rule(this.validateExpirationDate));
    validation.addRule(new Rule(this.validateFutureDate));

    if (this.props.onValidate) {
      this.props.onValidate(validation);
    }
    event.preventDefault();
  }
   
  validateExpirationDate(value, maxLength)
  {
    var tempValue = value;
    // verify the required length first
    if(tempValue.length > maxLength) {
      return false;
    }
    // to check date format mm/yyyy
    // Also include mm value as 1 or 01
    var regex = /^((0?[1-9])|(1[0-2]))\/(\d{4})$/;
    if (regex.test(tempValue)) {
      return true;
    }
    return false;
  }
  
  validateFutureDate(value)
  {
    // shit this.futureDate breaks rules. for now just writing it twice
    var currentDate = new Date();
    var currentMonth = currentDate.getMonth() + 1 ;
    var currentYear = currentDate.getFullYear();
    
    // Assumption : value will be in valid format - mm/yyyy
    const tempDate = value.split("/");
    const mm = parseInt(tempDate[0]);
    const yy = parseInt(tempDate[1]);

    // Assumption: value should have valid month between 1 to 12, based on the regex.
    if( (mm >= currentMonth && yy >= currentYear) || (mm <= 12 && yy > currentYear) )
    {
      return true;
    }
    else 
    {
      return false; 
    }
  }
  
  futureDate() {
    var currentDate = new Date();
    var currentMonth = currentDate.getMonth() + 1 ;
    var currentYear = currentDate.getFullYear();
    return [currentMonth, currentYear];
  }

  render() {
    const tooltip = this.props.tooltipText;
    const expValue = this.props.value;
    const textboxWidth = this.props.textBoxWidth;
    const placeHolder = this.futureDate().join('/');
    const inputName = this.props.name;
    const maxLength = this.props.maxLength;
    const style = {
      width:textboxWidth,
      border: this.props.isValid ? null : "2px solid red"
    }
    const id = this.props.id;
    const name = this.props.name;
    return (
      <div className='expirationDateInput'>
        <div>
          {this.props.title} 
        </div>
        <input
          id={ id }
          name={ name }
          type="text"
          style={ style }
          value={ expValue }
          placeholder={ placeHolder }
          onChange={ this.handleChange }
          onBlur={ this.handleBlur }
          name={ inputName }
          maxLength={ maxLength }
          title={ tooltip } />
      </div>
    );
  }
}

export default ExpirationDateInput;
