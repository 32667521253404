// PaymentForm
//
//  desc:
//    Main payment link form
//
//  props:
//    data:               (required) the model for the form data
//    logoUrl:              (optional) the logo to be shown on the branded comus page
//      primaryColor        (optional) color style
//      secondaryColor      (optional) color style
//      logoOverlay         (optional) passes to the company profile logo
//    children            (required) the steps and its children
//    handleViewTerms     (optional) callback function for handling termslink click

/*
  PaymentForm
  
  The main body of payment link. Contains the header, the form,
  the company info and a few other components. This component is
  shared between Comus as the actual payment portal 
  and aphrodite as a payment portal sample.
*/
import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Switch, Redirect, withRouter } from "react-router-dom";


import Payment from './Steps/Payment';
import Billing from './Steps/Billing';
import Review from './Steps/Review';
import Receipt from './Steps/Receipt';
import {default as Header} from './HeaderSection';
import FormSection from './FormSection';
import CompanyInfo from '../CompanyInfo';
import Icon from '../Icon';
import {CCValidator} from '../CreditCard';
import './PaymentForm.css';
import {DEFAULT_PRIMARY, DEFAULT_SECONDARY} from '../DefaultProperties';


class PaymentForm extends Component {
  constructor(props){
    super(props);

    this.validatePaymentStep = this.validatePaymentStep.bind(this);
    this.validateBillingStep = this.validateBillingStep.bind(this);
    this.validateAllBeforeSubmit = this.validateAllBeforeSubmit.bind(this);
    this.handleViewTerms = this.handleViewTerms.bind(this)
  }
  
  validateAllBeforeSubmit(formdata) {
    return this.validatePaymentStep(formdata)
            && this.validateBillingStep(formdata)
            && this.validateTerms(formdata.agreeToTermsChecked)
            && this.validateRecaptcha(formdata.recaptchaToken);
  }

  validatePaymentStep(formdata) {
    return this.validateAmount(formdata.amount)
            && this.validatePayon(formdata.payon)
            && !this.props.isProcessing;
  }

  validateBillingStep(formdata) {
    let isValid = true;
    if (formdata.paymentMethod == 'CC') {
      isValid = isValid && this.validateCC(formdata.cc);
      isValid = isValid && this.validateExp(formdata.exp);
      isValid = isValid && this.validateSecurityCode(formdata.securityCode);
    } else {
      isValid = isValid && this.validateCheckingAccount(formdata.checkingAccount);
      isValid = isValid && this.validateRoutingNumber(formdata.routingNumber);
    }
    return isValid 
            && this.validNameOnCard(formdata.name)
            && this.validateAddress(formdata.address)
            && this.validateZipcode(formdata.zipcode)
            && this.validateCity(formdata.city)
            && this.validateState(formdata.state)
            && !this.props.isProcessing;
  }

  validateTerms(agreeToTermsChecked) {
    return agreeToTermsChecked !== undefined && agreeToTermsChecked;
  }
  
  validateRecaptcha(recaptchaToken) {
    return recaptchaToken !== undefined && recaptchaToken !== null && recaptchaToken != '';
  }

  validateAmount(amount) {
    return Number(amount) > 0;
  }

  validatePayon(date) {
    return date != '';
  }

  validNameOnCard(name) {
    return name !== undefined && name != '';
  }
  
  validateCC(cc) {
    // not implemented if customer logged in and has customer profile
    // then dont validate this
    return cc !== undefined && CCValidator.valid(cc);
  }
  
  validateExp(exp) {
    // regular expression does not text month greater than 12
    return exp !== undefined && /^[0-1]?[0-9]\/(?:[0-9]{2})?[0-9]{2}$/g.test(exp);
  }
  
  validateSecurityCode(securityCode) {
    return /^[0-9]{3,4}$/.test(securityCode) && securityCode !== undefined && securityCode != ''; 
  }
  
  validateCheckingAccount(checkingAccount) {
    return /^#*\d+$/.test(checkingAccount) && checkingAccount !== undefined && checkingAccount != '';
  }
  
  validateRoutingNumber(routingNumber) {
    return /^\d{9,}$/.test(routingNumber) && routingNumber !== undefined && routingNumber != '';
  }
  
  validateAddress(addresses) {
    return addresses !== undefined 
            && addresses.length >= 1
            && addresses[0] != '';
  }
  
  validateZipcode(zipcode) {
    return zipcode !== undefined && /^\d{5}$/.test(zipcode);
  }
  
  validateCity(city) {
    return city !== undefined && city != '';
  }
  
  validateState(state) {
    return state !== undefined && state != '';
  }
  
  validateEmail(email) {
    return email !== undefined && /[\w-]+@([\w-]+\.)+[\w-]+/.test(email);
  }
  

  handleViewTerms()
  {
    if (this.props.handleViewTerms)
    {
      this.props.handleViewTerms();
    }
  }

  renderBackPaymentButton() {
    return (
      <div className='previous-button'>
        <Link to="/Payment">
          <button className='btn back'>
            <Icon className="fas fa-arrow-left" width="36" height="36"/>
            Back to Payment 
          </button>
        </Link>
      </div>
    )
  }

  renderBackBillingButton() {
    return (
      <div className='previous-button'>
        <Link to="/Billing">
          <button className='btn back'>
            <Icon className="fas fa-arrow-left" width="36" height="36"/>
            Back to Billing 
          </button>
        </Link>
      </div>
    )
  }

  renderContinueBillingButton(isEnabled) {
    return (
      <div className='continue-button'>
        <Link to="/Billing">
          <button className='btn' disabled={!isEnabled} style={{backgroundColor: this.getNavigationButtonColor()}}>
            Continue to Billing
          </button>
        </Link>
      </div>
    )
  }

  renderContinueReviewButton(isEnabled) {
    return (
      <div className='continue-button'>
        <Link to="/Review">
          <button className='btn' disabled={!isEnabled} style={{backgroundColor: this.getNavigationButtonColor()}}>
            Continue to Review
          </button>
        </Link>
      </div>
    )
  }

  renderSubmitPaymentButton(isEnabled) {
    return (
      <div className='continue-button'>
        <button onClick={this.props.onSubmit} className='btn' disabled={!isEnabled} style={{backgroundColor: this.getNavigationButtonColor()}}>
          Submit Payment
        </button>
      </div>
    )
  }

  renderNavigation() {
    // note this gets called on every rerender like when amount box changes
    if (this.props.location.pathname.toLowerCase() == "/payment") {
      const isAllGood = this.validatePaymentStep(this.props.formdata);
      return (
        <div className='nav-buttons row'>
        { this.renderContinueBillingButton(isAllGood) }
        </div>
      )
    } else if (this.props.location.pathname.toLowerCase() == "/billing") {
      const isAllGood = this.validateBillingStep(this.props.formdata);
      return (
        <div className='nav-buttons row'>
        { this.renderBackPaymentButton() }
          <div className='float-right-in-row'>
          { this.renderContinueReviewButton(isAllGood) }
          </div>
        </div>
      )
    } else if (this.props.location.pathname.toLowerCase() == "/review") {
      const isAllGood = this.validateAllBeforeSubmit(this.props.formdata);
      return (
        <div className='nav-buttons row'>
        { this.renderBackBillingButton() }
          <div className='float-right-in-row'>
          { this.renderSubmitPaymentButton(isAllGood) }
          </div>
        </div>
      )
    } else {
      // probably the designer on aphrodite
      return (
        <div className='nav-buttons row'>
        { this.renderContinueBillingButton(true) }
        </div>
      )
    }

  }

  getNavigationButtonColor(){
    
    let lPrimaryColor = this.props.primaryColor.toLowerCase();
    let lSecondaryColor = this.props.secondaryColor.toLowerCase();
        
    if(lPrimaryColor == "#ffffff"  && lSecondaryColor == "#ffffff"){
      return DEFAULT_PRIMARY
    }
    
    if(lPrimaryColor == "#ffffff"){
      return lSecondaryColor
    } 
    
    return lPrimaryColor

  }
  
  renderDesigner(props) {
    // payon required by payment component. but probably should just check if it exists
    // passing in null to just make it show up.
    return (
      <Payment 
        formdata={{payon: null}}
      />
    )
  }
  
  renderPaymentStep(props) {
    return (
      <Payment
        {...props}
        onPaymentAmountChange={ this.props.onPaymentAmountChange }
        onPaymentInvoiceChange={ this.props.onPaymentInvoiceChange }
        formdata={ this.props.formdata }
        validAmount={ this.validateAmount(this.props.formdata.amount) }
        validPayOn={ this.validatePayon(this.props.formdata.payon) } />
    )
  }

  renderBillingStep(props) {
    return (
      <Billing
        {...props}
        formdata={ this.props.formdata }

        onBillingPaymentMethodChange={ this.props.onBillingPaymentMethodChange }

        onBillingCreditCardNumberChange={ this.props.onBillingCreditCardNumberChange }
        onBillingCreditCardExpChange={ this.props.onBillingCreditCardExpChange }
        onBillingCreditCardSecurityCodeChange={ this.props.onBillingCreditCardSecurityCodeChange }

        onBillingCheckingAccountChange={ this.props.onBillingCheckingAccountChange }
        onBillingRoutingNumberChange={ this.props.onBillingRoutingNumberChange }

        onBillingCountryChange={ this.props.onBillingCountryChange }
        onBillingNameChange={ this.props.onBillingNameChange }
        onBillingAddressChange={ this.props.onBillingAddressChange }
        onBillingZipcodeChange={ this.props.onBillingZipcodeChange }
        onBillingCityChange={ this.props.onBillingCityChange }
        onBillingStateChange={ this.props.onBillingStateChange }
        onBillingPhoneChange={ this.props.onBillingPhoneChange }
        onBillingEmailChange={ this.props.onBillingEmailChange }

        validNameOnCard={ this.validNameOnCard(this.props.formdata.name) }
        validateCC={ this.validateCC(this.props.formdata.cc) }
        validateExp={ this.validateExp(this.props.formdata.exp) }
        validateSecurityCode={ this.validateSecurityCode(this.props.formdata.securityCode) }
        validateCheckingAccount={ this.validateCheckingAccount(this.props.formdata.checkingAccount) }
        validateRoutingNumber={ this.validateRoutingNumber(this.props.formdata.routingNumber) }
        validateAddress={ this.validateAddress(this.props.formdata.address) }
        validateZipcode={ this.validateZipcode(this.props.formdata.zipcode) }
        validateCity={ this.validateCity(this.props.formdata.city) }
        validateState={ this.validateState(this.props.formdata.state) }
        validateEmail={ this.validateEmail(this.props.formdata.email) }
      />
    )
  }

  renderReviewStep(props) {
    return (
      <Review
        {...props}
        onAgreementCheckboxChange={ this.props.onAgreementCheckboxChange }
        onRecaptchaChange={ this.props.onRecaptchaChange }
        formdata={ this.props.formdata }
        termsLink={ this.props.termsLink} 
        />
    )
  }

  renderReceipt(props) {
    return (
      <Receipt
        {...props}
        formdata={ this.props.formdata } />
    )
  }

  render() {
    // logoOverlay property only meant to be set on the aphrodite sample.
    // Comus will pass in the different payment processing steps as children.
    // Payment, Billing, Review, and Receipt components.
    // Data is a required property for company info component.
    const primaryColor = this.props.primaryColor || DEFAULT_PRIMARY;
    const secondaryColor = this.props.secondaryColor || DEFAULT_SECONDARY;
    const logoOverlay = this.props.logoOverlay || null;
    const contactInfo = this.props.contactInfo;
    
    return (
      <div className='payment-form row'>
        <div className='col'>
          <div className='row'>
            <Header bgcolor={primaryColor} />
          </div>

          <div className='paymentform-body row'>
            <div className='company-info col-md-4'>
              <CompanyInfo
                  companyLogoOverlay={logoOverlay}
                  companyLogo={this.props.logoUrl}
                  primaryColor={primaryColor}
                  companyName={contactInfo.name}
                  companyWebsite={contactInfo.website}
                  companyEmail={contactInfo.email}
                  handleEmail={this.handleEmail}
                  handleViewTerms={this.handleViewTerms}
                  handleLogoClick={this.handleLogoClick} />
            </div>

            <div className='col-md-8'>
              <FormSection
                    secondaryColor={ secondaryColor } 
                    primaryColor={ primaryColor }
                    totalPaymentControl={ this.props.totalPaymentControl }
                    navigationBreadCrumbs={ this.props.navigationBreadCrumbs }
                    navigationPageHeader={ this.props.navigationPageHeader }
                    navigateControl={ this.renderNavigation() } >
                <Route path="/Payment" render={(props) => (
                  this.renderPaymentStep(props)
                )} />
                <Route path="/Billing" render={(props) => (
                  this.renderBillingStep(props)
                )} />
                <Route path="/Review" render={(props) => (
                  this.renderReviewStep(props)
                )} />
                <Route path="/Receipt" render={(props) => (
                  this.renderReceipt(props)
                )} />
                <Route path="/designer" render={(props) => (
                  this.renderDesigner(props)
                )} />
              </FormSection>
            </div>

          </div>
        
        </div>
      </div>
    );
  }
}

export default withRouter(PaymentForm);
