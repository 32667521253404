// Country
//
//  desc:
//    dropdown control consisting of countries. Uses a library for the list of existing countries.
//
//  props:
//    value:                (optional) the country
//
import React, { Component } from 'react';
import {GetCountries} from './CountryList';
import DropDown from '../DropDown';
import DropDownIcon from '../resources/images/dropdown.png';
import './country.css';


var dropDownStyle = {
  backgroundImage: `url(${DropDownIcon})`
}


class Country extends Component {
  constructor(props)
  {
    super(props);
    this.options = GetCountries();
    this.onChange = this.onChange.bind(this);
  }

  onChange(event) {
    this.props.onChange(event.target.value);
  }

  render() {
    let options = Object.keys(this.options).map((key, index) => 
      <option key={index} value={key}>{this.options[key]}</option>
    );
    return (
      <div className="Country">
        <div className="label">
          <label>Country</label>
        </div>
        <select
              value={this.props.value || ''}
              onChange={this.onChange}
              style={dropDownStyle}>
          {options}
        </select>
      </div>
    );
  }
}

export default Country;
