import React, { Component } from 'react';
import PaymentSummary from '../../../react-component-library/PaymentSummary';

class PaymentSummaryDemo extends Component {
  constructor(props){
    super(props);
    this.items = {
      "Total": "$25.99"
      ,"Payment Type": "One-Time"
      ,"Pay On": "Oct 1, 2018"
      ,"Payment Card": "Visa *1234"
    }
    this.handleEdit = this.handleEdit.bind(this);
  }
  handleEdit(key, value) {
    console.log(key, value);
  }
  render() {
    return (
      <div style={{padding: '30px', backgroundColor: 'white'}}>
        <h4>Create array component</h4>
        <a href="https://paytrace.atlassian.net/browse/PC2-103">
        https://paytrace.atlassian.net/browse/PC2-103</a>
        <hr/>
        <PaymentSummary onEdit={this.handleEdit} items={this.items}/>
      </div>
    );
  }
}

export default PaymentSummaryDemo;
