// PaymentForm
//
//  desc:
//    Main payment link form
//
//  props:
//    data:               (required) the model for the form data
//    logoUrl:              (optional) the logo to be shown on the branded comus page
//      primaryColor        (optional) color style
//      secondaryColor      (optional) color style
//      logoOverlay         (optional) passes to the company profile logo
//    children            (required) the steps and its children

/*
  PaymentForm
  
  The main body of payment link. Contains the header, the form,
  the company info and a few other components. This component is
  shared between Comus as the actual payment portal 
  and aphrodite as a payment portal sample.
*/
import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Switch, Redirect, withRouter } from "react-router-dom";

import Payment from '../../common/react-component-library/PaymentForm/Steps/Payment';
import {default as Header} from '../../common/react-component-library/PaymentForm/HeaderSection';
import FormSection from '../../common/react-component-library/PaymentForm/FormSection';
import CompanyInfo from '../../common/react-component-library/CompanyInfo';
import NavigationButtons from '../../common/react-component-library/PaymentForm/NavigationButtons';
import Icon from '../../common/react-component-library/Icon';
import '../../common/react-component-library/PaymentForm/PaymentForm.css';


const DEFAULT_PRIMARY = '#4696CA';
const DEFAULT_SECONDARY = '#B2B7C3';


class SampleForm extends Component {
  constructor(props){
    super(props);
  }

  renderNavigation() {
    // note this gets called on every rerender like when amount box changes
    if (this.props.location.pathname.toLowerCase() == "/designer") {
      // currentState can be deleted and everything to do with it
      // in the navigationButtons component.
      return (
        <div className='nav-buttons'>
          <NavigationButtons
            itsAllGoodInTheHood={ false }
            primaryColor={ this.props.primaryColor } 
            currentState={ {currentPageIndex: 0} }
            onSubmit={ () => {} }
            onNavigate={ () => {} }
            onReset={ () => {} }
            onRecaptcha={ () => {} }
          />
        </div>
      )
    } else if (this.props.location.pathname.toLowerCase() == "/billing") {
      const isAllGood = this.validateBillingStep(this.props.formdata);
      return (
        <div className='nav-buttons'>
        { this.renderBackPaymentButton() }
        { this.renderContinueReviewButton(isAllGood) }
        </div>
      )
    } else if (this.props.location.pathname.toLowerCase() == "/review") {
      const isAllGood = this.validateAllBeforeSubmit(this.props.formdata);
      return (
        <div className='nav-buttons'>
        { this.renderBackBillingButton() }
        { this.renderSubmitPaymentButton(isAllGood) }
        </div>
      )
    }

  }

  renderPaymentStep(props) {
    var today = new Date();
    return (
      <Payment
        {...props}
        onPaymentAmountChange={ this.props.onPaymentAmountChange }
        formdata={ {
          payon: today
        } }
      />
    )
  }

  render() {
    // this.props.logoUrl
    console.log("sample form", this.props)
    const logoURL = this.props.contactInfo.companyInfo.logoUrl;

    return (
      <div className='payment-form'>
        <Header bgcolor={this.props.primaryColor} />
        <div className='paymentform-body'>
          <div className='company-info'>
            <CompanyInfo
                companyLogoOverlay={this.props.logoOverlay}
                companyLogo={logoURL}
                primaryColor={this.props.primaryColor}
                companyName={this.props.contactInfo.companyInfo.name}
                companyWebsite={this.props.contactInfo.companyInfo.website}
                companyEmail={this.props.contactInfo.companyInfo.email}
                handleEmail={this.handleEmail}
                handleViewTerms={this.handleViewTerms}
                handleLogoClick={this.handleLogoClick} />
          </div>
          <FormSection
                secondaryColor={ this.props.secondaryColor } 
                primaryColor={ this.props.primaryColor }
                totalPaymentControl={ this.props.totalPaymentControl }
                navigationBreadCrumbs={ this.props.renderBreadCrumbs }
                navigationPageHeader={ this.props.navigationPageHeader }
                navigateControl={ this.props.renderNavigation } >
            {this.renderPaymentStep(this.props)}
          </FormSection>
        </div>
      </div>
    );
  }
}

export default withRouter(SampleForm);
