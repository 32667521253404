// PrinterTabBar
//
//  desc:
//         
//
//
//  props:
//

import React from 'react';


import './PrinterTabBar.css'

class PrinterTabBar extends React.Component
{
 constructor(props)
  {
    super(props)
    this.state = {

    };
  }


  renderTabBar()
  {
    let tabBar = 

      <ul className="nav nav-tabs cloud-printer-tabs">
        <li  className="nav-item">
          <a className="nav-link" href={process.env.LEGACY_URL + "/modifyreceipt.pay"} >Logo</a>
        </li>
        <li  className="nav-item">
          <a className="nav-link" href={process.env.LEGACY_URL + "/receiptheaderfooter.pay"}>Header/Footer</a>
        </li>
        <li  className="nav-item">
          <a className="nav-link" href={process.env.LEGACY_URL + "/receipttemplate.pay"}>Templates</a>
        </li>
        <li className="nav-item">
          <a className="nav-link active" href="/CloudPrinters">Cloud Printers</a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href={process.env.LEGACY_URL + "/receipts_users.pay"}>Users</a>
        </li>
        <li  className="nav-item">
          <a className="nav-link" href={process.env.LEGACY_URL + "/receiptemail.pay"}>Email</a>
        </li>
      </ul>

    
  return tabBar   
  }


  render()
  {
    let tabBar = this.renderTabBar();

    return(
      <div>

        {tabBar}

      </div>
    );
  }

}

export default PrinterTabBar;