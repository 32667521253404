// DropDown
//
//  desc:
//    provided 2 kinds of components to represent drop down controls
//    The list drop down uses a list of items to render the selection and updates an input box when a selection is made.
//    the other drop down control just uses a regular old select tag
//
//  props (select tag):
//    
//    onValidate:        (optional) handler to check input when input changes.
//    onDataChange:      (optional) handler when input changes to update the data model
//    isValid:           (optional) if true, adds a red outline when error state
//    value:             (optional) the value of the selected item.
//    name:              (optional) name of the control
//    id:                (required) used to update the data model.
//    items:             (required) list of options of select tag.

//  props (list tag):
//    onSelect:          (optional) handler when item is selected
//    text:              (optional) text for the item in the item list
//    value:             (optional) value for the item in the item list
//    selected:          (optional) the selected value
//

import React, { Component } from 'react';
import {DateTimeUtility, Rule, Change, Validation} from '../Utility';
import {BackDrop} from '../Modal';
import './dropdown.css';

export const DropDownStates = {
  OPEN: 1,
  CLOSE: 0
};

export class DropDownList extends Component {
  constructor(props){
    super(props);
    this.state = {
      dropDownState: DropDownStates.CLOSE,
    }

    this.closeList = this.closeList.bind(this);
    this.openList = this.openList.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.onValidate = this.onValidate.bind(this);
  }
  
  onValidate(evt) {
    if (this.props.onValidate){
      this.props.onValidate(new Validation(evt));
    }
  }
  
  closeList(evt) {
    this.setState({
      dropDownState: DropDownStates.CLOSE
    });
  }

  openList(evt) {
    this.setState({
      dropDownState: DropDownStates.OPEN
    });
  }
  
  onSelect(index) {
    const change = new Change({
      target: {
        value: index,
        id: 'payment_frequency',
        name: 'payment[frequency[value]]'
      }
    });
    if (this.props.onDataChange) {
      this.props.onDataChange(change);
    }
    this.closeList();
  }

  render() {
    const value = this.props.value;
    const id = this.props.id;
    const name = this.props.name;
    const items  = this.props.items;
    const textValue = items[value];
    const dropdownState = {
      display: this.state.dropDownState ? 'block' : 'none',
    }
    const isOpen = this.state.dropDownState == DropDownStates.OPEN;
    const label = ["Frequency","Bill Every"][this.state.dropDownState];
    const style = {};
    if (this.props.isValid !== undefined && !this.props.isValid) {
      style.border = "2px solid red";
    }
  
    return (
      <div className='drop-down-list'>
        <BackDrop onClick={ this.closeList } 
                  enabled={ isOpen }/>

        <div>
          <label className='label'>{label}</label>
        </div>

        <span>
          <input
                id={ id }
                name={ name }
                type='text'
                readOnly
                value={ textValue }
                onFocus={ this.openList }
                onBlur={ this.onValidate }
                style={ style } />
        </span>

        <ul style={dropdownState} className={`overlay-panel`}>
          { 
            items.map((item, idx) => { 
              return <ListItem
                        onSelect={ this.onSelect }
                        selected={ value == idx }
                        key={ `frequency_${idx}` }
                        value={ idx }
                        text={ item }/> 
            })
          }
        </ul>

      </div>
    );
  }
}


export class ListItem extends Component {
  constructor(props){
    super(props);
    this.onSelect = this.onSelect.bind(this);
  }

  onSelect(evt, value) {
    if (this.props.onSelect) {
      // slightly different than other event handlers
      // because it is related to drop down component.
      // so just passing index value;
      this.props.onSelect(value);
    }
  }

  render() {
    const text = this.props.text;
    const value = this.props.value;
    const selected = this.props.selected;
    return (
      <li item-selected={ `${selected}` } onClick={(evt) => this.onSelect(evt, value)}>{text}</li>
    );
  }
}
