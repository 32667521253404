// SignOutButton
//
//  desc:
//    provides a button that will cause a user to be signed out   
//
//
//  required props:
//
//
//  *NOTE: Legacy Dependency*  Currently, will redirect user to legacy system....  This functionality may need to change in a future version

import React, { Component } from 'react';
import './index.css';

import BButton from '../Branded/BButton';


class SignOutButton extends Component {
  constructor(props)
  {
    super(props)

    this.state = {

    };

    this.onButtonClick = this.onButtonClick.bind(this);
  }


  //handle the button being clicked
  onButtonClick()
  { //redirect to the legacy signout page 
    let signout_uri = process.env.LEGACY_URL + "/logout.pay"
    window.location = encodeURI(signout_uri);
  }


  render() {
    return (
      <div className='SignOutButton'>
        <BButton title="Sign Out" buttonCallback={this.onButtonClick} />
      </div>
    );
  }
}

export default SignOutButton;
