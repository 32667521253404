import React, { Component } from 'react';
import DefaultLogo from './default_logo.png';
import './company_logo.css';

class CompanyLogo extends Component {
  constructor(props)
  {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(event) {
    this.props.handleClick(event);
    event.preventDefault();
  }

  render() {
    const height = this.props.height;
    const width = this.props.width;

    // overriding max sizes that was inherited 
    // and UI tweaks
    let frameStyle = {
      height: height,
      width: width,
      border: '1px solid gainsboro',
    }
    let logoImgStyle = {
      height: '160px',
      width: '320px',
    }
    const imgSrc = this.props.imageUrl ? this.props.imageUrl : DefaultLogo;
    return (
      <div className="CompanyLogo no-print" onClick={this.handleClick}>
        <div className="image-circle-frame" style={frameStyle}>
          <div className="logo-overlay">{this.props.overlay}</div>
          <img style={logoImgStyle} className="logoImage" src={imgSrc} />
        </div>
      </div>
    );
  }
}

export default CompanyLogo;
