import React, { Component } from 'react';
import FooterNavItems from './FooterNavItems';

import './index.css';

class FooterNav extends Component {
  constructor(props)
  {
    super(props);
  } 
  
  render() {

    return (
      <ul className ='FooterNav' id = "FooterNav" style={{color:this.props.theme.footer_color}}>
        <FooterNavItems items={this.props.links} theme={this.props.theme}/>
        <li>&copy; Copyright 2004-{getCurrentYear()} PayTrace, Inc.</li>
      </ul>
    )
  }
}


function getCurrentYear(){
   return new Date().getFullYear();
}

export default FooterNav;
