// Billing
//
//  desc:
//    Billing step and everything on that page. Right after payment step.
//
//  props:
//    formdata:             (required) the form data
//      name:               (optional) billing name for the ACH or CC fields
//      paymentMethod:      (optional) ACH or CC
//    onDataChange:         (optional) callback to update model
//    onValidate:           (optional) callback to validate model
//

import React, { Component } from 'react';
import PaymentSummary from '../../../PaymentSummary';
import SectionText from '../../../SectionText';
import PasswordNew from '../../../PasswordNew';
import Icon from '../../../Icon';
import AmountInputBox from '../../../AmountInputBox';
import FormInput from '../../../FormInput';
import CustomButton from '../../../CustomButton';
import DatePicker from '../../../DatePicker';
import RadioButton from '../../../RadioButton';
import {Validation, Change, Rule} from '../../../Utility';
import CreditCardFields from '../../CreditCardFields';
import ACHFields from '../../ACHFields';
import ContactFields from '../../ContactFields';

import './billing.css';

const CC = 'CC';
const ACH = 'ACH';

export const PaymentMethod = {
  CC: 'CC',
  ACH: 'ACH'
}

class Billing extends Component {
  constructor(props){
    super(props);
    this.state = {
      validNameOnCardVisited: false,
    }
    this.onBillingNameChange = this.onBillingNameChange.bind(this);
    this.onBillingCreditCardNumberChange = this.onBillingCreditCardNumberChange.bind(this);
    this.onBillingPaymentMethodChange = this.onBillingPaymentMethodChange.bind(this);
    this.onBillingCreditCardExpChange = this.onBillingCreditCardExpChange.bind(this);
    this.onBillingCreditCardSecurityCodeChange = this.onBillingCreditCardSecurityCodeChange.bind(this);
    this.onBillingCountryChange = this.onBillingCountryChange.bind(this);
    this.onBillingAddressChange = this.onBillingAddressChange.bind(this);
    this.onBillingZipcodeChange = this.onBillingZipcodeChange.bind(this);
    this.onBillingCityChange = this.onBillingCityChange.bind(this);
    this.onBillingStateChange = this.onBillingStateChange.bind(this);
    this.onBillingPhoneChange = this.onBillingPhoneChange.bind(this);
    this.onBillingEmailChange = this.onBillingEmailChange.bind(this);
    this.onBillingCheckingAccountChange = this.onBillingCheckingAccountChange.bind(this);
    this.onBillingRoutingNumberChange = this.onBillingRoutingNumberChange.bind(this);
    
    this.onValidate = this.onValidate.bind(this);
    this.validateNameOnCard = this.validateNameOnCard.bind(this);
  }
  
  onBillingPaymentMethodChange(evt) {
    if (this.props.onBillingPaymentMethodChange) {
      this.props.onBillingPaymentMethodChange(evt.target.value);
    }
  }
  
  onBillingCheckingAccountChange(value) {
    if (this.props.onBillingCheckingAccountChange) {
      this.props.onBillingCheckingAccountChange(value);
    }
  }
  
  onBillingRoutingNumberChange(value) {
    if (this.props.onBillingRoutingNumberChange) {
      this.props.onBillingRoutingNumberChange(value);
    }
  }

  onBillingCountryChange(value) {
    if (this.props.onBillingCountryChange) {
      this.props.onBillingCountryChange(value);
    }
  }

  onBillingAddressChange(value) {
    if (this.props.onBillingAddressChange) {
      this.props.onBillingAddressChange(value);
    }
  }
  
  onBillingZipcodeChange(value) {
    if (this.props.onBillingZipcodeChange) {
      this.props.onBillingZipcodeChange(value);
    }
  }
  
  onBillingCityChange(value) {
    if (this.props.onBillingCityChange) {
      this.props.onBillingCityChange(value);
    }
  }
  
  onBillingStateChange(value) {
    if (this.props.onBillingStateChange) {
      this.props.onBillingStateChange(value);
    }
  }
  
  onBillingPhoneChange(value) {
    if (this.props.onBillingPhoneChange) {
      this.props.onBillingPhoneChange(value);
    }
  }
  
  onBillingEmailChange(value) {
    if (this.props.onBillingEmailChange) {
      this.props.onBillingEmailChange(value);
    }
  }
  
  onBillingNameChange(change) {
    if (this.props.onBillingNameChange) {
      this.props.onBillingNameChange(change.value());
    }
  }
  
  onBillingCreditCardNumberChange(value) {
    if (this.props.onBillingCreditCardNumberChange) {
      this.props.onBillingCreditCardNumberChange(value);
    }
  }
  
  onBillingCreditCardExpChange(value) {
    if (this.props.onBillingCreditCardExpChange) {
      this.props.onBillingCreditCardExpChange(value);
    }
  }
  
  onBillingCreditCardSecurityCodeChange(value) {
    if (this.props.onBillingCreditCardSecurityCodeChange) {
      this.props.onBillingCreditCardSecurityCodeChange(value);
    }
  }

  // just to update UI on blur event.
  validateNameOnCard(validation) {
    this.setState({
      validNameOnCardVisited: true,
    })
  }
    
  onValidate(validation) {
    // if (this.props.onValidate){
    //   this.props.onValidate(validation);
    // }
  }

  hasAutoFocus(fieldId) {
    if (this.props.location === undefined) return false;
    if (this.props.location.state === undefined) return false;

    return this.props.location.state.autoFocus == fieldId;
  }

  componentDidMount() {
    // console.log(this.props.history.length)
    // clear autofocus state
    if (this.props.history !== undefined) {
      this.props.history.replace({
        pathname: '/Billing',
        state: {}
      });
    }
  }

  contactFields() {
    return (
      <ContactFields
        {...this.props}
      />
    )
  }
  
  hasACH() {
    // assume undefined is they have both cc and ACH but should usually be set.
    return this.props.formdata.hasACH === undefined || this.props.formdata.hasACH;
  }

  paymentFields() {
    const name = this.props.formdata.name;
    // isCC and hasACH is different fyi
    let isCC = this.props.formdata.paymentMethod == 'CC';
    
    // ensure always true if merchant does not have ACH.
    if (!this.hasACH()) {
      isCC = true; 
    }
    return (
      <div>
          <FormInput
            id="name"
            value={ name }
            isValid={ this.props.validNameOnCard || !this.state.validNameOnCardVisited }
            placeholder = "Full Name"
            onChange={ this.onBillingNameChange }
            onBlur={ this.validateNameOnCard }>
            <div>{isCC ? "Name on Card" : "Full Name"}</div>
          </FormInput>
          {
            isCC 
                ? <CreditCardFields
                  isCCValid={ this.props.validateCC }
                  isExpDateValid={ this.props.validateExp }
                  isSecurityCodeValid={ this.props.validateSecurityCode }
                  autoFocus={ this.hasAutoFocus('billing_account') }
                  onBillingCreditCardExpChange={ this.onBillingCreditCardExpChange }
                  onBillingCreditCardSecurityCodeChange={ this.onBillingCreditCardSecurityCodeChange }
                  onBillingCreditCardNumberChange={ this.onBillingCreditCardNumberChange }
                  formdata={ this.props.formdata } />
                : <ACHFields
                  isCheckingAccountValid={ this.props.validateCheckingAccount }
                  isCheckingRoutingNumber={ this.props.validateRoutingNumber }
                  autoFocus={ this.hasAutoFocus('billing_account') }
                  onBillingCheckingAccountChange={ this.onBillingCheckingAccountChange }
                  onBillingRoutingNumberChange={ this.onBillingRoutingNumberChange }
                  formdata={ this.props.formdata } />
          }
      </div>
    )
  }

  render() {
    const paymentMethodCCText = "Pay with CC";
    const paymentMethodACHText = "Pay with Checking Account";

    return (
      <div className='billing-fields'>
        <div className='row'>
          <RadioButton
            value={ CC }
            id="paymentMethod"
            tooltipText="Payment Method - Credit Card"
            labelText={paymentMethodCCText}
            selected={ this.props.formdata.paymentMethod == PaymentMethod.CC } 
            onChange={this.onBillingPaymentMethodChange}/>
          {
            // hide option for ACH if merchant doesn't have it
            this.hasACH() ? (
              <RadioButton
                value={ ACH } 
                id="paymentMethod"
                tooltipText="Payment Method - Credit Card"
                labelText={paymentMethodACHText}
                selected={ this.props.formdata.paymentMethod == PaymentMethod.ACH } 
                onChange={ this.onBillingPaymentMethodChange }/>
            ) : null
          }

        </div>

        <div style={{clear: 'both'}}>
          <div>
          {this.paymentFields()}
          </div>
        </div>

        {this.contactFields()}

      </div>
    );
  }
}

export default Billing;
