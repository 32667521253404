// CheckboxLabelTextInput
//
//  desc:
//    stylized inline component made up of checkbox, label, and text input all in one row
//
//
//  props:
//    isChecked:                indicates whether the checkbox should be displayed as checked or not
//    onCheckboxCallback:       callback handler for when the checkbox is clicked on
//    onTextInputCallback:      callback handler for when any text is input or changed on the text input box
//

import './index.css';
import TextInputBox from '../TextInputBox';


import React from 'react';
class CheckboxLabelTextInput extends React.Component
{
 constructor(props)
  {
    super(props)
    this.state = 
    { 
      value:this.props.value

    };

    this.onCheckboxClick = this.onCheckboxClick.bind(this);
    this.onTextInput = this.onTextInput.bind(this);
  }


  //check for a callback handler and pass it the new value of this checkbox
  onCheckboxClick(event)
  {
    if (this.props.onCheckboxCallback)
    { 
      this.props.onCheckboxCallback(event.target.checked);
    } 
  }


  //check for a callback handler and pass it the changed text 
  onTextInput(text)
  {
    if (this.props.onTextInputCallback)
    {
      this.props.onTextInputCallback(text);  
    } 
  }

  render()
  {
    const tempValue = this.props.value ? this.props.value : "";
    return(
      <div className='checkboxLabelTextInput'>
        <div className='clti-component-div clti-checkbox-div'>
          <input type="checkbox" name="customButton" value="customButton" onChange={this.onCheckboxClick} checked={this.props.isChecked} />
        </div>
        <div className='clti-component-div clti-label-div'>
          Use Custom Button
        </div>
        <div className='clti-component-div clti-input-div'>
          <TextInputBox placeholderText='http://' onChangeCallback={this.onTextInput} name="customButtonUrl" maxLength="175" value={tempValue}/>
        </div>
      </div>
    );
  }

}

export default CheckboxLabelTextInput;
