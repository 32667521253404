import React, { Component } from 'react';
import ImageUploader from '../../ImageUploader';
import CompanyLogo from '../../CompanyInfo/CompanyLogo';

class ImageUploaderDemo extends Component {
  constructor(props){
    super(props);
    this.uploader = React.createRef();
    this.handleLogoClick = this.handleLogoClick.bind(this);
    this.hideWidget = this.hideWidget.bind(this);
    this.handleFileLoad = this.handleFileLoad.bind(this);
    
    this.state = {
      display: true
    }
    
    const vars = this.getUrlVars();
    this.preview = vars["preview"];
  }
  
  getUrlVars() {
    var vars = {};
    var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
        vars[key] = value;
    });
    return vars;
  }

  hideWidget(event) {
    var current = this.state.display;
    console.log(current);
    
    this.setState({
      display: !current
    })
  }
  
  isVisible() {
    return this.state.display;
  }
  
  openUpWindow() {
    var patt = /paytrace.com/i;
    if (patt.test(window.location.href)) {
      console.log("meh.", window.location.href);
    } else {
      window.open("http://localhost:1234/RCLDemo?preview=true", '_blank');
    }
  }
  
  handleFileLoad(base64Image) {
    // console.log("image = ", base64Image);
    try {
      localStorage.setItem("companylogo", base64Image);
    } catch(err) {
      if (err.code == 22) {
        alert("AHHHhhh what are you trying to do! You broke me. The base64 of image is to big for local storage. Do we need to rethink the strategy or can we just NOT allow such a ri·dic·u·lous logo size.")
        console.log(err.name, err.type, err.message);
      }
    }
  }
  
  handleLogoClick(event) {
    this.uploader.current.triggerFileUpload(this);
    event.preventDefault();
  }
  
  renderDemo() {
    const imageUrl = "https://bit.ly/2RpZVo7";
    
    if (this.preview) {
      var base64Image = localStorage.getItem("companylogo");
      return (
        <div>
          <div>below image is stored in local storage in browser.</div>
          <div>other browsers able to access the image if they are on the same domain and port</div>
          <div>this is just a test and should work in comus.</div>
          <div>Right now i just added a parameter in the URL to simulate it.</div>
          <div>Parent that uses this component should be responsible to do any clean up.</div>
          <div>And define name of the local storage key</div>
          <div>(Would be cool to allow zooming and repositioning logo. At the moment it's centered)</div>
          <br/>
          <CompanyLogo 
            height="100px" 
            width="100px" 
            imageUrl={base64Image}
            handleClick={this.handleLogoClick}/>
        </div>
      )
    }
    return (
      <div>
        <h4>Aphrodite - Design Page- Logo Chooser </h4>
        <div>Image Uploader example/demo</div>
        <br/>
        <div>
        <a href="https://paytrace.atlassian.net/browse/PC2-94">
        https://paytrace.atlassian.net/browse/PC2-94</a>
        </div>
        <div>
          <button onClick={this.openUpWindow}>Click to view image</button>
        </div>
        <button onClick={this.hideWidget}>{this.state.display ? "hide" : "show"} default widget</button>
        <hr/>
        <ImageUploader ref={this.uploader} onFileLoad={this.handleFileLoad} display={this.isVisible()} >
          <CompanyLogo 
            height="100px" 
            width="100px" 
            imageUrl={imageUrl}
            handleClick={this.handleLogoClick}/>
        </ImageUploader>
      </div>
    )
  }

  render() {
    return (
      <div style={{textAlign:'left', padding: '20px', marginBottom: '20px', border: '2px dashed green'}}>
        {this.renderDemo()}
      </div>
    );
  }
}

export default ImageUploaderDemo;
