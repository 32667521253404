// CustomButton
//
//  desc:
//    one time and recurring buttons. Has a radio input box as a child component
//
//  props:
//    value:        (optional) recurring or onetime value for this example
//    onClick:      (optional) event handler to update the selected value
//    labelText:    (optional) label
//    selected:     (optional) if 'true', will visually display as selected
//    id:           (required) id of control used to update model
//    name:         (optional) name property of the control
//    {children}:   (optional) UI components that can be rendered inside the custom button. Ex used for recurring text
//

import React, { Component } from 'react';
import {Change} from '../Utility';
import './CustomButton.css';

class CustomButton extends Component {
  constructor(props){
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick(evt) {
    this.refs['radio'].checked = true;
    
    const change = new Change({
      target: this.refs['radio'],
    });
    if (this.props.onClick) {
      this.props.onClick(change);
    }
    // evt.preventDefault();
  }

  render() {
    const children = this.props.children;
    const labelText = this.props.labelText;
    const className = this.props.selected ? 'selected' : '';
    const selectedColor = this.props.selected ? '#4696CA' : '#FFFFFF';
    const id = this.props.id;
    const name = this.props.name;
    const value = this.props.value;
    return (
      <div className='form-custom-button' onClick={this.onClick} >
        <div className='circle-thing' >
          <input ref='radio' defaultChecked={this.props.selected} type="radio" id={id} name={name} value={value} />
        </div>
        <span className='verticalspace' style={{display: 'inline-block', verticalAlign: 'middle', marginTop: '-40px', height: '100%'}}>
        </span>
        <span style={{textAlign: 'center', width: '100%'}}>
          {children}
        </span>
        <div style={{marginTop: '-10px', textAlign: 'center'}}>
          {labelText}
        </div>
      </div>
    );
  }
}

export default CustomButton;
