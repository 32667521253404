import 'url-search-params-polyfill';
// ParamParse
//
//  desc:
//    a singleton for adding parameters to create an encoded parameter url scheme
//

const PARAM_PARSE_VERSION_STRING          = 'v';
const PARAM_PARSE_DATA_STRING             = 'i';
const PARAM_PARSE_COMPONENT_DICT          = 'c';

const PARAM_PARSE_MERCHANT_ID             = 'm'
const PARAM_PARSE_COMPONENT_ATTR_VISIBLE  = 'v';
const PARAM_PARSE_COMPONENT_ATTR_REQUIRED = 'r';

const PARAM_PARSE_PRIMARY_COLOR           = 'p';
const PARAM_PARSE_SECONDARY_COLOR         = 's';

class ParamParse
{
  constructor()
  {
    if(! ParamParse.ParamParseInstance)
    {
      this._data = [];
      this.paramDict = {};

      //determines the encoding/decoding version to use. 
      // By default, this will be the most recent version. 
      // However, when an encoded parameter string is read in, it will grab and use the version given by that string
      // Encoding should always be done by the most recent version. Decoding can be a different version and can be specified with 'setDecodingVersion' 
      this.version = 3;
      this.decodingVersion = 3;
      
      ParamParse.ParamParseInstance = this;
    }


    return ParamParse.ParamParseInstance;
  }

  // deletes everything in the hash.
  clear() {
    for (var prop in this.paramDict) {
        if (this.paramDict.hasOwnProperty(prop)) {
            delete this.paramDict[prop];
        }
    }
  }


  //takes an encoded parameter string, decodes/deconstruct it to get at the values
  //. example string that this function expects: "?v=1&i=eyJjb21wIjp7InJlcXVpcmVkIjp0cnVlfX0%3D"
  deconstructEncodedParameterString(encodedParams)
  {

    var searchParams = new URLSearchParams(encodedParams);
    //TODO: sadly, will need to replace URLSearchParams as it is not supported by IE 
    var dataString = "";
    if (searchParams.has(PARAM_PARSE_VERSION_STRING))
    {
      this.decodingVersion = searchParams.get(PARAM_PARSE_VERSION_STRING)
    }
    if (searchParams.has(PARAM_PARSE_DATA_STRING))
    {
      dataString = searchParams.get(PARAM_PARSE_DATA_STRING)
    }
    else
    {
      return {"error" : "version given is invalid or not available"};
    }

    //decode the parameters according to the given encoding version 
    if (this.decodingVersion <= 3)
    { 
      //perform the deconstruction using encoding/decoding version 3.0
      
      var dec = window.atob(dataString);
      this.paramDict = JSON.parse(dec);
      return this.paramDict
    }
    else
    { //the given decoding version is not recognized, return an error
      return {"error" : "unable to decode query string with given decoding version"};
    }
  }


  //encodes the parameters in the paramDict, attaches the encoding version, and returns that string to the calling function 
  getEncodedParameterString()
  {
    var jsonStr = JSON.stringify(this.paramDict);
    var encStr = window.btoa(jsonStr);

    //using URLSearchParams will also url safe encod the strings as necessary 
    //TODO: sadly, will need to replace URLSearchParams as it is not supported by IE 
    var searchParams = new URLSearchParams();
    searchParams.set(PARAM_PARSE_VERSION_STRING, this.version);        //adding the version information for decoding purposes 
    searchParams.set(PARAM_PARSE_DATA_STRING, encStr);              //add the base64 encoded string of the paramDict
    return "?" + searchParams.toString();       //formatting complete string... just add to url 
  }


  //returns the current parameters (and key/values) as a json string
  getParamsAsJson()
  {
    return JSON.stringify(this.paramDict);
  }

  getVersion()
  {
    return this.version ;
  }
  
  
  getDecodingVersion()
  {
    return this.decodingVersion ;
  }
  //adds a parameter with a key/value pair 
  // If the parameter does not yet exist, it will create it
  // If the parameter key already exists, will replace current value with new value
  addParameterKeyAndValue(parameter, paramKey, paramValue)
  {
    var currentParam = this.paramDict[parameter];
    if (!currentParam)
    { //param does not currently exist, so we are free to add it 
      this.paramDict[parameter] = {[paramKey] : paramValue};
    }
    else
    { //parameter currently exists, so we add the new key/value pair without disrupting existing key/value pairs 
      currentParam[paramKey] = paramValue;
      this.paramDict[parameter] = currentParam;
    }
  }


  //completely removes any trace of a parameter
  removeParameter(parameter)
  {
    delete this.paramDict[parameter];
  }


  //As the name implies, will completely remove a given key assocaited with the parameter
  // Will return success if the key is removed or if the key never existed to begin with
  // Will return an false if the parameter does not exist
  removeParameterKey(parameter, paramKey)
  {
    var currentParam = this.paramDict[parameter];
    if (currentParam)
    {
      delete currentParam[paramKey];   
    }
    else
    { //parameter does not exist, return false 
      return false;
    }

    return true;
  }


  addComponentKeyAndValue(componentName, key, value)
  {
    var componentDict = this._getComponentDict(componentName);
    componentDict[key] = value;

    this._setComponentDict(componentName, componentDict);

  }


  //returns the componentName's value for a given key
  getComponentKeyValue(componentName, key)
  {
    var componentDict = this._getComponentDict(componentName);
    return componentDict[key];
  }





  //removes a particular key for a given component 
  removeComponentKey(componentName, key)
  {
    var componentDict = this._getComponentDict(componentName);
    delete componentDict[key];            

    this._setComponentDict(componentName, componentDict);

  }


  //removes a component entirely
  removeComponent(componentName)
  {
    var allComponentDict = this._getAllComponentDict();
    delete allComponentDict[componentName];       

    this._setAllComponentDict[allComponentDict];
  }


  //sets the specific component's hidden state as bool isVisible: true/false
  //. If the component does not yet exist in the allComponentDict it will be created
  //. This is really a helper convenience function to ensure that the hidden attribute is consistent with all components 
  setComponentVisible(componentName, isVisible)
  {
   var compressedVal = 0
    if (isVisible)
    {
      compressedVal = 1
    }
    this.addComponentKeyAndValue(componentName, PARAM_PARSE_COMPONENT_ATTR_VISIBLE, compressedVal);
  }


  //sets the specific component's required state as bool isRequired: true/false
  //. If the component does not yet exist in the allComponentDict it will be created
  //. This is really a helper convenience function to ensure that the required attribute is consistent with all components 
  setComponentRequired(componentName, isRequired)
  {
    //not storing true/false in order to decrease size of the later encoded string, so converting to a number value instead
    var compressedVal = 0
    if (isRequired)
    {
      compressedVal = 1
    }
    this.addComponentKeyAndValue(componentName, PARAM_PARSE_COMPONENT_ATTR_REQUIRED, compressedVal);
  }


  //gets the specific component's required state as bool isRequired: true/false
  //. If the component does not yet exist in the allComponentDict, value will default to FALSE, as the component should NOT BE REQUIRED
  //. This is really a helper convenience function to ensure that the required attribute is consistent with all components 
  getComponentRequired(componentName)
  {
    var componentDict = this._getComponentDict(componentName);
    var isRequired = componentDict[PARAM_PARSE_COMPONENT_ATTR_REQUIRED];
    if (isRequired == 1)
    { //only if isRequired is true should true be returned
      return true;
    }
    //for all other situations, return false
    return false;
  }


  //gets the specific component's visible state as bool: true/false
  //. If the component does not yet exist in the allComponentDict, value will default to false, as the component should be hidden
  //. This is really a helper convenience function to ensure that the required attribute is consistent with all components 
  getComponentVisible(componentName)
  {
    var componentDict = this._getComponentDict(componentName);
    var isVisible = componentDict[PARAM_PARSE_COMPONENT_ATTR_VISIBLE];
    if (isVisible == 1)
    { //only if isVisible is 1 should true be returned
      return true;
    }
    //for all other situations, return false
    return false;
  }


  //returns a specific components dictionary as json
  getComponentAsJson(componentName)
  {
    var componentDict = this._getComponentDict(componentName);
    return JSON.stringify(componentDict);
  }


 //returns a specific components dictionary
  getComponentDict(componentName)
  {
    return  this._getComponentDict(componentName);
  }



  //returns the merchant id associated with this payment link
  getMerchantID()
  { //PARAM_PARSE_MERCHANT_ID
    return this.paramDict[PARAM_PARSE_MERCHANT_ID];
  }


  //sets the merchant id that will be associated with this payment link
  setMerchantID(merchantID)
  {
    this.paramDict[PARAM_PARSE_MERCHANT_ID] = merchantID
  }


  //returns the merchant's primary color associated with this payment link
  getPrimaryColor()
  { //PARAM_PARSE_PRIMARY_COLOR
    return this.paramDict[PARAM_PARSE_PRIMARY_COLOR];
  }


  //sets the merchant's primary color that will be associated with this payment link
  setPrimaryColor(primaryColor)
  {
    this.paramDict[PARAM_PARSE_PRIMARY_COLOR] = primaryColor;
  }


  //returns the merchant's Secondary color associated with this payment link
  getSecondaryColor()
  { //PARAM_PARSE_SECONDARY_COLOR
    return this.paramDict[PARAM_PARSE_SECONDARY_COLOR];
  }


  //sets the merchant's Secondary color that will be associated with this payment link
  setSecondaryColor(secondaryColor)
  {
    this.paramDict[PARAM_PARSE_SECONDARY_COLOR] = secondaryColor;
  }


  //sets the *decoding* version for this object. 
  setDecodingVersion(version)
  { 
    this.decodingVersion = version;
  }


  //internal function for getting the dictionary containing all of the components
  //   If component dictionary does not yet exist, will create one
  _getAllComponentDict()
  {
    var componentDict = this.paramDict[PARAM_PARSE_COMPONENT_DICT];
    if (!componentDict)
    {
      componentDict = {}        
    }
    return componentDict;
  }


  //internal function for setting the dictionary containing all of the components
  //    Will replace any existing component dictionary in the paramDict
  _setAllComponentDict(componentDict)
  {
    this.paramDict[PARAM_PARSE_COMPONENT_DICT] = componentDict;
  }


  //internal function for getting a specific component dictionary
  _getComponentDict(componentName)
  {
    var allComponentDict = this._getAllComponentDict();
    var componentDict = allComponentDict[componentName];
    if (!componentDict)
    {
      componentDict = {};     //TODO: is the functionality that we will always want when the specific component dictionary does not yet exist?
    }

    return componentDict;
  }


  //internal function for setting a specific component dictionary
  //    Will replace any existing component dictionary in the paramDict
  _setComponentDict(componentName, componentDict)
  {
    var allComponentDict = this._getAllComponentDict();
    allComponentDict[componentName] = componentDict;
    this._setAllComponentDict(allComponentDict);  
  }


}


const ParamParseLib = new ParamParse()
// Object.freeze(ParamParseLib)
export default ParamParseLib
