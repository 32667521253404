/*
  Name:
  Login {Widget}

  Description:
  Login thing where user can navigate to login view.
*/
import React, { Component } from 'react';
import Icon from '../Icon';
import './login.css'

export class LoginWidget extends Component {
  constructor(props){
    super(props);
    this.openLogin = this.openLogin.bind(this);
    this.closeLogin = this.closeLogin.bind(this);
    this.login = this.login.bind(this);
    this.onUserNameChange = this.onUserNameChange.bind(this);
    this.onPasswordChange = this.onPasswordChange.bind(this);

    this.state = {
      isEnabled: false,
      userName: '',
      password: '',
      message: '',
    }
  }
  
  onPasswordChange(evt) {
    this.setState({
      password: evt.target.value
    })
  }
  
  onUserNameChange(evt) {
    this.setState({
      userName: evt.target.value
    })
  }
  
  openLogin(evt) {
    this.setState({
      isEnabled: true
    });
  }
  
  closeLogin(evt) {
    this.setState({
      isEnabled: false,
      userName: "",
      message: "",
    });
  }
  
  login(evt){
    evt.preventDefault();
    if (this.state.userName.trim() == "" || this.state.password.trim() == "") {
      this.setState({
        message: "enter valid email and password",
        isEnabled: false,
      });
    }

    // TODO: bug can not keep modal open. I think that when fetch is called state gets updated
    if (this.props.onLogin) {
      this.props.onLogin(evt, this.state.userName, this.state.password);
    }
  }
  
  render() {
    const enabled = this.state.isEnabled ? "enabled" : "disabled";
    const loginClass = `login-popup ${enabled}`
    return (
      <div className='login-widget'>
        <Icon img="ReturningUser.svg" width='32px' height='32px' />
        <div className='login-message'>
          <div>Returning User?</div>
          <div>Log in to pre-fill your</div>
          <div>information and submit </div>
          <div>a payment</div>
          <div className='login-link' onClick={this.openLogin}>Log In</div>
        </div>
        
        {
          // TODO clean up the css for this login stuff. there are style issues
        }
        <div className={loginClass}>
          <div>
            <div>
              <h1>Returning Users</h1>
              <span>Log into your account to retrieve your saved information</span>
            </div>
            <br/>
            <div>
              <label>Email</label>
              <input type='text' onChange={this.onUserNameChange} value={this.state.userName} />
            </div>
            <br/>
            <div>
              <label>Password</label>
              <input type='password' onChange={this.onPasswordChange} value={this.state.password} />
            </div>
            <br/>
            <button className="btn btn-secondary" onClick={this.closeLogin}>Cancel</button>
            <span>&nbsp;&nbsp;</span>
            <button className="btn btn-primary" onClick={this.login}>Login</button>
            <span style={{color: 'red'}}>{this.state.message}</span>
          </div>
        </div>
      </div>
    );
  }
}

export default LoginWidget;
