import React, { Component } from 'react';
import HyperLink from '../../Shared/HyperLink';
import './index.css'


class FeedbackAlert extends Component {
  
  constructor(props){
    super(props);
    this.state={ 
  
    };
  }
  
  getFooterText(){
    let footerText = "Questions, comments or feedback?";
    if(this.props.theme.footer_text != null){
      footerText = this.props.theme.footer_text ;
    }
    return footerText;
  }

  getCommentIcon(){
    return <i className="fa fa-comments"></i>;
  } 
  
  getFeedbackLink()
  {
    //If Branding footer_text are available then hyperlink is not shown in VT. so to mimic the same branding behavior
    let commentIcon = this.getCommentIcon();
    let linkText = <HyperLink name=" Let us know what you think." url= "mailto:support@paytrace.com?subject=Feedback" targetWindow= "_blank" titleText= "" icon={commentIcon} />

    if(this.props.theme.footer_text != null){
      linkText = null ;
    }
    return linkText;
  }
  
  render() {
    let currentFooterText = this.getFooterText();
    let currentFeedbackLink = this.getFeedbackLink();
    
    return (
        <div className="alert alert-info" id="feedback">
          {currentFooterText}
          {currentFeedbackLink}
        </div>
    );
  }
}

export default FeedbackAlert;
