// SearchMerchantBox
//
//  desc:
//    provides an input box and button for the user to enter a merchants name to search for.       
//
//
//  required props:
//
//  *NOTE: Legacy Dependency*  Currently, will redirect user to legacy system....  This functionality may need to change in a future version


import React, { Component } from 'react';
import './index.css';

import MerchantInputBox from './MerchantInputBox';
import SearchButton from './SearchButton';

class SearchMerchantBox extends Component {

  constructor(props)
  {
    super(props);
    this.state =
    {
      inputText: '',
      searchStatus: false              //by default, the seachStatus is disabled until text is entered
    };

    this.onTextInput = this.onTextInput.bind(this);
    this.onSearch = this.onSearch.bind(this);
  } 


  //look aheaad functionality could be handled here in the future
  // for now, this determines if the search status should be true or not depending on the length of the text 
  onTextInput(text)
  {     
    this.setState({inputText: text});
    if (text.length > 0)
    {
      this.setState({searchStatus: true});
    }
    else
    {
      this.setState({searchStatus: false});
    }
  }


  //performs a redirect to merchant search page on VT 
  onSearch()
  { 
    const uri = process.env.LEGACY_URL + "/adminbyun.pay?utf8=✓&UN=" + this.state.inputText + "&button=";
    window.location = encodeURI(uri);
  }


  render() {
    return (
      <div className="SearchMerchantBox">
        <div className="searchComponent">
          <MerchantInputBox onTextInputCallback={this.onTextInput} onSubmitCallback={this.onSearch}/>
        </div>
        <div className="searchComponent">
          <SearchButton onSearch={this.onSearch} buttonStatus={this.state.searchStatus} />
        </div>
      </div>
    );
  }
}

export default SearchMerchantBox;
