import React, { Component } from 'react';


class TopLiLegacy extends Component {

  componentWillMount()
  {  

  }

  render() 
  {
    return (
      <li dangerouslySetInnerHTML= {{__html: this.props.node.innerHTML}}></li>
    );
  }
}

export default TopLiLegacy;
