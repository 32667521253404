import React, { Component } from 'react';
import './InputSubmitBox.css';
class InputSubmitBox extends Component {
  constructor(props){
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
    this.onTextChange = this.onTextChange.bind(this);
  }
  onTextChange(evt) {
    if (this.props.handleTextChange) {
      this.props.handleTextChange(evt.target.value);
    }
    evt.preventDefault();
  }
  onSubmit(evt) {
    if (this.props.handleSubmit) {
      // passing props value back seems kind of redundant. meh
      this.props.handleSubmit(this.props.textValue);
    }
    evt.preventDefault();
  }
  render() {
    return (
      <div className="InputSubmitBox">
        <input 
          type="text" 
          placeholder="Color.." 
          name="search" 
          value={this.props.textValue}
          onChange={this.onTextChange} />
        <button type="submit" onClick={this.onSubmit}>
          {this.props.children}
        </button>
      </div>
    );
  }
}

export default InputSubmitBox;
